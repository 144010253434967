import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  constructor(
    public formBuilder: FormBuilder,
    public router:Router, 
    public authService: AuthService
    ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])] 
    });
  }
  

  public onLoginFormSubmit(values:Object):void {
    if (this.loginForm.valid) {
      this.authService.signIn(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .then(() => this.router.navigate(['/']));
    }
  }

  public googleAuth(): void {
    this.authService.googleSignin()
      .then(() => this.router.navigate(['/']))
  }

}
