import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';


export interface RequestCacheEntry {
  cacheKey: string,
  response: HttpResponse<any>;
  lastRead: number;
}

export abstract class RequestCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
  abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void;
  abstract clearKey(key: string): void;
  abstract setCacheServer(server: string): boolean;
  abstract clearCache(): void;
}

const maxAge = 300000000;

@Injectable()
export class RequestCacheService implements RequestCache {

  constructor() { }

  clearKey(key: string): void {
    let cached: any = localStorage.getItem("cache");
    if(cached){
      cached = JSON.parse(cached);
      if(cached[key]) {
        delete cached[key];
        localStorage.setItem("cache", JSON.stringify(cached));
      }
    }
  }
  setCacheServer(server: string): boolean {
    if(!localStorage.getItem("server") || (localStorage.getItem("server") !== server)){
      this.clearCache();
      localStorage.setItem("server", server);
      return true;
    } 
    return false;
  }
  clearCache(): void {
    localStorage.setItem("cache", "{}");
  }
  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    if(!isCachable(req)){
      return undefined;
    }


    let cached: any = localStorage.getItem("cache");
    if(!cached){
      cached = {};
    } else {
      cached = JSON.parse(cached);
    }
    if(cached[getKey(req)]) {
      let response = cached[getKey(req)];
      const isExpired = response.lastRead < (Date.now() - maxAge);

      if (!isExpired && response.response) {

        let httpResponse = new HttpResponse({
          status : response.response.status,
          body : response.response.body,
          headers : response.response.headers,
          statusText : response.response.statusText,
          url : response.response.url
        });

        return httpResponse;
      };
    }
    return undefined;
  }


  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const params = req.params;

    if(isCachable(req)){
      let cache: any = localStorage.getItem("cache");
      if(!cache) {
        cache = {};
      } else {
        cache = JSON.parse(cache);
      }
      const entry = {cacheKey: params.get("cacheKey"), response, lastRead: Date.now()};
      cache[getKey(req)] = entry;
      localStorage.setItem("cache", JSON.stringify(cache));
    }
  }
}

function getKey(req: HttpRequest<any>): string | undefined {
  const params = req.params;
  if(params.get("cacheKey")){
    return params.get("cacheKey");
  }
  return undefined;
}

function isCachable(req: HttpRequest<any>) {
  const params = req.params;
  return (params.has("cache") && getKey(req));
}
