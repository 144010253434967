import { Component } from '@angular/core';
import { HeaderComponent } from '../header.component';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent extends HeaderComponent{
  

}
