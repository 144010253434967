import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TemplateOption } from '../classes/user';

export class Settings {
  
    public name: string = ' FAHM Ecommerce';
    public theme: string = 'outdoors';
    public template: TemplateOption;
    public logo: string = 'https://www.fahmpartners.com/wp-content/uploads/2017/12/FAHM-Technology-Partners.svg';
    public baseOrg: string = 'Outdoors';
    public domain: string = 'oms-server.fahmpartners.com';
    public distributionGroup: string = 'Outdoors_Shipping';
    public banner: string = 'banners.json';
    public slides: string = 'slides.json';
    public rtl: boolean = false;
    public adminSidenavIsOpened: boolean = true;
    public adminSidenavIsPinned: boolean = true;
    public adminSidenavUserBlock: boolean = true;
    public locale: string = 'en_US_EST';

    constructor(obj: any) {
       Object.assign(this, obj);
    }
}

@Injectable()
export class AppSettings {
    private _settings: BehaviorSubject<Settings>;

    constructor() {
        if(localStorage.getItem('settings')){
            this._settings = new BehaviorSubject(new Settings(JSON.parse(localStorage.getItem('settings'))));
        } else {
            this._settings = new BehaviorSubject(new Settings({
                name: 'OMS',
                theme: 'outdoors',
                logo: 'https://www.fahmpartners.com/wp-content/uploads/2017/12/FAHM-Technology-Partners.svg',
                baseOrg: 'Outdoors',
                domain: 'oms-server.fahmpartners.com',
                distributionGroup: 'Outdoors_Shipping',
                banner: 'banners.json',
                slides: 'slides.json',
                rtl: false,
                adminSidenavIsOpened: false,
                adminSidenavIsPinned: false,
                adminSidenavUserBlock: false,
                locale: 'en_US_EST'
            }))
        }
       
    }


    public getSettings(): Observable<Settings> {
        return this._settings.asObservable();
    }
    public updateTheme(theme: string): void {
        let settings = this._settings.getValue();
        this.updateSettings(settings);
    }
    public getCurrentSettings(): Settings {
        return this._settings.getValue();
    }
    public updateSettings(settings: Settings): void {
        localStorage.setItem('settings', JSON.stringify(settings));
        this._settings.next(settings);
    }
}