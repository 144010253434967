import { Component, OnInit, Input } from '@angular/core';
import { Category } from '../classes/product';
import { AuthService } from '../services/auth.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-footer',
  template: '<div></div>'
})
export class FooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  
  public today: number = Date.now();
  public orgName: string = '';
  public categories: Category[] = [];

  constructor(
    private authService: AuthService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.initializePage();
  }

  initializePage() {
    this.authService.template$.subscribe(r => {
      if(r){
        this.orgName = r.name;
        this.themeLogo = r.logo;
  
        this.productService.getTopCategories(1, 4).subscribe(r => {
          this.categories = r;
          console.log(r);
        })
      }
        
    })
  }

}
