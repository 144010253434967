import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../modal/quick-view/quick-view.component";

import { Product } from "../../classes/product";
import { ProductService } from "../../services/product.service";
import { CartModalComponent } from '../modal/cart-modal/cart-modal.component';
import { CartService } from '../../services/cart.service';
import { InventoryService } from '../../services/inventory.service';
import { StorePickupComponent } from '../store-pickup/store-pickup.component';

@Component({
  selector: 'app-product-box',
  template: '<div></div>'
})
export class ProductBoxComponent implements OnInit {

  private _product: Product;
  get product(): Product {
    return this._product;
  }
  @Input() set product(value: Product) {
    this._product = value;
    this.unitOfMeasure = value.UnitOfMeasure;
    console.log("set " + this.unitOfMeasure);
  };
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  @ViewChild("storePickupModal") StorePickup: StorePickupComponent;

  public unitOfMeasure: string;
  public ImageSrc : string

  constructor(
      private productService: ProductService,
      private cartService: CartService,
      private inventoryService: InventoryService
    ) { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 200); // Skeleton Loader
      this.ImageSrc = this.product.images[0].small;
    }
  
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    if(variants && variants.distinctAttributes && variants.distinctAttributes.color) {
      for (let i = 0; i < variants.distinctAttributes.color.length; i++) {
        uniqColor.push(variants.distinctAttributes.color[i].Value)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    console.log('product', product);
    product.variants.itemList.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
      console.log('addToCart', product);

    this.cartService.addProductToCart(product, 1, product.UnitOfMeasure, 'SHP', undefined, this.inventoryService);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  pickup(product: any) {
    this.StorePickup.openModal(
      {
        product: product, 
        title: "In-Store Pickup"
      }
    )
  }

}
