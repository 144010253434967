import { Component, OnInit } from '@angular/core';
import { Category, Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public categories: Category[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService) { 
    this.productService.getTopCategories().subscribe(category => {
      console.log(category);
      this.categories = category;
    });
  }

  ngOnInit(): void {
  }



}
