import { Component } from '@angular/core';
import { HeaderComponent } from '../header.component';

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent extends HeaderComponent {


}
