import { Component } from '@angular/core';
import { ProductBoxComponent } from '../product-box.component';

@Component({
  selector: 'app-product-box-two',
  templateUrl: './product-box-two.component.html',
  styleUrls: ['./product-box-two.component.scss']
})
export class ProductBoxTwoComponent extends ProductBoxComponent {

}
