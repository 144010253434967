<!--footer section -->
<footer [ngClass]="class">
    <div class="dark-layout" *ngIf="mainFooter">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="col-lg-3">
                        <div class="footer-title footer-mobile-title">
                            <h4 translate>footer.about</h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img [src]="themeLogo"  alt="logo">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 subscribe-wrapper">
                        <div class="subscribe-block">
                            <h2 translate>newsletter</h2>
                            <form >
                                <div class="form-group">
                                    <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" [placeholder]="'footer.enter-email' | translate">
                                    <button type="submit" class="btn btn-solid " translate>footer.subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-title">
                            <h4 translate>footer.store-info</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-details">
                                <li><i class="fa fa-map-marker"></i>100 Mural St. Suite 100, Richmond Hill ON, L4B 1J3</li>
						        <li><i class="fa fa-phone"></i>{{ "call-us" | translate }}: (855) 909-3999</li>
						        <li><i class="fa fa-envelope-o"></i>{{ "email-us" | translate}}: <a>info@fahmpartners.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="dark-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col p-set">
                        <div class="footer-link">
                            <div class="sub-title">
								<div class="footer-title">
									<h4 translate>footer.categories</h4>
								</div>
								<div class="footer-contant" *ngIf="categories">
                                    <ul>
									<li *ngFor="let category of categories">
                                        <a [routerLink]="category.path" [queryParams]="category.queryParams">{{ category.name }}</a>
                                    </li>
                                    </ul>
								</div>
							</div>
                        </div>
                        <div class="footer-link-b">
                            <div class="sub-title">
								<div class="footer-title">
                                    <h4 translate>footer.choose</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul>
                                        <li><a href="javascript:void(0)" translate>footer.shipping-returns</a></li>
                                        <li><a href="javascript:void(0)" translate>footer.secure-shopping</a></li>
                                        <li><a href="javascript:void(0)" translate>footer.gallary</a></li>
                                        <li><a href="javascript:void(0)" translate>footer.affiliates</a></li>
                                        <li><a href="javascript:void(0)" translate>footer.contacts</a></li>
                                    </ul>
                                </div>
							</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} {{ "powered-by-fahm" | translate }}</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                     
                    </ul>
                  </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->