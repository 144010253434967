import { CustomerAddress } from './address';

export class Customer {
  CustomerKey: string;
  CustomerID: string;
  OrganizationCode: string;
  LoginID: string;
  CustomerType: string;
  CustomerLevel: string;
  Status: string;
  Vertical: String;
  ContactList: CustomerContact[];
  PrimaryContact: CustomerContact;
  AddressList: CustomerAddress[];
  ShippingAddresses: CustomerAddress[];
  BillingAddresses: CustomerAddress[];
  DefaultShipping: CustomerAddress;
  DefaultBilling: CustomerAddress;
  addressContext: any;
  digitalAnalytics: boolean = true;
  digitalAnalyticsAnonymous: boolean = true;
  marketingTrackingConsentBoolean: boolean = true;
  preferredCurrency: string;
  currencies: string[];
  curPass: string;
  newPass: string;
  cnfPass: string;
  constructor(obj: any = null) {
    if(obj != null) {
      Object.assign(this, obj);
      this.AddressList = [];
      this.ContactList = [];
      if(obj.CustomerContactList && obj.CustomerContactList.CustomerContact) {
        this.ContactList = obj.CustomerContactList.CustomerContact.map(x => new CustomerContact(x));
        if(this.ContactList.length > 0){
          for(let i = 0; i < this.ContactList.length; i++){
            if(this.ContactList[i].UserID === this.LoginID){
              this.PrimaryContact = this.ContactList[i];
            }
          }
          if(!this.PrimaryContact) {
            this.PrimaryContact = this.ContactList[0];
          }
        }
      }
      if(obj.CustomerAdditionalAddressList && obj.CustomerAdditionalAddressList.CustomerAdditionalAddress) {
        this.AddressList = obj.CustomerAdditionalAddressList.CustomerAdditionalAddress.map(x => new CustomerAddress(x));
      }
      this.ShippingAddresses = [];
      this.BillingAddresses = [];

      for(let a of this.AddressList){
        this.addAddress(a);
      }
      for(let c of this.ContactList){
        for(let a of c.AddressList){
          this.addAddress(a);
        }
      }
      this.currencies = [];
      if(obj.CustomerCurrencyList && obj.CustomerCurrencyList.CustomerCurrency){
        for(let c of obj.CustomerCurrencyList.CustomerCurrency){
          if(c.IsDefaultCurrency){
            this.preferredCurrency = c.Currency;
          }
          this.currencies.push(c.Currency);
        }
      }
      this.addressContext = {
          Shipping: {
              'addresses': this.ShippingAddresses,
              "selectedAddress": this.DefaultShipping
          },
          Billing: {
              'addresses': this.BillingAddresses,
              "selectedAddress": this.DefaultBilling
          }
      };
    }
  }

  private addAddress(a: CustomerAddress){
    if(a.IsShipTo){
      this.ShippingAddresses.push(a);
    }
    if(a.IsBillTo){
      this.BillingAddresses.push(a);
    }
    if(a.IsDefaultShipTo){
      this.DefaultShipping = a;
    }
    if(a.IsDefaultBillTo){
      this.DefaultBilling = a;
    }
  }

  stringify(): string {
    return JSON.stringify(this);
  }

}

export class CustomerContact {
  FirstName: string;
  LastName: string;
  EmailID: string;
  CustomerContactID: string;
  AddressList: CustomerAddress[];
  DateOfBirth: Date;
  UserID: string;
  birthYear: number;
  birthMonth: number;
  birthDate: number;
  gender: string = 'Unspecified';
  DayPhone: string;
  MobilePhone: string;


  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this,obj);
      this.AddressList = [];
      if(obj.CustomerAdditionalAddressList && obj.CustomerAdditionalAddressList.CustomerAdditionalAddress) {
        this.AddressList = obj.CustomerAdditionalAddressList.CustomerAdditionalAddress.map(x => new CustomerAddress(x));
      }
      if(obj.DateOfBirth){
        this.DateOfBirth = new Date(obj.DateOfBirth);
        this.birthYear = this.DateOfBirth.getUTCFullYear() || 0;
        this.birthMonth = this.DateOfBirth.getUTCMonth() + 1 || 0;
        this.birthDate = this.DateOfBirth.getUTCDate() || 0;
      }


    }
  }

}
