import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log("Missing: " + params.key);
    if (params.interpolateParams) {
      return params.interpolateParams["Default"] || params.key;
    }
    return params.key;
  }
}