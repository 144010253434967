import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { AppSettings } from './shared/services/app.settings';
import { AuthService } from './shared/services/auth.service';
import { InventoryService } from './shared/services/inventory.service';
import { OmsRestService } from './shared/services/oms.service';
import { PromisingService } from './shared/services/promising.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { RequestCache, RequestCacheService } from './shared/services/request-cache.service';
import { httpInterceptorProviders } from './shared/http-interceptors/index';
import { DatePipe } from '@angular/common';
import { CartService } from './shared/services/cart.service';
import { AdminGuard } from './shared/guards/admin.guard';
import { MissingTranslationHelper } from './shared/handlers/missing-translation.handler';
import { FeedService } from './shared/services/feed.service';
var firebaseConfig = {
  apiKey: "AIzaSyBuIL-oN1ozTH8GsiWNBjVkFpDUtQ1TmD8",
  authDomain: "fahm-forest-67a92.firebaseapp.com",
  projectId: "fahm-forest-67a92",
  storageBucket: "fahm-forest-67a92.appspot.com",
  messagingSenderId: "268020394933",
  appId: "1:268020394933:web:5a256db7efff1f80ba87fc",
  measurementId: "G-J5T8RXV421"
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireAnalyticsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    SharedModule,
    AppRoutingModule
  ],
  providers: [
    DatePipe,
    AuthGuard,
    AdminGuard,
    AppSettings,
    CartService,
    InventoryService,
    PromisingService,
    FeedService,
    AuthService,
    OmsRestService,
    {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHelper
    },
    { provide: RequestCache, useClass: RequestCacheService },
		httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
