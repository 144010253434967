import { Component } from '@angular/core';
import { ProductBoxComponent } from '../product-box.component';

@Component({
  selector: 'app-product-box-four',
  templateUrl: './product-box-four.component.html',
  styleUrls: ['./product-box-four.component.scss']
})
export class ProductBoxFourComponent extends ProductBoxComponent {

}
