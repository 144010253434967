<!--footer-->
<footer [ngClass]="class">
    <div class="white-layout">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col">
                        <div class="footer-link link-white">
                            <div class="footer-brand-logo">
                                <a href="javascript:void(0)">
                                    <img [src]="themeLogo" class="img-fluid" alt="">
                                </a>
                            </div>
                            <div class="social-white">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-title footer-mobile-title">
                                <h4>my account</h4>
                            </div>
                            <div class="footer-contant">
                                <ul *ngIf="categories">
                                    <li *ngFor="let category of categories">
                                        <a [routerLink]="category.path" [queryParams]="category.queryParams">{{ category.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer black-subfooter ">
        <div class="container">
            <div class="row ">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} {{ "powered-by-fahm" | translate }}</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->