import { Component } from '@angular/core';
import { FooterComponent } from '../footer.component';

@Component({
  selector: 'app-footer-three',
  templateUrl: './footer-three.component.html',
  styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent extends FooterComponent{

}
