<ng-template #storePickupModal let-modal>
  <div class="pickup-modal">
    <div class="title">
        <h2>{{ title }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" class="pickup-header">
      <div class="country">
        <label>Country</label>
        <select [(ngModel)]="address.Country">
          <option value=""></option>
          <option [value]="country.code"  *ngFor="let country of countries">{{ country.name }}</option>
        </select>
      </div>
      <div class="postal">
        <label>Postal Code</label>
        <input matInput placeholder="Zip/Postal Code" [(ngModel)]="address.ZipCode" required (blur)="search()">
      </div>
    </div>
    <div class="storeResults" *ngIf="storeResults">
      <div fxLayout="row" fxLayoutAlign="space-between start" class="record" *ngFor="let node of storeResults.ShipNodes">
          <div fxFlex="50" >
            <h4>{{ node.Description }} ({{ node.DistanceFromShipToAddress }} Miles)</h4>
            <div class="small-11 cell medium-6">
                <div>
                  {{ node.ShipNodePersonInfo.AddressLine1 }}
                </div>
                <div *ngIf="node.ShipNodePersonInfo.AddressLine2">
                  {{ node.ShipNodePersonInfo.AddressLine2 }}
                </div>
                <div>
                  {{ node.ShipNodePersonInfo.City }}, {{ node.ShipNodePersonInfo.State }} {{ node.ShipNodePersonInfo.ZipCode }}
                </div>
                <div>
                  <button class="btn btn-outline" (click)="setMyStore(node)" *ngIf="!currentStore || node.ShipNode !== currentStore">Set as My Store</button>
                </div>
            </div>
          </div>
          <div fxFlex="25">
            <div class="available" *ngIf="node.IsAvailable">
              {{ node.Availability.totalAvailableQuantity }} Available - <span *ngIf="node.Availability.earliestDisplay"></span>{{ node.Availability.earliestDisplay }}
            </div>
            <div class="unavailable" *ngIf="!node.IsAvailable" translate>
              unavailable
            </div>
          </div>
          <div fxFlex="25" style="text-align: right">
           
            <button class="btn btn-outline" *ngIf="node.IsAvailable" (click)="selectNode(node)">Pickup Here</button>
          </div>

      </div>

    </div>

  </div>
</ng-template>