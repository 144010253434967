import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      
      return this.authService.access$.pipe(map(admin => {
        if(admin.Ecomm) {
          return true;
        }
        console.log('Not Logged In');
        this.router.navigate(['/sign-in'], { queryParams: {returnUrl: state.url} });
        return false;
      }));
   


  }
}
