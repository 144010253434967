<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                </div>
                                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li>
    <li class="onhover-div mobile-setting">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i></div>
      <div class="show-div setting">
        <h6 translate>Inventory</h6>
        <ul>
          <li>
            <a href="javascript:void(0)" (click)="toggleInventory()" translate>Toggle Inventory</a>
          </li>
        </ul>
        
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ cart?.totalCartCount }}</span>
      <ul class="show-div shopping-cart" *ngIf='!cart?.totalCartCount'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='cart?.totalCartCount'>
        <li *ngFor="let orderLine of cart.OrderLines">
          <div class="media">
            <a [routerLink]="['//shop/product/', orderLine.ItemDetails.ItemID]">
              <img class="mr-3" [src]="orderLine.ItemDetails?.images[0].small" [alt]="orderLine?.ItemDetails.title">
            </a>
            <div class="media-body">
              <a [routerLink]="['//shop/product/', orderLine.ItemDetails.ItemID]">
                <h4>{{ orderLine.ItemDetails?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ orderLine.LineOverallTotals.DisplayLineTotal | currency : cart.PriceInfo.Currency }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(orderLine)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ cart.OverallTotals.LineSubTotal | currency:cart.PriceInfo.Currency }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>