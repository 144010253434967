<div *ngIf="!loader && !product.variants">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
      <span class="preorder" *ngIf="product.IsPreOrder" translate>pre-order</span>
      <span class="crowdsourced" *ngIf="product.IsCrowdSourced" translate>crowd-sourced</span>
    </div>
    <a [routerLink]="['//shop/product/', product.id, product.UnitOfMeasure]">
    <div class="front">
      <span class="helper"></span>
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].small" 
          class="img-fluid lazy-loading" 
          alt="{{ product.images[0].alt }}" />
          <div *ngIf="product.availability && !product.availability.isAvailable()" class="overlay unavailable" translate>unavailable</div>
          <div *ngIf="product.availability && product.availability.isAvailable()" class="overlay available">
            <div class="overlay-container">
              <div *ngIf="product.availability.isCurrentlyAvailable()"><span class="label">{{"Onhand" | translate }}:</span> {{ product.availability.getAvailableQuantity() }}</div>
              <div *ngIf="product.nodeAvailability && product.nodeAvailability.getAvailableQuantity() > 0"><span class="label">{{"in-my-store" | translate }}:</span> {{ product.nodeAvailability.getAvailableQuantity() }}</div>
              <div *ngIf="product.availability.getFutureQuantity() > 0"><span class="label">{{"future-quantity" | translate }}:</span> {{ product.availability.getFutureQuantity() }}</div>
              <div class="date"><span class="label">{{"ead"}}:</span> {{ product.availability.getEAD() | date }}</div>
              <div class="date" *ngIf="product.nodeAvailability && product.nodeAvailability.getEarliestRecord() && product.nodeAvailability.getEarliestRecord().earliestDisplay === 'Today'"><span class="label" translate>order-by-8</span> {{"Today" | translate}}</div>
              <div class="date" *ngIf="product.nodeAvailability && product.nodeAvailability.getEarliestRecord().totalAvailableQuantity > 0 && product.nodeAvailability.getEarliestRecord() && product.nodeAvailability.getEarliestRecord().earliestDisplay !== 'Today'"><span class="label" translate>available-for-pickup</span> {{ product.nodeAvailability.getEarliestRecord().earliestDisplay }}</div>
          </div>
          
          </div>
    </div>
  </a>
    <div class="back" *ngIf="onHowerChangeImage && product.images.length > 1">
      <a [routerLink]="['//shop/product/', product.id, product.UnitOfMeasure]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].small" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.small" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.small)">
          <img [lazyLoad]="image.small">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" [title]="'add-to-cart' | translate" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" [title]="'add-to-cart' | translate" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" [title]="'Pickup' | translate" (click)="pickup(product)">
        <i class="fa-building"></i>
      </a>
      <a href="javascript:void(0)" [title]="'Add-To-Wishlist' | translate" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" [title]="'Quick-View' | translate" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" [title]="'Compare' | translate" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['//shop/product/', product?.id]">
        <h6>{{ product?.title | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <h4>
        {{ product?.newPrice | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
    </div>
  </div>
</div>

<div *ngIf="!loader && product.variants">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.variants.selectedItem.new" translate>new</span>
      <span class="lable4" *ngIf="product.variants.selectedItem.sale" translate>on-sale</span>    
      <span class="preorder" *ngIf="product.variants.selectedItem.IsPreOrder" translate>pre-order</span>
      <span class="crowdsourced" *ngIf="product.variants.selectedItem.IsCrowdSourced" translate>crowed-sourced</span>
    </div>
    <a [routerLink]="['//shop/product/', product.id, product.UnitOfMeasure]">
    <div class="front">
      <span class="helper"></span>
        <img 
          [defaultImage]="'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="product.variants.selectedItem.images[0].small" 
          class="img-fluid lazy-loading" 
          alt="{{ product.variants.selectedItem.images[0].alt }}" />
          <div *ngIf="product.variants.selectedItem.availability && !product.variants.selectedItem.availability.isAvailable()" class="overlay unavailable" translate>unavailable</div>
          <div *ngIf="product.variants.selectedItem.availability && product.variants.selectedItem.availability.isAvailable()" class="overlay available">
              <div class="overlay-container">
                  <div *ngIf="product.variants.selectedItem.availability.isCurrentlyAvailable()"><span class="label">{{"Onhand" | translate }}:</span> {{ product.variants.selectedItem.availability.getAvailableQuantity() }}</div>
                  <div *ngIf="product.variants.selectedItem.nodeAvailability && product.variants.selectedItem.nodeAvailability.getAvailableQuantity() > 0"><span class="label">{{"in-my-store" | translate }}:</span> {{ product.variants.selectedItem.nodeAvailability.getAvailableQuantity() }}</div>
                  <div *ngIf="product.variants.selectedItem.availability.getFutureQuantity() > 0"><span class="label">{{"future-quantity" | translate }}:</span> {{ product.variants.selectedItem.availability.getFutureQuantity() }}</div>
                  <div class="date"><span class="label">{{"ead"}}:</span> {{ product.variants.selectedItem.availability.getEAD() | date }}</div>
                  <div class="date" *ngIf="product.variants.selectedItem.nodeAvailability && product.variants.selectedItem.nodeAvailability.getEarliestRecord() && product.variants.selectedItem.nodeAvailability.getEarliestRecord().earliestDisplay === 'Today'"><span class="label" translate>order-by-8</span> {{"Today" | translate}}</div>
                  <div class="date" *ngIf="product.variants.selectedItem.nodeAvailability && product.variants.selectedItem.nodeAvailability.getEarliestRecord().totalAvailableQuantity > 0 && product.variants.selectedItem.nodeAvailability.getEarliestRecord() && product.variants.selectedItem.nodeAvailability.getEarliestRecord().earliestDisplay !== 'Today'"><span class="label" translate>available-for-pickup</span> {{ product.variants.selectedItem.nodeAvailability.getEarliestRecord().earliestDisplay }}</div>
              </div>
          
          </div>
    </div>
  </a>
    <div class="back" *ngIf="onHowerChangeImage && product.images.length > 1">
      <a [routerLink]="['//shop/product/', product.variants.selectedItem.id]">
        <img [src]="product.variants.selectedItem.images[1].small" class="img-fluid lazy-loading" alt="{{ product.variants.selectedItem.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.small" *ngFor="let image of product.variants.selectedItem.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.small)">
          <img [lazyLoad]="image.small">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product.variants.selectedItem)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product.variants.selectedItem)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Pickup" (click)="pickup(product.variants.selectedItem)">
        <i class="fa-building"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product.variants.selectedItem)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product.variants.selectedItem)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['//shop/product/', product?.id]">
        <h6>{{ product.variants.selectedItem?.title | titlecase }}</h6>
      </a>
      <p>{{ product.variants.selectedItem?.description }}</p>
      <h4>
        {{ product.variants.selectedItem?.newPrice | currency:currency?.currency:'symbol' }}
        <del *ngIf="product.variants.selectedItem?.discount"><span class="money"> {{ product.variants.selectedItem?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
      <div *ngFor="let option of product.variants.sequence">
        <ul class="color-variant" *ngIf="option.AttributeID === 'color'">
          <li [class]="att.Value"  [ngClass]="product.variants.getButtonStyle(option, att)" *ngFor="let att of product.variants.distinctAttributes[option.AttributeID]" [ngStyle]="{'background-color': att.Value}"
            (click)="product.variants.setSelected(option, att)">
          </li>
        </ul>
        <ul class="option-variant" *ngIf="option.AttributeID !== 'color'">
          <li [class]="att.Value" [ngClass]="product.variants.getButtonStyle(option, att)" *ngFor="let att of product.variants.distinctAttributes[option.AttributeID]" (click)="product.variants.setSelected(option, att)">
            {{ att.ShortDescription }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<app-store-pickup #storePickupModal></app-store-pickup>

