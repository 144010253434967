export class UserState {
  LocaleCode: string;
  UserName: string;
  UserGroupID: string;
  LoginID: string;
  ContactAddress_Key: string;
  User_key: string;
  OrganizationCode: string;
  UserToken: string;
  CartKey: string;
  IsGuest: boolean;

  constructor(obj?: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.IsGuest = (obj.LoginID == undefined);
    } else if(localStorage.getItem("session")){
      Object.assign(this, JSON.parse(localStorage.getItem("session")));
    } else {
      this.LoginID = undefined;
      this.IsGuest = true;
    }
  }


  saveState() {
    localStorage.setItem("session", JSON.stringify(this));
  }

  logout() {
    localStorage.removeItem("session");
  }

  clone(): UserState {
    return new UserState(JSON.parse(JSON.stringify(this)));
  }
}
