import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Product } from '../classes/product';
import { AuthService } from './auth.service';


@Injectable()
export class PromisingService {

  public promisingCallStack = [];
  private showPromising: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private carrierServices = [{
    value: "AURE_STANDARD",
    description: "Standard"
  },{
    value: "AURE_EXPRESS",
    description: "Express"
  },{
    value: "AURE_PRIORITY",
    description: "Priority"
  }];

  private days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    
  }

  toggleIVDisplay(): void {
    this.showPromising.next(!this.showPromising.getValue());
  }

  showIVDisplay(): Observable<boolean> {
    return this.showPromising.asObservable();
  }

 
  get(url: string, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.get(`https://api.watsoncommerce.ibm.com/promising/v1/${template.ivTenantID}${url}`, options);
      }
    }));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.delete(`https://api.watsoncommerce.ibm.com/promising/v1/${template.ivTenantID}${url}`, options);
      }
    }));
  }

  post(url: string, input: any, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.post(`https://api.watsoncommerce.ibm.com/promising/v1/${template.ivTenantID}${url}`, input, options);
      }
    }));
  }

  patch(url: string, input: any, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.patch(`https://api.watsoncommerce.ibm.com/promising/v1/${template.ivTenantID}${url}`, input, options);
      }
    }));
  }

  put(url: string, input: any, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.put(`https://api.watsoncommerce.ibm.com/promising/v1/${template.ivTenantID}${url}`, input, options);
      }
    }));
  }

  getIsoDate(date: Date): string {
    return date.toISOString().substring(0, 10) + "T00:00:00Z";
  }

  loadNodeLocation(node: any): Observable<any> {
    let nodeType = node.NodeType.toUpperCase();
    if(nodeType !== 'DC' && nodeType !== 'STORE'){
      nodeType = 'DC';
    }
      return this.patch(`/config/nodes/${node.ShipnodeKey}/basic`, {
          "basicConfigs": {
              "location": {
                  "format": "POSTAL_CODE",
                  "value": node.ShipNodePersonInfo.ZipCode
              },
              "timeZoneId": "America/New_York",
              "nodeType": nodeType
          }
      });
  }

  loadNodeTypeLeadtime(nodeType: string, hours: string) {
      return this.patch(`/config/nodeTypes/${nodeType.toUpperCase()}/leadTime`, {
          "leadTime": `P0DT${hours}H0M0S`
      });
  }

  loadNodeLeadtime(nodeId: string, hours: string) {
      return this.patch(`/config/nodes/${nodeId}/leadTime`, {
          "leadTime": `P0DT${hours}H0M0S`
      });
  }

  getNodeTypeLeadtime(nodeType: string): Observable<any> {
    return this.get(`/config/nodeTypes/${nodeType.toUpperCase()}/leadTime`);
  }
 
  loadNodeTypeProcessingTimes(nodeType: string, hours: string) {
    return this.patch(`/config/nodeTypes/${nodeType.toUpperCase()}/processingTime`, {
        "processingTime": `P0DT${hours}H0M0S`
    });
  }

  loadNodeCapacity(node: string): Observable<any> {
    return this.put(`/config/nodes/${node}/capacitySchedules/2000-01-01/2030-01-01`, {
      "monday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }],
      "tuesday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }],
      "wednesday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }],
      "thursday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }],
      "friday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }],
      "saturday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }],
      "sunday": [{ "fromTimeIncl": "08:00:00", "toTimeExcl": "22:00:00" }]
    });
  }

    loadNodeProcessingtime(nodeId: string, hours: string) {
        return this.patch(`/config/nodes/${nodeId}/processingTime`, {
            "processingTime": `P0DT${hours}H0M0S`
        });
    }

    getNodeTypeProcessingtime(nodeType: string): Observable<any> {
    return this.get(`/config/nodeTypes/${nodeType.toUpperCase()}/processingTime`);
    }

    loadCarrierTransit(carrierService: string): Observable<any> {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'text/csv',
              'Accept': '*/*'
            })
        };        
        return this.httpClient.get(`/assets/data/zipCodeCarrier-${this.carrierServices.find((record) => record.value === carrierService).description}.csv`, {
          headers: new HttpHeaders({
            'Accept': 'text/csv'
          }),
          responseType: 'text'
        }).pipe(switchMap((file: string) => {
            return this.put(`/config/transitTimes/carrierServices/${carrierService}`, file, httpOptions);
        }))
       
    }

    loadCarrierPickupSchedule(carrierService: string, shipNode: string , times: any[]): Observable<any> {
      const input = this.days.reduce((previous, nextValue) => {
        previous[nextValue] = times;
        return previous;
      }, {});
      return this.put(`/config/nodes/${shipNode}/carrierServices/${carrierService}/carrierPickupSchedules/2021-01-01/2029-12-31`, JSON.stringify(input));
    }

    loadItemNodeAvailability(item: Product, node: string, quantity: number, startDate: Date, endDate: Date = new Date("2030-01-01")){
      return this.put(`availabilityWindows/nodes/${node}?itemId=${item.ItemID}&unitOfMeasure=${item.UnitOfMeasure}&productClass=&segment=&segmentType=`,[
        {
          "availableFrom": startDate.toISOString(),
          "availableTill": endDate.toISOString(),
          "qty": quantity
        }
      ])
    }

  getEDD(item: Product, zipCode: string): Observable<any> {
    const currentDate = new Date();
    return this.get(`edd?itemId=${item.ItemID}&unitOfMeasure=${item.UnitOfMeasure}&productClass=&segment=&segmentType=&destinationFormat=POSTAL_CODE&destinationValue=${zipCode}&referenceTime=${currentDate.toISOString()}`);
  }

  getCarrierServices(): Observable<any> {
    return of(this.carrierServices);
  }
}
