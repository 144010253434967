import { Component } from '@angular/core';
import { FooterComponent } from '../footer.component';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent extends FooterComponent {

}
