<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3 i18n>create account</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="registerForm" (ngSubmit)="onRegisterFormSubmit(registerForm.value)">
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="name" i18n>Name</label>
                                <input type="text" class="form-control" formControlName="name" id="fname" placeholder="First Name" required="">
                            </div>
                            <div class="col-md-6">
                                <label for="email" i18n>email</label>
                                <input type="text" class="form-control" formControlName="email" id="email" placeholder="Email" required="">
                            </div>
                        </div>
                        <div class="form-row">
                            
                            <div class="col-md-6">
                                <label for="password" i18n>Password</label>
                                <input type="password" class="form-control" formControlName="password" id="password" placeholder="Enter your password" required="">
                            </div>
                            <div class="col-md-6">
                                <label for="confirmPassword" i18n>Confirm Password</label>
                                <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword" placeholder="Re-Enter your password" required="">
                            </div>
                            <button class="btn btn-solid" type="submit" i18n>create Account</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->