import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Category, Product, Variants, VariationItem } from '../classes/product';
import { InventoryService } from './inventory.service';
import { AppSettings } from './app.settings';
import { AuthService } from './auth.service';
import { SearchResults } from '../classes';
import { OmsRestService } from './oms.service';
import { CartService } from './cart.service';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

export class Data {
  constructor(public categories: Category[],
              public compareList: Product[],
              public wishList: Product[],
              public cartList: Product[],
              public totalPrice: number,
              public totalCartCount: number) { }
}


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public Data = new Data(
    [], // categories
    [], // compareList
    [],  // wishList
    [],  // cartList
    null, //totalPrice,
    0 //totalCartCount
)
  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products
  private topCategories: BehaviorSubject<Category[]>;
  
  constructor(
    private appSettings: AppSettings, 
    private authService: AuthService, 
    private omsService: OmsRestService,
    private cartService: CartService,
    private inventoryService: InventoryService,
    private toastrService: ToastrService
  ) { 
    this.topCategories = new BehaviorSubject<Category[]>([]);
      this.authService.template$.subscribe(template => {
        if(template && template.baseOrg){
          const apiInput = {"SearchCatalog": {
            "CallingOrganizationCode": template.baseOrg,
            "CategoryDepth": 3
          }};
          if(template.locale){
            apiInput.SearchCatalog['DisplayLocalizedFieldInLocale'] = template.locale
          }

          this.omsService.callApi("searchCatalogIndex", apiInput , "searchCatalogIndexCategories", true, `topCategories_${template.baseOrg}`)
            .subscribe((response) => {
              let temp: Category[] = [];
      
              // Moves the top level categories to a parent category of More when
              // there are more than 3 parent categories to fit the display.

      
              let i = 0;
      
              if(response.CategoryList && response.CategoryList.Category)
              for(let c of response.CategoryList.Category) {
              temp.push(new Category(c));
                i++;
              }

              this.topCategories.next(temp);
            });
        }
      })
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  public getCategories(): Observable<Category[]>{
      return this.topCategories.asObservable()
      // return this.http.get<Category[]>(this.url + 'categories.json');
  }
  
  getTopCategories(depth: number = 3, limit: number = -1): Observable<Category[]> {
    return this.topCategories.asObservable();        
  }

  public getCategoryDetails(categoryPath: string): Observable<Category> {
    const apiInput ={
      Category: {
        "CategoryPath": categoryPath,
        "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg,
        "DisplayLocalizedFieldInLocale": this.appSettings.getCurrentSettings().locale
      }
    };
    return this.omsService.callApi('getCategoryDetails', apiInput, "getCategoryDetails").pipe(map(x => new Category(x)));
  }
  // Product

  getProductsByID(productIds: string[]): Observable<Product[]> {
    let input = {
      "Item":{
      "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg,
      "DisplayLocalizedFieldInLocale": this.appSettings.getCurrentSettings().locale,
      "UnitOfMeasure": "EACH",
      "CustomerInformation": {},
      "ComplexQuery": {
        "Or": {
          "Exp": productIds.map(x => {
            return {
              "Name": "ItemID",
              "Value": x
            }
          })
        }
      }
    }};
    if(this.authService.getCurrentCustomer()){
      input.Item.CustomerInformation["CustomerID"] = this.authService.getCurrentCustomer().CustomerID;
      if(this.authService.getCurrentCustomer().CustomerLevel){
        input.Item.CustomerInformation["CustomerLevel"] = this.authService.getCurrentCustomer().CustomerLevel;
      }
    }
    const cacheKey = `products_${this.appSettings.getCurrentSettings().baseOrg}_${productIds.reduce((s, value) => `${s}_${value}`, '')}`;
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID && this.inventoryService){
        return this.omsService.callApi("getItemListForOrdering", input, "getItemVariationList", true, cacheKey, false)
          .pipe(map(x => {
          let sr = new SearchResults({
            "ItemList": x
          });
          if(this.inventoryService){
            this.authService.user$.subscribe(user => {
              if(user.shipNode) {
                sr.loadInventory(this.inventoryService, user.shipNode);
              } else {
                sr.loadInventory(this.inventoryService);
              }
            })
          }
          return sr.Items;
        }));
      } else {
        return this.omsService.callApi("getItemListForOrdering", input, "getItemList", true, cacheKey, false).pipe(map(x => {
          let sr = new SearchResults(x);
          console.log("Search Results", sr);
          return sr.Items;
        }));
      }
    }));
  }

  public getProductsByCategory(categoryPath: string): Observable<Product[]> {
    return this.getProducts(categoryPath);
  }

  public getProductSearch(categoryPath: string, searchCriteria?: string, size: number = 12, page: number = 1): Observable<SearchResults> {
      let input = {
        "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg,
        "DisplayLocalizedFieldInLocale": this.appSettings.getCurrentSettings().locale,
        "PageSize": size,
        "PageNumber": page,
        "Item": {
          "IsForOrdering": "Y",
          "ExcludeChildItemsOfModelItems": "Y",
          "CustomerInformation": {}
          
        }
      };

    if(this.authService.getCurrentCustomer()){
      input.Item.CustomerInformation["CustomerID"] = this.authService.getCurrentCustomer().CustomerID;
      if(this.authService.getCurrentCustomer().CustomerLevel){
        input.Item.CustomerInformation["CustomerLevel"] = this.authService.getCurrentCustomer().CustomerLevel;
      }
    }
    if(categoryPath){
        input["CategoryList"] = {
            "Category": {
                "Path": categoryPath
            }
        };
    }
    if(searchCriteria){
      input["Terms"] = {
        "Term": searchCriteria.split(" ").map(x => {
          return {
            "Value": x
          }
        })
      };
    }
    
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID && this.inventoryService){
        return this.omsService.callApi("searchCatalogIndex", {"SearchCatalog": input}, "searchResultsNoInventory", true, `${categoryPath}_${searchCriteria}_${size}_${page}`).pipe(map(x => {
          let sr = new SearchResults(x);
          if(this.inventoryService){
            this.authService.user$.subscribe(user => {
              
              if(user.shipNode) {
                sr.loadInventory(this.inventoryService, user.shipNode);
              } else {
                sr.loadInventory(this.inventoryService);
              }

            })
            
          }

          return sr;
        }));
      } else {
        return this.omsService.callApi("searchCatalogIndex", {"SearchCatalog": input}, "searchCatalogIndexResults", true, `${this.appSettings.getCurrentSettings().baseOrg}_${categoryPath}_${searchCriteria}_${size}_${page}`).pipe(map(x => {
          let sr = new SearchResults(x);
          return sr;
        }));
      }
    }));
  }
  public getProducts(categoryPath: string): Observable<Product[]>{       
    return this.getProductSearch(categoryPath).pipe(map(x => {
      return x.Items;
    }))
  }

 getVariationItems(Item: Product): Observable<VariationItem>{
  if(Item && Item.PrimaryInformation && Item.PrimaryInformation.IsModelItem){
    var input = {"Item": {
        "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg,
        "DisplayLocalizedFieldInLocale": this.appSettings.getCurrentSettings().locale,
        "GetAvailabilityFromCache": "Y",
        "ClassificationCodes":{
          "Model": Item.ItemID
        },
        "CustomerInformation":{},
        "ItemAttributeGroupTypeList":{
          "ItemAttributeGroupType":[
            {
              "ClassificationPurposeCode":"DISTINCT_ATTRIBUTES"
            }
          ]
        }
      }
    }
    if(this.authService.getCurrentCustomer()){
      input.Item.CustomerInformation["CustomerID"] = this.authService.getCurrentCustomer().CustomerID;
      if(this.authService.getCurrentCustomer().CustomerLevel){
        input.Item.CustomerInformation["CustomerLevel"] = this.authService.getCurrentCustomer().CustomerLevel;
      }
    }
    return this.omsService.callApi("getItemListForOrdering", input, "getItemVariationList")
      .pipe(map((response) => {
        if(response && response.Item){
          return new VariationItem(response);
        }
        return undefined;
      }));
  }
}

  // Get Products By Slug
  public getProductBySlug(slug: string, unitOfMeasure: string): Observable<Product> {
    let input = {"Item":{
      "ItemID": slug,
      "UnitOfMeasure": unitOfMeasure,
      "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg,
      "DisplayLocalizedFieldInLocale": this.appSettings.getCurrentSettings().locale,
      "ItemAssociationTypeList": {
        "ItemAssociationType": [
          {
            "Type": "Alternative.Y"
          }, {
            "Type": "Substitutions"
          }, {
            "Type": "CrossSell"
          }, {
            "Type": "UpSell"
          }
        ]
      }
    }};
    return this.omsService.callApi("getCompleteItemList", input, "ItemDetails", true, `product_${slug}`)
      .pipe(map((response) => {
        if(response.Item && response.Item.length >= 1){
          let t = new Product(response.Item[0], response.Currency);
          return t;
        }
      }));
   
  }

  public getShipNodes(organizationCode: string = "Bambu-Corp"): Observable<any> {
    return this.omsService.callApi("getShipNodeList", {
      "ShipNode": {
        "OwnerOrganization": {
          "InventoryOrganizationCode": organizationCode
        }
      }
    }, "nodeList")
  }
  
  public getDistributionRuleList(): Observable<any> {
    return this.omsService.callApi("getDistributionRuleList", {
      "DistributionRule": {
        "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg
      }
    }, "getDistributionRuleList");
  }
 

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);
    
    if(!stock) return false

    this.cartService.addProductToCart(product, product.quantity, product.UnitOfMeasure, 'SHP', undefined, this.inventoryService);

    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

    // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      return product.reduce((prev, curr: Product) => {
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return (prev + price * curr.quantity) * this.Currency.price;
      }, 0);
    }));
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.getProducts(filter);
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    } 
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) { 
      currentPage = 1; 
    } else if (currentPage > totalPages) { 
      currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage =  currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
