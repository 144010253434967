import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { InventoryService } from '../services/inventory.service';
import { DatePipe } from '@angular/common';


@Injectable()
export class IvInterceptor implements HttpInterceptor {
  
  private AUTH_HEADER = "Authorization";
  private token = "";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private authService: AuthService;
  private ivService: InventoryService;

  constructor(inj: Injector, private datePipe: DatePipe) {
    this.authService = inj.get(AuthService);
    this.ivService = inj.get(InventoryService);
  }

  updateEDD(response: any, future: number = 2): any {
    if(response && response.lines){
      let lines = response.lines.map(line => {
        if(line.networkAvailabilities) {
          let networkAvailabilities = line.networkAvailabilities.map(avail => {
            if(avail.totalAvailableQuantity > 0) {
              avail["earliestDeliveryTs"] = new Date(new Date(avail.earliestShipTs).getTime() + (1000 * 60 * 60 * 24 * future)).toISOString();
            }
            return avail;
          });
          line["networkAvailabilities"] = networkAvailabilities;
        } else if(line.shipNodeAvailability) {
          let shipNodeAvailability = line.shipNodeAvailability.map(avail => {
            console.log(new Date().getHours())
            if(avail.earliestShipTs) {
              const earliest = new Date(avail.earliestShipTs);
              if(earliest < new Date(new Date().setHours(21, 0, 0, 0))) {
                let pickupDate = new Date();
                pickupDate = new Date(pickupDate.setHours(20, 0, 0, 0));
                avail["earliestDisplay"] = "Today";
                avail["orderByTs"] = pickupDate;
              } else if (earliest.getHours() < 21) {
                avail["earliestDisplay"] = this.datePipe.transform(earliest, "longDate");
              } else {
                avail["earliestDisplay"] = this.datePipe.transform(new Date(earliest.getTime() + (1000 * 60 * 60 * 5)))
              }
            }
            return avail;
          });
          line["shipNodeAvailability"] = shipNodeAvailability;
        }

        return line;
      })
      response["lines"] = lines;
    }
    return response;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/api.watsoncommerce.ibm.com\/inventory/)) {
      return next.handle(request);
    }

    if(this.token === "") {
        this.authService.getIVDetails().subscribe(r => {
            if(r && r.access_token){
                this.token = r.access_token;
            }

        })
    }

    if (!request.headers.has('Content-Type')) {
        request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json')
        });
    }

    request = this.addAuthenticationToken(request);
    let ivCall = {
      url: request.url,
      method: request.method
    };
    if(request.body){
      

      ivCall['request'] = JSON.stringify(request.body, undefined, 2);
    }
    return next.handle(request).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if(event.status < 300 && event.body){
              ivCall['response'] = JSON.stringify(this.updateEDD(event.body), undefined, 2);
            }  
            this.ivService.ivCallStack.push(ivCall);
          }
        }),
        catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401 || error.status === 403) {
                // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                if (this.refreshTokenInProgress) {
                  // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                  // which means the new token is ready and we can retry the request again
                  return this.refreshTokenSubject.pipe(
                    filter(result => result !== null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(request)))
                  );
                } else {
                  this.refreshTokenInProgress = true;
      
                  // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                  this.refreshTokenSubject.next(null);
                  
                  return this.refreshAccessToken().pipe(
                    switchMap((success: boolean) => {               
                      this.refreshTokenSubject.next(success);
                      return next.handle(this.addAuthenticationToken(request));
                    }),
                    // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                    // for the next time the token needs to be refreshed
                    finalize(() => this.refreshTokenInProgress = false)
                  );
                }
              } else {
                return throwError(error);
              }
        })
    ) as Observable<HttpEvent<any>>;
  }

  private refreshAccessToken(): Observable<any> {
    return this.authService.getIVDetails().pipe(map(r => {
        this.token = r.access_token;
        return r;
    }))

  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.token) {
      return request;
    }
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/api.watsoncommerce.ibm.com\/inventory/)) {
      return request;
    }


    
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.token)
    });
  }
}
