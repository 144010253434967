import { Order } from './order';

export class Pagination {
  public IsFirstPage: boolean;
  public IsLastPage: boolean;
  public PageNumber: number;
  public PageSize: number;
  public Output: any;
  public TotalNumberOfPages: number;
  public TotalNumberOfRecords: number;
  constructor(obj: any, type?: string){
    if(obj != null) {
      Object.assign(this, obj);
      this.IsLastPage = (obj.IsLastPage === 'Y');
      this.IsFirstPage = (obj.IsFirstPage === 'Y');
      this.PageNumber = parseInt(obj.PageNumber);
      this.PageSize = parseInt(obj.PageSize);
      this.TotalNumberOfPages = parseInt(obj.TotalNumberOfPages);
      if(type && type === 'Order'){
        this.TotalNumberOfRecords = parseInt(obj.Output.OrderList.TotalOrderList);
        this.Output = obj.Output.OrderList.Order.map(x => new Order(x));
      }
    }
  }
}
