import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { parseString } from "xml2js";
import { NewsRss } from "../classes";



@Injectable({
	providedIn: 'root'
})

export class FeedService {

	constructor(
		private httpClient: HttpClient
	) { }

    getRssFeedData(feed: string): Observable<any> {
        const requestOptions: Object = {
          observe: "body",
          responseType: "text"
        };
        return this.httpClient
          .get<any>(feed, requestOptions);
      }
}