import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';
import { OmsRestService } from '../services/oms.service';

@Component({
  selector: 'app-header',
  template: '<div></div>'
})
export class HeaderComponent implements OnInit {
  
  @Input() class: string;
  themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public orgName: string = '';
  public stick: boolean = false;

  constructor(
    private authService: AuthService,
    private omsService: OmsRestService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    this.authService.template$.subscribe(r => {
      if(r) {
        this.themeLogo = r.logo;
        this.orgName = r.name;
      }

    })
  }

  signout() {
    this.authService.signOut();
  }

  deleteCart() {
    this.cartService.deleteCart();
  }
  loadTemplates() {
    this.omsService.loadApiTemplates();
  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
