import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpParams,
  HttpHandler
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

import { RequestCache } from '../services/request-cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private cache: RequestCache;

  constructor(inj: Injector) {
    this.cache = inj.get(RequestCache);
  }


  intercept(req: HttpRequest<any>, next: HttpHandler){
    if(!isCachable(req)) {
      return next.handle(req);
    }
    return next.handle(req);
    const cachedResponse = this.cache.get(req);

    if(req.headers.get('x-refresh')){
      const results$ = sendRequest(req, next, this.cache);
      return cachedResponse ?
        results$.pipe( startWith(cachedResponse) ) :
        results$;
    }

    return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache);
  }
}

function getKey(req: HttpRequest<any>): string | undefined {
  const params = req.params;
  if(params.get("cacheKey")){
    return params.get("cacheKey");
  }
  return undefined;
}

function isCachable(req: HttpRequest<any>) {
  const params = req.params;
  return (params.has("cache") && getKey(req));
}

function sendRequest(
  req: HttpRequest<any>,
  next: HttpHandler,
  cache: RequestCache): Observable<HttpEvent<any>> {

  // No headers allowed in npm search request
  const noHeaderReq = req.clone({ params: new HttpParams() });

  return next.handle(noHeaderReq).pipe(
    tap(event => {
      // There may be other events besides the response.
      if (event instanceof HttpResponse) {
        cache.put(req, event); // Update the cache.
      }
    })
  );
}
