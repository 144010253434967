<!-- footer -->
<footer [ngClass]="class">
    <div class="white-layout" *ngIf="mainFooter">
        <section class="p-0">
            <div class="container-fluid">
                <div class="row footer-theme2 section-light footer-border">
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title footer-mobile-title">
                                    <h4 translate>footer.about</h4>
                                </div>
                                <div class="footer-contant">
                                    <div class="footer-logo">
                                        <img [src]="themeLogo" alt="">
                                    </div>
                                    <div class="social-white">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col form-p p-0">
                        <div class="footer-block">
                            <div class="subscribe-white">
                                <h2 translate>newsletter</h2>
                                <form>
                                    <div class="form-group">
                                        <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" [placeholder]="'footer.enter-email' | translate">
                                        <button type="submit" class="btn btn-solid black-btn" translate>footer.subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title">
                                    <h4 translate>store-info</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul class="contact-details">
                                        <li><i class="fa fa-map-marker"></i>100 Mural St. Suite 100, Richmond Hill ON, L4B 1J3</li>
                                        <li><i class="fa fa-phone"></i>{{ "call-us" | translate }}: (855) 909-3999</li>
                                        <li><i class="fa fa-envelope-o"></i>{{ "email-us" | translate}}: <a>info@fahmpartners.com</a></li>>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="white-layout box-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col">
                        <div class="footer-link link-white">
                            <div class="footer-title">
                                <h4>my account</h4>
                            </div>
                            <div class="footer-contant">
                                <ul *ngIf="categories">
                                    <li *ngFor="let category of categories">
                                        <a [routerLink]="category.path" [queryParams]="category.queryParams">{{ category.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer black-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} {{ "powered-by-fahm" | translate }}</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                         
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->