import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CachingInterceptor } from './caching-interceptor';
import { IvInterceptor } from './iv-interceptor';
import { PromisingInterceptor } from './promising-interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
  { provide: HTTP_INTERCEPTORS, useClass: IvInterceptor, multi: true},
  { provide: HTTP_INTERCEPTORS, useClass: PromisingInterceptor, multi: true},
];
