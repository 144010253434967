import { Product } from './product';
import { Address } from './address';

export class Shipment {
  public ShipmentKey: string;
  public DeliveryMethod: string;
  public Status: string;
  public Notes: Note[] =[];
  public ToAddress: Address;

  constructor(obj: any) {
    if(obj) {
      Object.assign(this, obj);
      
      if(obj.ToAddress){
        this.ToAddress = new Address(obj.ToAddress);
      }
      if(obj.Status.Description) {
        this.Status = obj.Status.Description;
      }
    }
  }
}

export class Note {
  public NoteText: string;
  public SequenceNo: number;
  public ContactTime: Date;
  public ContactType: string;
  public ReasonCode: string;

  constructor(obj: any) {
    Object.assign(this, obj);
    if(obj.SequenceNo) {
      this.SequenceNo = parseInt(obj.SequenceNo);
    }
  }
}

export class Order {
  public OrderHeaderKey: string;
  public OrderNo: string;
  public DocumentType: string;
  public EnterpriseCode: string;
  public EntryType: string;
  public DraftOrderFlag: boolean;
  public SellerOrganizationCode: string;
  public CarrierServiceCode: string;
  public BillToID: string;
  private hasAvailability: boolean = false;
  public PersonInfoBillTo: Address;
  public PersonInfoShipTo: Address;
  public OverallTotals: OrderPriceInfo;
  public Promotions: Promotion[] = [];
  public Awards: {[promotionId: string]: Award};
  public PaymentMethods: PaymentMethod[] = [];
  public SVCs: PaymentMethod[] = [];
  public CreditCards: PaymentMethod[] = [];
  public OrderLines: OrderLine[] = [];
  public PromotionAmount: number = 0;
  public Status: string;
  public OrderDate: Date;
  public fetchedLines: boolean;
  public totalCartCount: number = 0;
  public PriceInfo: {
    Currency: string;
    TotalAmount: number;
  }
  public Shipments: Shipment[] = [];

  constructor(obj: any) {
    this.hasAvailability = false;
    if(obj != null) {
      Object.assign(this, obj);
      if(obj.Shipments && obj.Shipments.Shipment) {
        this.Shipments = obj.Shipments.Shipment.map(x => new Shipment(x));
      }
      this.DraftOrderFlag = (obj.DraftOrderFlag === "Y");
      if(this.DraftOrderFlag){
        this.fetchedLines = true;
      }
      if(obj.OrderLines && obj.OrderLines.OrderLine){
        this.OrderLines = obj.OrderLines.OrderLine.map(x => {
          this.totalCartCount += parseInt(x.OrderedQty);
          return new OrderLine(x, obj.PriceInfo.Currency)
        });
        this.fetchedLines = true;
      } else {
        this.OrderLines = [];
      }
      for(let i = this.OrderLines.length - 1; i >= 0; i--){
        let ol = this.OrderLines[i];
        if(ol.BundleParentLine && ol.BundleParentLine.OrderLineKey) {
          for(let line of this.OrderLines){
            if(line.OrderLineKey === ol.BundleParentLine.OrderLineKey){
              line.addComponentLine(ol);
              this.OrderLines.splice(i, 1);
              break;
            }
          }
        }
      }
      if(obj.OverallTotals) {
        this.OverallTotals = new OrderPriceInfo(obj.OverallTotals);
      }
      if(obj.PersonInfoBillTo) {
        this.PersonInfoBillTo = new Address(obj.PersonInfoBillTo);
      }
      if(obj.PersonInfoShipTo) {
        this.PersonInfoShipTo = new Address(obj.PersonInfoShipTo);
      }
      if(obj.Promotions && obj.Promotions.Promotion){
        this.Promotions = obj.Promotions.Promotion.map(x => new Promotion(x));
      }
      if(obj.Awards && obj.Awards.Award) {
        for(let a of obj.Awards.Award) {
          let award = new Award(a);
          this.Awards[a.PromotionId] = award;
          if(award.AwardApplied){
            this.PromotionAmount += award.AwardAmount;
          }
        }
      }
      if(obj.PaymentMethods && obj.PaymentMethods.PaymentMethod){
        this.PaymentMethods = [];
        for(let p of obj.PaymentMethods.PaymentMethod) {
          let pm = new PaymentMethod(p);
          this.PaymentMethods.push(pm);
          if(pm.PaymentType === 'SVC'){
            this.SVCs.push(pm);
          } else if(pm.PaymentType === 'CREDIT_CARD') {
            this.CreditCards.push(pm);
          }
        }
      }
    }
  }

  public getQuantityForProduct(product: Product): number {
    return this.OrderLines.reduce((previousValue, currentValue) => {
      if(currentValue.ItemDetails.ItemID === product.ItemID){
        return previousValue + currentValue.OrderedQty;
      } else {
        return previousValue;
      }
    }, 0);
  }

  public getOrderLineForKey(orderLineKey: string): OrderLine {
    return this.OrderLines.find((orderLine) => {
      return (orderLine.OrderLineKey === orderLineKey);
    })
  }
  public hasPayment(): boolean {
    let chargeAmount = this.OverallTotals.GrandTotal;
    let limit = 0;
    if(chargeAmount > 0){
      if(this.PaymentMethods.length > 0){
        for(let p of this.PaymentMethods){
          if(p.UnlimitedCharges){
            return true;
          } else {
            limit += p.MaxChargeLimit;
          }
        }
        return limit >= chargeAmount;
      }
      return false;
    }
    return true;


  }

  public hasConfirmedAddress(): boolean {
    return (this.PersonInfoBillTo && this.PersonInfoBillTo.validate());
  }



  public getUsableDeliveryMethods(): any[] {
  	let pickup: boolean = true;
  	let delivery: boolean = true;
  	let shipping: boolean = true;

  	for(let ol of this.OrderLines) {
  		if(ol.ItemDetails && ol.ItemDetails.PrimaryInformation) {
  			if(!ol.ItemDetails.PrimaryInformation.IsPickupAllowed) {
  				pickup = false;
  			}
  			if(!ol.ItemDetails.PrimaryInformation.IsDeliveryAllowed) {
  				delivery = false;
  			}
  			if(!ol.ItemDetails.PrimaryInformation.IsShippingAllowed) {
  				shipping = false;
  			}
  		}
  	}

  	let deliveryMethods: any[] = [];
  	if(shipping) {
  		deliveryMethods.push({"name": "Shipping", "value": "SHP"});
  	}
  	if(pickup){
  		deliveryMethods.push({"name": "Pickup", "value": "PICK"});
  	}
  	return deliveryMethods;
  }
}

export class LinePriceInfo {
  public UnitPrice: number;
  public LineTotal: number;
  public ExtendedPrice: number;
  public BundleTotal: number;
  public DisplayLineTotal: number;
  public DisplayUnitPrice: number;
  public DisplayExtendedPrice: number;
  public DisplayBundleTotal: number;
  constructor(obj: any) {
    if(obj != null) {
      if(obj.UnitPrice) {
        this.UnitPrice = parseFloat(obj.UnitPrice);
      } else {
        this.UnitPrice = 0;
      }
      this.DisplayUnitPrice = this.UnitPrice;
      if(obj.LineTotal) {
        this.LineTotal = parseFloat(obj.LineTotal);
      } else {
        this.LineTotal = 0;
      }
      this.DisplayLineTotal = this.LineTotal;
      if(obj.ExtendedPrice) {
        this.ExtendedPrice = parseFloat(obj.ExtendedPrice);
      } else {
        this.ExtendedPrice = 0;
      }
      this.DisplayExtendedPrice = this.ExtendedPrice;
      if(obj.BundleTotal) {
        this.BundleTotal = parseFloat(obj.BundleTotal);
      } else {
        this.BundleTotal = 0;
      }
      this.DisplayBundleTotal = this.BundleTotal;
    }
  }

  addBundlePrice(lpi: LinePriceInfo) {
    this.DisplayLineTotal += lpi.DisplayLineTotal;
    this.DisplayUnitPrice += lpi.DisplayUnitPrice;
    this.DisplayExtendedPrice += lpi.DisplayExtendedPrice;
    this.DisplayBundleTotal += lpi.DisplayBundleTotal;
  }
}

export class Promotion {
  public PromotionId: string;
  public PromotionKey: string;
  public PromotionType: string;
  public PromotionApplied: boolean;
  public Description: string;
  public DenialReason: string;
  public Awards: Award[];

  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.PromotionApplied = (obj.PromotionApplied === 'Y');
      if(obj.PricingRule && obj.PricingRule.Description) {
        this.Description = obj.PricingRule.Description;
      }
    }
  }


}

export class Award {
  public AwardAmount: number;
  public AwardApplied: boolean;
  public PromotionId: string;
  public PromotionKey: string;

  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.AwardAmount = parseFloat(obj.AwardAmount);
      this.AwardApplied = (obj.AwardApplied === 'Y');
    }
  }

}

export class OrderPriceInfo {
  public GrandTotal: number;
  public GrandDiscount: number;
  public GrandCharges: number;
  public GrandTax: number;
  public GrandShippingTotal: number;
  public LineSubTotal: number;
  public GrandAdjustment: number = 0;

  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.GrandDiscount = parseFloat(obj.GrandDiscount);
      this.GrandCharges = parseFloat(obj.GrandCharges);
      this.GrandTax = parseFloat(obj.GrandTax);
      this.GrandShippingTotal = parseFloat(obj.GrandShippingTotal);
      this.LineSubTotal = parseFloat(obj.LineSubTotal);
      if(this.GrandCharges !== 0 || this.GrandDiscount !== 0)
      this.GrandAdjustment = this.GrandCharges - this.GrandDiscount;
    }
  }
}

export class WorkOrder {
  public WorkOrderKey: string;
  public WorkOrderNo: string;
  public Status: string;
  public StatusDescription:  string;
  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
    }
  }
}
export class OrderLine {
  public OrderedQty: number;
  public OrderLineKey: string;
  public PrimeLineNo: string;
  public SubLineNo: string;
  public DeliveryMethod: string;
  public Status: string;
  public CarrierServiceCode: string;
  public SCAC: string;
  public ItemGroupCode: string;
  public IsBundleParent: boolean;
  public IsConfigured: boolean;
  public ConfigurationKey: string;
  public KitCode: string;
  public ShipNode: string;
  public PersonInfoShipTo: Address;
  public ComponentLines: OrderLine[] = [];
  public Item: any;
  public ItemDetails: Product;
  public ReservationID: string;
  public LineOverallTotals: LinePriceInfo;
  public WorkOrders: WorkOrder[] = [];
  public AvailableOptions: {[type: string]: LineOption};
  public CustomAttributes: any = {
    "RentalDays": 1
  };
  public BundleParentLine: {
    OrderLineKey: string;
  };

  constructor(obj: any, currency: string) {
    if(obj != null) {
      Object.assign(this, obj);
      this.IsBundleParent = (obj.IsBundleParent === "Y");
      this.IsConfigured = (obj.IsBundleParent === "Y");
      this.OrderedQty = parseFloat(obj.OrderedQty);
      this.AvailableOptions = {};
      if(obj.ItemDetails){
        this.ItemDetails = new Product(obj.ItemDetails, currency);
      } else if(obj.Item) {
        this.ItemDetails = new Product(obj.Item, currency);
      }
      if(obj.LinePriceInfo) {
        this.LineOverallTotals = new LinePriceInfo(obj.LinePriceInfo);
      }
      if(obj.PersonInfoShipTo) {
        this.PersonInfoShipTo = new Address(obj.PersonInfoShipTo);
      }
      this.WorkOrders = [];
      if(obj.WorkOrders && obj.WorkOrders.WorkOrder) {
        for(let i = 0; i < obj.WorkOrders.WorkOrder.length; i++){
          let wo = obj.WorkOrders.WorkOrder[i];
          if(wo.Status === "1100"){
            this.WorkOrders.push(new WorkOrder(wo));
          }
        }
      }
    }
  }

  addComponentLine(orderLine: OrderLine) {
    this.ComponentLines.push(orderLine);
    this.LineOverallTotals.addBundlePrice(orderLine.LineOverallTotals);
  }

  loadAvailableOptions(options: any[]): void {
    for(let option of options) {
      if (option.OptionNo === "0"){
        let lineOption: LineOption = {
          DeliveryMethod: "SHP",
          Available: (option.IsUnavailable === "N"),
          AvailabilityDate: option.DeliveryDate,
          HasUnavailable: (option.HasAnyUnavailableQty === "Y")
        }
        this.AvailableOptions["SHP"] = lineOption;
      } else if(option.OptionNo === "1"){
        let lineOption: LineOption = {
          DeliveryMethod: "DEL",
          Available: (option.IsUnavailable === "N"),
          AvailabilityDate: option.DeliveryDate,
          HasUnavailable: (option.HasAnyUnavailableQty === "Y")
        }
        this.AvailableOptions["DEL"] = lineOption;
      } else if(option.OptionNo === "2"){
        let lineOption: LineOption = {
          DeliveryMethod: "PICK",
          Available: (option.IsUnavailable === "N"),
          AvailabilityDate: option.DeliveryDate,
          HasUnavailable: (option.HasAnyUnavailableQty === "Y")
        }
        this.AvailableOptions["PICK"] = lineOption;
      }

    }
  }
}

export class LineOption {
  public DeliveryMethod: string;
  public AvailabilityDate: Date;
  public HasUnavailable: boolean;
  public Available: boolean;

}

export class PaymentMethod {
  ChargeSequence: number;
  CreditCardExpDate: Date;
  CreditCardName: string;
  CreditCardType: string;
  DisplayCreditCardNo: string;
  DisplaySvcNo: string;
  FundsAvailable: number;
  GetFundsAvailableUserExitInvoked: boolean;
  MaxChargeLimit: number;
  PaymentKey: string;
  PaymentType: string;
  UnlimitedCharges: boolean;

  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.GetFundsAvailableUserExitInvoked = (obj.GetFundsAvailableUserExitInvoked === 'Y');
      this.UnlimitedCharges = (obj.UnlimitedCharges === 'Y');
    }
  }

  public static getCreditCardType(number: string): string {
    if(number.startsWith('34') || number.startsWith('37')) {
      return 'AMEX';
    } else if(number.startsWith('4')) {
      return 'VISA';
    } else if(number.startsWith('5')) {
      return 'MASTERCARD';
    } else if(number.startsWith('3')) {
      return 'DINERS';
    }
  }
}
