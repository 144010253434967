import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { InventoryService } from '../services/inventory.service';



@Injectable()
export class PromisingInterceptor implements HttpInterceptor {
  
  private AUTH_HEADER = "Authorization";
  private token = "";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private authService: AuthService;
  private ivService: InventoryService;

  constructor(inj: Injector) {
    this.authService = inj.get(AuthService);
    this.ivService = inj.get(InventoryService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/api.watsoncommerce.ibm.com\/promising/)) {
      return next.handle(request);
    }

    if(this.token === "") {
        this.authService.getPromisingDetails().subscribe(r => {
            if(r && r.access_token){
                this.token = r.access_token;
            }

        })
    }

    if (!request.headers.has('Content-Type')) {
        request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json')
        });
    }

    request = this.addAuthenticationToken(request);
    let promisingCall = {
      url: request.url,
      method: request.method
    };
    if(request.body){
      promisingCall['request'] = JSON.stringify(request.body, undefined, 2);
    }
    return next.handle(request).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if(event.status < 300 && event.body){
              promisingCall['response'] = JSON.stringify(event.body, undefined, 2);
            }  
            this.ivService.ivCallStack.push(promisingCall);
          }
        }),
        catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401 || error.status === 403) {
                // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                if (this.refreshTokenInProgress) {
                  // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                  // which means the new token is ready and we can retry the request again
                  return this.refreshTokenSubject.pipe(
                    filter(result => result !== null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(request)))
                  );
                } else {
                  this.refreshTokenInProgress = true;
      
                  // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                  this.refreshTokenSubject.next(null);
                  
                  return this.refreshAccessToken().pipe(
                    switchMap((success: boolean) => {               
                      this.refreshTokenSubject.next(success);
                      return next.handle(this.addAuthenticationToken(request));
                    }),
                    // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                    // for the next time the token needs to be refreshed
                    finalize(() => this.refreshTokenInProgress = false)
                  );
                }
              } else {
                return throwError(error);
              }
        })
    ) as Observable<HttpEvent<any>>;
  }

  private refreshAccessToken(): Observable<any> {
    return this.authService.getPromisingDetails().pipe(map(r => {
        this.token = r.access_token;
        return r;
    }))

  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.token) {
      return request;
    }
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/api.watsoncommerce.ibm.com\/promising/)) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.token)
    });
  }
}
