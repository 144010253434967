export class Address {
  City: string;
  AddressLine1: string;
  AddressLine2: string;
  PersonInfoKey: string;
  Country: string
  ZipCode: string;
  State: string;
  FirstName: string;
  LastName: string;
  EMailID: string;
  DayPhone: string;
  MobilePhone: string;

  constructor(obj?: any){
    if(obj != null){
      Object.assign(this, obj);
    }
  }

  clone(): Address {
    return Object.assign(new Address(), this);
  }

  validate(existing?: Address): boolean {
    if(this.Country && this.ZipCode && this.City && this.AddressLine1) {
      if(existing && this.equal(existing)) {
        return false;
      }
      return true;
    }
    return false;
  }

  equal(address: Address): boolean {
    if(this.City === address.City) {
      if(this.AddressLine1 === address.AddressLine1) {
        if(this.AddressLine2 === address.AddressLine2) {
          if(this.Country === address.Country) {
            if(this.ZipCode === address.ZipCode) {
              if(this.State === address.State) {
                if(this.FirstName === address.FirstName) {
                  if(this.LastName === address.LastName) {
                    if(this.EMailID === address.EMailID){
                      if(this.DayPhone === address.DayPhone){
                        return true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    return false;
  }
}

export class CustomerAddress {
  IsDefaultShipTo: boolean;
  IsDefaultBillTo: boolean;
  IsShipTo: boolean;
  IsBillTo: boolean;
  Description: string;
  CustomerAdditionalAddressID: string;
  Address: Address;

  constructor(obj?: any) {
    if(obj != null){
      Object.assign(this, obj);
      this.IsDefaultBillTo = (obj.IsDefaultBillTo === 'Y');
      this.CustomerAdditionalAddressID = obj.CustomerAdditionalAddressID;
      this.IsDefaultShipTo = (obj.IsDefaultShipTo === 'Y');
      this.IsBillTo = (obj.IsBillTo === 'Y');
      this.IsShipTo = (obj.IsShipTo === 'Y');
      this.Address = new Address(obj.PersonInfo);
    }
  }
}
