import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/shared/services/cart.service';
import { Order } from 'src/app/shared/classes';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() currency : any;
  @Output() payment: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild("creditCardModal", { static: false }) CreditCardModal: TemplateRef<any>;

  public cart: Order;

  public closeResult: string;
  public modalOpen: boolean = false;

  public formGroup: FormGroup;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private cartService: CartService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      CreditCardNo: ['', [Validators.required, Validators.pattern(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)]],
      CreditCardName: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      CreditCardExpDate: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/)]]
    })
  }

  ngAfterViewInit(): void {
  }

  async openModal(cart) {
    this.cart = cart;
    console.log('credit-card-modal', cart);
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.CreditCardModal, { 
        size: 'sm',
        ariaLabelledBy: 'Credit-Card-Modal',
        centered: true,
        windowClass: 'theme-modal credit-card-modal CreditCardModal'
      }).result.then((result) => {
        return this.formGroup.value;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  handleResponse() {
    if(this.formGroup.valid){
      console.log('Handle Response', this.formGroup.value);
      this.closeResult = this.formGroup.value;
      this.payment.emit(this.formGroup.value);
      this.modalService.dismissAll(this.closeResult);
    }

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
