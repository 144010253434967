import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private authService: AuthService
  ) { }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      
      return this.authService.access$.pipe(map(admin => {
        if(admin.ECommManager) {
          return true;
        }
        return false;
      }));
   


  }
}
