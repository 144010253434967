export class ShipNode {
  public Description: string;
  public DistanceFromShipToAddress: number;
  public DistanceUOM: string;
  public NodeType: string;
  public ShipNode: string;
  public ShipNodePersonInfo: {
    AddressLine1: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
  }

  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
    }
  }
}

export class OrderLineAvailabilityOption {
  public IsAvailable: boolean;
  public AvailableDate: Date;
  public ShipNode: string;

  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.IsAvailable = (obj.IsAvailable === 'Y');
    }
  }
}



export class OrderLineAvailability {
  public OrderLineKey: string;
  public RequiredQty: number;
  public Availability: OrderLineAvailabilityOption[];
  public AvailableOnly: OrderLineAvailabilityOption[];
  constructor(obj: any) {
    if(obj != null) {
      Object.assign(this, obj);
      this.Availability = obj.AvailabilityList.Availability.map(x => new OrderLineAvailabilityOption(x));
      this.AvailableOnly = this.Availability.filter(x => x.IsAvailable);
    }
  }
}

export class AlternateStore {
  public ShipNodes: {[NodeKey: string]: ShipNode} = {};
  public OrderLines: OrderLineAvailability[];
  public OrderLine: OrderLineAvailability;
  constructor(obj: any) {
    if(obj != null) {
      for(let node of obj.NodeList.Node) {
        this.ShipNodes[node.ShipNode] = new ShipNode(node);
      }
      if(obj.OrderLines.OrderLine.length > 0){
        this.OrderLines = obj.OrderLines.OrderLine.map(x => new OrderLineAvailability(x));
        this.OrderLine = this.OrderLines[0];
      } else {
        this.OrderLine = new OrderLineAvailability(obj.OrderLines.OrderLine[0]);
      }

    }
  }
}
