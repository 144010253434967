<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<div class="ivCalls" [class.active]="showIVData">
    <div class="ivCall" *ngFor="let call of ivService.ivCallStack; let i = index">
        <div class="url" (click)="setActive(i)" ><span style="text-transform: uppercase">{{ call.method}}</span> - {{ call.url }}</div>
        <div class="content" [class.active]="i === activeIndex" >
            <h5 *ngIf="call.request" >request</h5>
            <pre *ngIf="call.request" class="request">{{ call.request }}</pre>
            <h5 *ngIf="call.response" >response</h5>
            <pre *ngIf="call.response" class="response">{{ call.response }}</pre>
        </div>
    </div>
</div>