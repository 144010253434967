export class InventoryResponse {
  lines: ItemAvailability[];

  constructor(obj: any){
    if(obj != null && obj.lines){
      if(!(obj.lines instanceof Array)){
        obj.lines = [obj.lines];
      }
      this.lines = obj.lines.map(x => new ItemAvailability(x));
    } else if(obj != null) {
      this.lines = obj.map(item => new ItemAvailability(item, false));
    } else {
      this.lines = [];
    }
  }
}

export class ItemNodeAvailability {
  lineId: string;
  availability: ShipNodeAvailability[];

  constructor (obj: any, iv: boolean = true){
    if(obj != null){
      if(iv) {
        this.lineId = obj.lineId;
        if(obj.shipNodeAvailability){
          if(obj.shipNodeAvailability instanceof Array){
            this.availability = obj.shipNodeAvailability.map(x => new ShipNodeAvailability(x));
          } else {
            this.availability = [new ShipNodeAvailability(obj.networkAvailabilities)];
          }        
        }

      } else {
        this.lineId = obj.ItemID;
        this.availability = [new ShipNodeAvailability(obj.Availability)]
      }
     
    }
  }

  isAvailable(): boolean {
    if(this.availability) {
      for(let a of this.availability){
        if(a.totalAvailableQuantity > 0){
          return true;
        } 
      }
    }
    return false;
  }
  getAvailableQuantity(): number {
     if(this.availability){
      for(let a of this.availability){
        if(a.onhandAvailableQuantity > 0){
          return a.onhandAvailableQuantity;
        }
      }
    }
    return 0;
  }

  getTotalAvailableQuantity(): number {
    if(this.availability){
      for(let a of this.availability){
        if(a.totalAvailableQuantity > 0){
          return a.totalAvailableQuantity;
        }
      }
    }
    return 0;
  }

  getFutureQuantity(): number {
    if(this.availability){
      for(let a of this.availability){
        if(a.futureAvailableQuantity > 0){
          return a.futureAvailableQuantity;
        }
      }
    }
    return 0;
  }
  isCurrentlyAvailable(): boolean {
    if(this.availability) {
      for(let a of this.availability){
        if(a.onhandAvailableQuantity > 0){
          return true;
        }
      }
    }
    return false;
  }


  getEarliestAvailability(): ShipNodeAvailability {
    if(this.availability){
      let d: ShipNodeAvailability;
      for(let a of this.availability){
        if(!d || d.earliestShipTs.getTime() > a.earliestShipTs.getTime()){
          d = a;
        }
      }
      return d;
    }
    return undefined;
  }

  getEAD(): Date {
    if(this.availability){
      let d: ShipNodeAvailability;
      for(let a of this.availability){
        if(!d || d.earliestShipTs.getTime() > a.earliestShipTs.getTime()){
          d = a;
        }
      }
      if(d){
        return d.earliestShipTs;
      }
    }
    return undefined;
  }

  getEarliestRecord(): ShipNodeAvailability {
    if(this.availability){
      let d: ShipNodeAvailability;
      for(let a of this.availability){
        if(!d || d.earliestShipTs.getTime() > a.earliestShipTs.getTime()){
          d = a;
        }
      }
      if(d){
        return d;
      }
    }
    return undefined;
  }
}

export class ItemAvailability {
  lineId: string;
  availability: NetworkAvailability[];

  constructor (obj: any, iv: boolean = true){
    if(obj != null){
      if(iv) {
        this.lineId = obj.lineId;
        if(obj.networkAvailabilities){
          if(obj.networkAvailabilities instanceof Array){
            this.availability = obj.networkAvailabilities.map(x => new NetworkAvailability(x));
          } else {
            this.availability = [new NetworkAvailability(obj.networkAvailabilities)];
          }        
        }

      } else {
        this.lineId = obj.ItemID;
        this.availability = [new NetworkAvailability(obj.Availability)]
      }
     
    }
  }

  isAvailable(): boolean {
    if(this.availability) {
      for(let a of this.availability){
        if(a.totalAvailableQuantity > 0){
          return true;
        } 
      }
    }
    return false;
  }
  getAvailableQuantity(): number {
     if(this.availability){
      for(let a of this.availability){
        if(a.onhandAvailableQuantity > 0){
          return a.onhandAvailableQuantity;
        }
      }
    }
    return 0;
  }
  getTotalAvailableQuantity(): number {
    if(this.availability){
      for(let a of this.availability){
        if(a.totalAvailableQuantity > 0){
          return a.totalAvailableQuantity;
        }
      }
    }
    return 0;
  }
  getFutureQuantity(): number {
    if(this.availability){
      for(let a of this.availability){
        if(a.futureAvailableQuantity > 0){
          return a.futureAvailableQuantity;
        }
      }
    }
    return 0;
  }
  isCurrentlyAvailable(): boolean {
    if(this.availability) {
      for(let a of this.availability){
        if(a.onhandAvailableQuantity > 0){
          return true;
        }
      }
    }
    return false;
  }


  getEarliestAvailability(): NetworkAvailability {
    if(this.availability){
      let d: NetworkAvailability;
      for(let a of this.availability){
        if(!d || d.earliestShipTs.getTime() > a.earliestShipTs.getTime()){
          d = a;
        }
      }
      return d;
    }
    return undefined;
  }

  getEAD(): Date {
    if(this.availability){
      let d: NetworkAvailability;
      for(let a of this.availability){
        if(!d || d.earliestShipTs.getTime() > a.earliestShipTs.getTime()){
          d = a;
        }
      }
      if(d){
        return d.earliestShipTs;
      }
     
    }
    return undefined;
  }

  getEDD(future: number = 2): Date {
    if(this.availability){
      let d: NetworkAvailability;
      for(let a of this.availability){
        if(!d || d.earliestShipTs.getTime() > a.earliestShipTs.getTime()){
          d = a;
        }
      }
      if(d){
        let date: number = d.earliestShipTs.getTime();
        date += (1000 * 60 * 60 * 24 * future);
        return new Date(date);
      }
     
    }
    return undefined;
  }
}

export class ShipNodeAvailability {
  public alertLevel: string;
  public alertQuantity: number;
  public distributionGroupId: string;
  public distributionOrganizationCode: string;
  public thresholdLevel: string;
  public thresholdType: string;
  public earliestShipTs: Date;
  public earliestDisplay: string;
  public orderByTs: Date;
  public totalAvailableQuantity: number;
  public onhandAvailableQuantity: number;
  public onhandEarliestShipTs: Date;
  public futureAvailableQuantity: number;
  public futureEarliestShipTs: Date;
  public shipNode: string;

  constructor (obj: any) {
    if(obj != null){
      Object.assign(this, obj);
      if(obj.CurrentAvailableQty) {
        this.onhandAvailableQuantity = parseInt(obj.CurrentAvailableQty);
      }
      if(obj.earliestShipTs) {
        this.earliestShipTs = new Date(Date.parse(obj.earliestShipTs));
      }
      if(obj.onhandEarliestShipTs) {
        this.onhandEarliestShipTs = new Date(Date.parse(obj.onhandEarliestShipTs));
      }
      if(obj.futureEarliestShipTs) {
        this.futureEarliestShipTs = new Date(Date.parse(obj.futureEarliestShipTs));
      }
      if(obj.orderByTs) {
        this.orderByTs = new Date(Date.parse(obj.orderByTs));
      }
    }
  }
}

export class NetworkAvailability {
  public alertLevel: string;
  public alertQuantity: number;
  public distributionGroupId: string;
  public distributionOrganizationCode: string;
  public thresholdLevel: string;
  public thresholdType: string;
  public earliestShipTs: Date;
  public earliestDeliveryTs: Date;
  public totalAvailableQuantity: number;
  public onhandAvailableQuantity: number;
  public onhandEarliestShipTs: Date;
  public futureAvailableQuantity: number;
  public futureEarliestShipTs: Date;

  constructor (obj: any) {
    if(obj != null){
      Object.assign(this, obj);
      if(obj.CurrentAvailableQty) {
        this.onhandAvailableQuantity = parseInt(obj.CurrentAvailableQty);
      }
      if(obj.earliestShipTs) {
        this.earliestShipTs = new Date(Date.parse(obj.earliestShipTs));
      }
      if(obj.onhandEarliestShipTs) {
        this.onhandEarliestShipTs = new Date(Date.parse(obj.onhandEarliestShipTs));
      }
      if(obj.futureEarliestShipTs) {
        this.futureEarliestShipTs = new Date(Date.parse(obj.futureEarliestShipTs));
      }
      if(obj.earliestDeliveryTs){
        this.earliestDeliveryTs = new Date(Date.parse(obj.earliestDeliveryTs));
      }
    }
  }
}
