import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OmsRestService } from './oms.service';
import { Observable, BehaviorSubject, of} from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Order, PaymentMethod, Customer, Pagination } from '../classes';
import { Product } from '../classes/product';
import { AppSettings } from './app.settings';
import { AuthService } from './auth.service';
import { User } from '../classes/user';
import { InventoryService } from './inventory.service';

@Injectable({
  providedIn: 'root'
})
export class CartService extends OmsRestService {

  public cart$: Observable<Order>;
  private cartSubject: BehaviorSubject<Order>;
  private user: User;
  private fetchingCart: boolean = false;
  constructor(
    httpClient: HttpClient,
    appSettings: AppSettings,
    private authService: AuthService
  ) {
    super(httpClient, appSettings);
    this.cartSubject = new BehaviorSubject<Order>(undefined);
    this.cart$ = this.cartSubject.asObservable();
    this.authService.user$.subscribe(user => {
      if(user){
		  console.log("getCartOrderDetails:user::: ", user);
        this.user = user;
        this.getCartOrderDetails(user).subscribe(r => {
          this.cartSubject.next(r);	  
		  console.log("getCartOrderDetails: R:::: ", r);
        })              
      }
    });
  }

  protected fetchCart(orderHeaderKey: string): void {
    this.callApi("getCompleteOrderDetails", {
       "Order": {
         "OrderHeaderKey": orderHeaderKey
       }
     }, "getOrderDetails").subscribe((response) => {
       let o = new Order(response);
       if(o.DraftOrderFlag) {
        this.cartSubject.next(o);
        this.fetchingCart = false;
       }
     }, (error) => {
      console.log(error);
     });
  }

  deleteCart(): void {
    this.callApi("deleteOrder", {
      "Order": {
        "Action": "DELETE",
        "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey
      }
    }).subscribe((r) => {
      this.createCart().subscribe();
    }, (error) => {
      this.createCart().subscribe();
    })
  }
  createCart(): Observable<Order> {
    console.log("Customer", this.authService.getCurrentCustomer());
    return this.callApi("createOrder", {
      "Order": {
        "EnterpriseCode": this.appSettings.getCurrentSettings().baseOrg,
          "SellerOrganizationCode": this.appSettings.getCurrentSettings().baseOrg,
          "EntryType": "WEB",
          "DocumentType": "0001",
          "CarrierServiceCode": "STANDARD_AURE",
          "DraftOrderFlag": "Y",
          "BillToID": this.authService.getCurrentCustomer().CustomerID
        }
      }, "createOrder").pipe(map(response => {
        this.fetchingCart = false;
        this.authService.updateUserCart(this.user, response.OrderHeaderKey);
        return new Order(response);
      }));
  }

  public getOrderHistoryDetails(orderHeaderKey: string): Observable<Order> {
    return this.callApi("getCompleteOrderDetails", {
      "Order": {
        "OrderHeaderKey": orderHeaderKey
      }
    }, "getOrderHistoryDetails", false, undefined, true).pipe(map(x => new Order(x)));
  }
  
  public getCartOrderDetails(user: User): Observable<Order> {
console.log("getCompleteOrderDetails:user " , user);
    if(user && user.currentCart){
		  console.log("getCompleteOrderDetails:user.currentCart:IF " , user.currentCart);
      return this.callApi("getCompleteOrderDetails", {"Order": {"OrderHeaderKey": user.currentCart}}, "getOrderDetails", false)
      .pipe(
        catchError(error => {
          return this.createCart().pipe(map(x => {
            this.authService.updateUserCart(user, x.OrderHeaderKey);
			 console.log("getCompleteOrderDetails:user.currentCart:X " , x);
            return x;
          }))
        }),
        switchMap((order) => {
		console.log("getCompleteOrderDetails:switchMap ");
          if(order){
			  console.log("getCompleteOrderDetails:order IF ");
            let o = new Order(order);
			console.log("getCompleteOrderDetails:order:o ", o);
            if(!o.DraftOrderFlag){
				console.log("getCompleteOrderDetails:DraftOrderFlag ");
              return this.createCart().pipe(map(x => {
                this.authService.updateUserCart(user, x.OrderHeaderKey);
				console.log("getCompleteOrderDetails:X ", x);
                return x;
              }))
            } else {
				  console.log("getCompleteOrderDetails:order ELSE " , o);
              return of(o);
            }
            return of(undefined);
          }
      }));
    } else if(user){
		console.log("getCompleteOrderDetails:user.currentCart:Else " , user);
      return this.createCart().pipe(map(x => {
        this.authService.updateUserCart(user, x.OrderHeaderKey);
		console.log("getCompleteOrderDetails:user.currentCart:Else: X " , x);
        return x;
      }))
    }
  }

  confirmCart(): Observable<any> {
    if(!this.cartSubject.getValue().BillToID) {
      return this.authService.customer$
        .pipe(switchMap(customer => {
          if(customer) {
            return this.callApi("changeOrder", {
              "Order": {
                "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
                "BillToID": customer.CustomerID,
                "CustomerContactID": customer.ContactList[0].CustomerContactID
              }
            })
              .pipe(switchMap(response => {
                return this.callApi("confirmDraftOrder", {
                  "Order": {
                    "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey
                  }
                });
              }));
          } else {
            return this.callApi("confirmDraftOrder", {
              "Order": {
                "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey
              }
            });
          }
        }));
    } else {
      return this.callApi("confirmDraftOrder", {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey
        }
      });
    }
  }

  public getOrderDetails(orderHeaderKey: string): Observable<Order> {
    return this.callApi("getCompleteOrderDetails", {
       "Order": {
         "OrderHeaderKey": orderHeaderKey
       }
     }, "getOrderDetails", false, undefined, true).pipe(map((response) => {
       let o = new Order(response);
       return o;
     }));
  }
  public generateWorkOrder(): Observable<any> {
    if(this.cartSubject.getValue()){
      return this.callApi("generateWorkOrder", {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey
        }
      }).pipe(switchMap(r => {
        return this.getOrderDetails(this.cartSubject.getValue().OrderHeaderKey)
          .pipe(map(r => {
            this.cartSubject.next(r);
            return r;
          }));
      }))
    }
  }
  public getWorkOrderAppointmentOptions(workOrderKey: string): Observable<any> {
    return this.internalEasyService("getWorkOrderAppointmentOptions", {
      "Promise": {
        "WorkOrderKey": workOrderKey,
        "ReturnMultipleSrvcSlots": "Y"
      }
    }).pipe(map(r => {
      r["Dates"] = [];
      r["Slots"] = {};
      for(let i = 0; i <r.Appointment.length; i++){
        let a = r.Appointment[i];
        if(r.Dates.indexOf(a.Date) == -1){
          r.Dates.push(a.Date);
          r.Slots[a.Date] = [];
        }
        r.Slots[a.Date].push(a);
      }
      return r;
    }));
  }
  addProductToCart(product: Product, quantity: number = 1, unitOfMeasure: string, deliveryMethod: string = "SHP", shipNode?: string, inventoryService?: InventoryService, lineType?: string ): void {
	  
	   console.log("CartSubject", this.cartSubject.getValue());
	   console.log("OrderHeaderKey::: ", this.cartSubject.getValue().OrderHeaderKey);
	    console.log("OrganizationCode::: ", this.cartSubject.getValue().EnterpriseCode);
			    console.log("OrderedQty::: ", quantity);
				console.log("OrderedQty::: ", shipNode);
    if(this.cartSubject.getValue()){
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
	 "OrganizationCode": this.cartSubject.getValue().EnterpriseCode,
          "OrderLines": {
            "OrderLine": {
              "OrderedQty": quantity,
              "DeliveryMethod": deliveryMethod,
              "LineType": lineType ? lineType : '',
              "Item": {
                "ItemID": product.getSelectedItem().ItemID,
                "UnitOfMeasure": unitOfMeasure
              }
            }
          }
        }
      };
      if(shipNode){
        input.Order.OrderLines.OrderLine["ShipNode"] = shipNode;
      }
      if(inventoryService) {
        let distributionGroup = undefined;
        if(deliveryMethod === "SHP"){
          distributionGroup = this.appSettings.getCurrentSettings().distributionGroup;
          input.Order.OrderLines.OrderLine["SCAC"] = "pvft";
        }
        inventoryService.createReservation({
          product, 
          quantity, 
          deliveryMethod, 
          shipNode,
          distributionGroup,
          reference: this.cartSubject.getValue().OrderHeaderKey
        }).subscribe(reservation => {
          if(reservation && reservation.lines && reservation.lines[0]) {
            let line = reservation.lines[0];
            if(line.reservationId && deliveryMethod === "SHP"){
              input.Order.OrderLines.OrderLine["ReservationID"] = line.reservationId;
            } else if(line.reservationId && deliveryMethod === "PICK"){
            /*  input.Order.OrderLines.OrderLine["OrderLineReservations"] = {
                "OrderLineReservation": {
                  "ReservationID": line.reservationId,
                  "Quantity": quantity,
                  "ItemID": product.ItemID,
                  "UnitOfMeasure": product.UnitOfMeasure,
                  "Node": shipNode
                }
              };*/
            }
          }
     
          console.log("changeOrder input" , input);
          this.callApi("changeOrder", input, "getOrderDetails", false, undefined, true).subscribe(response => {
            this.cartSubject.next(new Order(response));
          })
        })
      } else {
        this.callApi("changeOrder", input, "getOrderDetails", false, undefined, true).subscribe(response => {
          this.cartSubject.next(new Order(response));
        })
      }

    }
  }

  getConfigurationDetails(orderLineKey: string): Observable<any> {
    return this.callApi("getOrderLineDetails", {
      "OrderLine":{
        "OrderLineKey": orderLineKey
      }}, "getConfigurationDetails");
  }
  removeLine(orderLineKey: string): void {
    if(this.cartSubject.getValue()){
      this.callApi("changeOrder", {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
          "OrderLines": {
            "OrderLine": {
              "OrderLineKey": orderLineKey,
              "Action": "REMOVE"
            }
          }
        }
      }, "getOrderDetails", false, undefined, true).subscribe(response => {
        this.cartSubject.next(new Order(response));
      })
    }
  }

  updateOrderLine(orderLineKey: string, rentalDays:number): void {
    let input = {
      "Order": {
        "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
        "OrderLines": {
          "OrderLine": {
            "OrderLineKey": orderLineKey,
            "CustomAttributes": {
              "RentalDays": rentalDays
            }
          }
        }
      }
    };

    this.callApi("changeOrder", input, "getOrderDetails", false, undefined, true).subscribe(response => {
      this.cartSubject.next(new Order(response));
    });
  }

  changeQuantity(orderLineKey: string, quantity: number, inventoryService?: InventoryService): void {
    let input = {
      "Order": {
        "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
        "OrderLines": {
          "OrderLine": {
            "OrderLineKey": orderLineKey,
            "OrderedQty": quantity
          }
        }
      }
    };
    if(this.cartSubject.getValue()){
      if(inventoryService) {
        let OrderLine = this.cartSubject.getValue().getOrderLineForKey(orderLineKey);
        let reservationInput = {
          product: OrderLine.ItemDetails, 
          quantity,
          deliveryMethod: OrderLine.DeliveryMethod,
          reference: this.cartSubject.getValue().OrderHeaderKey,
          shipNode: undefined,
          distributionGroup: undefined
        };
        if(OrderLine.DeliveryMethod === "SHP") {
          reservationInput["distributionGroup"] = this.appSettings.getCurrentSettings().distributionGroup;
        } else {
          reservationInput["shipNode"] = OrderLine.ShipNode;
        }

        if(OrderLine && OrderLine.ReservationID){
          if(quantity > 0){
            inventoryService.deleteReservation(OrderLine.ReservationID).subscribe(del => {
              inventoryService.createReservation(reservationInput).subscribe(newRes => {
                if(newRes && newRes.lines && newRes.lines[0]) {
                  let line = newRes.lines[0];
                  if(line.reservationId){
                    input.Order.OrderLines.OrderLine["ReservationID"] = line.reservationId;
                  }
                }
                this.callApi("changeOrder", input , "getOrderDetails", false, undefined, true).subscribe(response => {
                  this.cartSubject.next(new Order(response));
                })
              });
            }, 
            error => {
              inventoryService.createReservation(reservationInput).subscribe(newRes => {
                if(newRes && newRes.lines && newRes.lines[0]) {
                  let line = newRes.lines[0];
                  if(line.reservationId){
                    input.Order.OrderLines.OrderLine["ReservationID"] = line.reservationId;
                  }
                }
                this.callApi("changeOrder", input , "getOrderDetails", false, undefined, true).subscribe(response => {
                  this.cartSubject.next(new Order(response));
                })
              });
            });
          }          
        } else {
          this.callApi("changeOrder", input, "getOrderDetails", false, undefined, true).subscribe(response => {
            this.cartSubject.next(new Order(response));
          })
        }
      } else {
        this.callApi("changeOrder", input, "getOrderDetails", false, undefined, true).subscribe(response => {
          this.cartSubject.next(new Order(response));
        })
      }
     
    }
  }
  updateCarrierServiceCode(carrierServiceCode: string, orderLineKeys?: string[]) {
    if(this.cartSubject.getValue()){
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey
        }
      }
      if(orderLineKeys) {
        input.Order["OrderLines"] = {
          "OrderLine": orderLineKeys.map((ol) => {
            return {
              "OrderLineKey": ol,
              "CarrierServiceCode": carrierServiceCode
            };
          })
        };
      } else {
        input.Order["CarrierServiceCode"] = carrierServiceCode;
      }
      this.callApi("changeOrder", input, "getOrderDetails", false, undefined, true).subscribe(response => {
        this.cartSubject.next(new Order(response));
      })
    }
  }
  changeDeliveryMethod(orderLineKey: string, deliveryMethod: string, shipNode: string = ' '): void {
    let scac = '';
    if(deliveryMethod === 'SHP') {
      scac = 'pvft';
    }
    if(this.cartSubject.getValue()){
      this.callApi("changeOrder", {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
          "OrderLines": {
            "OrderLine": {
              "OrderLineKey": orderLineKey,
              "DeliveryMethod": deliveryMethod,
              "ShipNode": shipNode,
              "SCAC": scac
            }
          }
        }
      }, "getOrderDetails", false, undefined, true).subscribe(response => {
        this.cartSubject.next(new Order(response));
      })
    }
  }

  setAddresses(billToAddress?: any, shipToAddress?: any, orderLineKey?: string): void {
    if(this.cartSubject.getValue()){
      let save = false;
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
        }
      };
      if(billToAddress) {
        input.Order["PersonInfoBillTo"] = billToAddress;
        save = true;
      }
      if(orderLineKey && shipToAddress) {
        input.Order["OrderLines"] =  {
          "OrderLine": {
            "OrderLineKey": orderLineKey,
            "PersonInfoShipTo": shipToAddress
          }
        };
        save = true;
      } else if(shipToAddress) {
          input.Order["PersonInfoShipTo"] = shipToAddress;
          save = true;
      } else if (billToAddress) {
        input.Order["PersonInfoBillTo"] = billToAddress;
        save = true;
      }

      if(save) {
        this.callApi("changeOrder", input, "getOrderDetails").subscribe(response => {
          this.cartSubject.next(new Order(response));
        })
      }

    }
  }

  addCoupon(code: string): void {
    if(this.cartSubject.getValue()){
      let save = false;
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
          "Promotions": {
            "Promotion": {
              "PromotionId": code,
              "PromotionGroup": "COUPON"
            }
          }
        }
      };
      this.callApi("changeOrder", input, "getOrderDetails").subscribe(response => {
        this.cartSubject.next(new Order(response));
      });
    }
  }

  removeCoupon(code: string): void {
    if(this.cartSubject.getValue()){
      let save = false;
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
          "Promotions": {
            "Promotion": {
              "PromotionId": code,
              "PromotionGroup": "COUPON",
              "Action":"REMOVE"
            }
          }
        }
      };
      this.callApi("changeOrder", input, "getOrderDetails").subscribe(response => {
        this.cartSubject.next(new Order(response));
      });
    }
  }
  public getDeliveryMethods(): Observable<any>{
    return this.cart$.pipe(switchMap(c => {
      if(c) {
        return this.callApi("getFulfillmentSummaryDetails", {
          "Order": {
            "OrderHeaderKey": c.OrderHeaderKey
          }
        }, "getShipmentBreakdownCarrierOptions").pipe(map(r => {
          
          let shipping = [];
          if(r.ShippingGroups) {
            shipping = r.ShippingGroups.ShippingGroup.map(x => {
              let map = {};
              let orderlines =  x.OrderLines.OrderLine.map(ol => {
                ol["Item"] = new Product(ol.ItemDetails);
                for(let carrier of ol.CarrierServiceList.CarrierService) {
                  if(map[carrier.CarrierServiceCode]) {
                    map[carrier.CarrierServiceCode] += parseFloat(carrier.Price);
                  } else {
                    map[carrier.CarrierServiceCode] = parseFloat(carrier.Price);
                  }
                }
                return ol;
              });
              return {
                "DeliveryMethod": "SHP",
                "DeliveryName": "Shipment",
                "CarrierServiceCode": x.CarrierServiceCode,
                "OrderLines": orderlines,
                "CarrierServices": x.CarrierServiceList.CarrierService.map(y => {
                  y.Price = map[y.CarrierServiceCode];
                  return y;
                })
              }
            });
          }
          let pickup = [];
          if(r.PickupGroups) {
            pickup = r.PickupGroups.PickupGroup.map(x => {
              let orderlines =  x.OrderLines.OrderLine.map(ol => {
                ol["Item"] = new Product(ol.ItemDetails);
                return ol;
              });
              return {
                "DeliveryMethod": "PICK",
                "DeliveryName": "Pickup",
                "ShipNode": x.Shipnode,
                "OrderLines": orderlines
              }
            });
          }
          let unavailable = [];
          if(r.UnavailableLines && r.UnavailableLines.ShippingLines && r.UnavailableLines.ShippingLines.length > 0){
            unavailable.concat(r.UnavailableLines.ShippingLines);
          };
          if(r.UnavailableLines && r.UnavailableLines.PickupLines && r.UnavailableLines.PickupLines.length > 0){
            unavailable.concat(r.UnavailableLines.PickupLines);
          };
          return {
            "Shipments": shipping,
            "Pickups": pickup,
            "Unavailable": unavailable.map(x => {
              x["Item"] = new Product(x.ItemDetails);
              return x;
            })
          };
        }))
      } else {
        return undefined;
      }
      
    }))
    
     
  }
  addGiftCard(cardNo: string): void {
    if(this.cartSubject.getValue()){
      let save = false;
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
          "PaymentMethods": {
            "PaymentMethod": {
              "PaymentType": "SVC",
              "SvcNo": cardNo,
              "UnlimitedCharges":"N",
              "ChargeSequence": "1"
            }
          }
        }
      };
      this.callApi("processOrderPayments", input).subscribe(response => {
        this.fetchCart(this.cartSubject.getValue().OrderHeaderKey);
      });
    }
  }


  addCreditCard(payment: any): Observable<any> {
    if(this.cartSubject.getValue()){
      payment['CreditCardType'] = PaymentMethod.getCreditCardType(payment.CreditCardNo);
      payment['PaymentType'] = 'CREDIT_CARD';
      payment
      let save = false;
      let input = {
        "Order": {
          "OrderHeaderKey": this.cartSubject.getValue().OrderHeaderKey,
          "PaymentMethods": {
            "PaymentMethod": payment
          }
        }
      };
      return this.callApi("processOrderPayments", input).pipe(switchMap(response => {
        return this.confirmCart();
      }));
    }
  }

  getCarrierServiceOptions(order: Order): Observable<any[]> {
    return this.callApi("getCarrierServiceOptionsForOrdering", {
      "Order": {
        "OrderHeaderKey": order.OrderHeaderKey
      }
    }, "getCarrierServiceOptions").pipe(map(
      r => {
        let options = [];

        for(let i = 0; i < r.OrderLines.OrderLine.length; i++) {
          if(i == 0){
            for(let carrier of r.OrderLines.OrderLine[i].CarrierServiceList.CarrierService){
              options.push(carrier);
            }
          } else {
            for(let y = options.length - 1; y > 0; y--){
              let found = false;
              for(let carrier of r.OrderLines.OrderLine[i].CarrierServiceList.CarrierService){
                if(options[y].CarrierServiceCode === carrier.CarrierServiceCode){
                  found = true;
                  break;
                }
              }
              if(!found){
                options.splice(y, 1);
              }
            }
          }
        }
        return options;
      }
    ));
  }

  getOrderHistoryList(customer: Customer, pageNumber: number = 1, pageSize: number = 12): Observable<Pagination> {
    let input = {
      "Page": {
        "PaginationStrategy": "GENERIC",
        "PageSize": pageSize,
        "PageNumber": pageNumber,
        "API": {
          "Name": "getOrderList",
          "Input": {
            "Order": {
              "DocumentType": "0001",
              "EnterpriseCode": this.appSettings.getCurrentSettings().baseOrg,
              "DraftOrderFlag": "N",
              "ComplexQuery": {
                "Or": {
                  "Exp": [
                    {
                      "Name": "BillToID",
                      "Value": customer.CustomerID
                    }, {
                      "Name": "CustomerEMailID",
                      "Value": customer.PrimaryContact.EmailID
                    }
                  ]
                }
              }
            }
          },
          "Template": {
            "OrderList" :{
              "Order":{
                "OrderNo": "",
                "EnterpriseCode":"",
                "DocumentType": "",
                "OrderHeaderKey": "",
                "DraftOrderFlag": "",
                "SellerOrganizationCode": "",
                "OrderName": "",
                "Status": "",
                "OrderDate": "",
                "PriceInfo": {
                  "TotalAmount": "",
                  "Currency": ""
                },
                "PersonInfoBillTo":{
                  "PersonInfoKey":"",
                  "FirstName":"",
                  "LastName":"",
                  "EMailID":"",
                  "DayPhone":"",
                  "MobilePhone": "",
                  "City":"",
                  "State":"",
                  "ZipCode":"",
                  "Country":"",
                  "AddressLine1":"",
                  "AddressLine2":""
                },
                "PersonInfoShipTo":{
                  "PersonInfoKey":"",
                  "FirstName":"",
                  "LastName":"",
                  "EMailID":"",
                  "DayPhone":"",
                  "MobilePhone": "",
                  "City":"",
                  "State":"",
                  "ZipCode":"",
                  "Country":"",
                  "AddressLine1":"",
                  "AddressLine2":""
                },
                "OverallTotals": {
                  "GrandTotal":"",
                  "GrandShippingTotal":"",
                  "GrandTax":"",
                  "LineSubTotal":"",
                  "GrandCharges":"",
                  "GrandDiscount":""
                },
                "OrderLines": {
                  "OrderLine": {
                    "OrderedQty": "",
                    "ItemDetails": {
                      "ItemID":"",
                      "PrimaryInformation": {
                        "ExtendedDisplayDescription": "",
                        "ShortDescription": "",
                        "ImageLocation": "",
                        "ImageID": ""
                      }
                    },
                    "Item": {
                      "ItemID": "",
                      "UnitOfMeasure": ""
                    },
                    "LinePriceInfo": {
                      "UnitPrice": "",
                      "ExtendedPrice": ""
                    }
                  }
                }
              }
            }
          }
        }
      }

    };
    return this.callApi("getPage", input)
      .pipe(map(r => {return new Pagination(r, 'Order')}));
  }
  getWorkOrderAvailability(address: any): Observable<any> {
    let order = this.cartSubject.getValue();
    return this.internalEasyService("getAlternateWorkOrderNodes", {
      "AlternateNodes": {
        "OrganizationCode": order.EnterpriseCode,
        "OrderHeaderKey": order.OrderHeaderKey,
        "ShipToAddress": address
      }
    });
  }
  updateWorkOrderAppointment(workOrderKey: string, slot: any){
    return this.callApi("modifyWorkOrder", {
      "WorkOrder": {
        "WorkOrderKey": workOrderKey,
        "PromisedApptStartDate": slot.ApptStart,
        "PromisedApptEndDate": slot.ApptEnd
      }
    }).pipe(switchMap(r => {
      return this.getOrderDetails(this.cartSubject.value.OrderHeaderKey)
        .pipe(map(r => {
          this.cartSubject.next(r);
          return r;
        }));
    }));
  }
  updateWorkOrderNode(workOrderKey: string, node: string, orderLineKey: string): Observable<any> {
    return this.callApi("cancelWorkOrder", {
      "WorkOrder": {
        "WorkOrderKey": workOrderKey,
        "ReasonCode": "YCD_CHANGE_NODE"
      }
    }).pipe(switchMap(rs => {
      return this.callApi("changeOrder", {
        "Order": {
          "OrderHeaderKey":  this.cartSubject.value.OrderHeaderKey,
          "OrderLines": {
            "OrderLine": {
              "OrderLineKey": orderLineKey,
              "ShipNode": node
            }
          }
        }
      })
        .pipe(switchMap(r => {
          return this.generateWorkOrder();
        }));
    }));

  }

  getOrderAvailability(order: Order): Observable<any> {
    let input = {
      "Promise": {
        "OrganizationCode": order.SellerOrganizationCode,
        "IgnorePromised": "Y",
        "IgnoreUnpromised": "Y",
        "EvaluateOrder": {
          "OrderHeaderKey": order.OrderHeaderKey
        },
        "EvaluateOptions": {
          "EvaluateOption": [
            {
              "DeliveryMethod": "SHP"
            }, {
              "DeliveryMethod": "DEL"
            }, {
              "DeliveryMethod": "PICK"
            }
          ]
        },
        "PromiseLines": {
          "PromiseLine": []
        }
      }
    };

    for(let ol of order.OrderLines) {
      let l = {
        "OrderLine": {
          "OrderLineKey": ol.OrderLineKey
        }
      };
      input.Promise.PromiseLines.PromiseLine.push(l);
    }
    return this.callApi("getFulfillmentOptionsForLines", input);
  }
}
