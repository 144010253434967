<!-- Add to credit card modal popup start-->
<ng-template #creditCardModal let-modal>
    <div class="credit-card">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 header">
                   {{ "capture-payment-form" | translate: {amount: cart.PriceInfo.TotalAmount | currency : cart.PriceInfo.Currency} }}
                </div>
                <div class="col-12 body">
                    <form [formGroup]="formGroup" (ngSubmit)="handleResponse()">
                        <div class="form-group col-xs-12">
                            <div class="field-label" translate>name-on-card</div>
                            <input type="text" name="CreditCardName" formControlName="CreditCardName" value="" placeholder="" autocomplete="off">
                            <div translate *ngIf="formGroup.controls.CreditCardName.touched && formGroup.controls.CreditCardName.errors?.required" class="text text-danger">
                            name-required
                            </div>
                            <div translate *ngIf="formGroup.controls.CreditCardName.touched && formGroup.controls.CreditCardName.errors?.pattern" class="text text-danger">
                            name-alphabet
                            </div>
                        </div>
                        <div class="form-group col-xs-12">
                            <div class="field-label" translate>credit-card-number</div>
                            <input type="text" name="CreditCardNo" formControlName="CreditCardNo" value="" placeholder="" autocomplete="off">
                            <div translate *ngIf="formGroup.controls.CreditCardNo.touched && formGroup.controls.CreditCardNo.errors?.required" class="text text-danger">
                            number-required
                            </div>
                            <div translate *ngIf="formGroup.controls.CreditCardNo.touched && formGroup.controls.CreditCardNo.errors?.pattern" class="text text-danger">
                            number-incorrect
                            </div>
                        </div>
                        <div class="form-group col-xs-12">
                            <div class="field-label" translate>expiration-date</div>
                            <input type="text" name="CreditCardExpDate" formControlName="CreditCardExpDate" value="" placeholder="mm/yyyy" autocomplete="off">
                            <div translate *ngIf="formGroup.controls.CreditCardExpDate.touched && formGroup.controls.CreditCardExpDate.errors?.required" class="text text-danger">
                            date-required
                            </div>
                            <div translate *ngIf="formGroup.controls.CreditCardExpDate.touched && formGroup.controls.CreditCardExpDate.errors?.pattern" class="text text-danger">
                            date-invalid
                            </div>
                        </div>
                        <button type="submit" class="btn-solid btn" [disabled]="!formGroup.valid" translate>
                            add-card
                         </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add to credit card modal popup end-->