import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import {
  AngularFireFunctions
} from '@angular/fire/functions';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Admin, ECommTemplate, TemplateOption, User } from '../classes/user';
import { Customer } from '../classes/customer';
import { AppSettings } from './app.settings';
import { OmsRestService } from './oms.service';
import { RequestCache } from './request-cache.service';
import * as _ from "lodash";
import { Address, Order } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  access$: Observable<Admin>;

  private user: BehaviorSubject<User>;
  private customer: BehaviorSubject<Customer>;
  private template: BehaviorSubject<ECommTemplate>;
  private admin: BehaviorSubject<Admin>;

  user$: Observable<User>;
  customer$: Observable<Customer>;
  template$: Observable<ECommTemplate>;
  templateOptions$: Observable<TemplateOption>;
  
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private router: Router,
    private appSettings: AppSettings,
    private omsService: OmsRestService,
    private requestCache: RequestCache
  ) {
    if(sessionStorage.getItem("user")){
      this.user = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("user")));
    } else {
      this.user = new BehaviorSubject<User>(undefined);
    }
    if(sessionStorage.getItem("template")){
      this.template = new BehaviorSubject<ECommTemplate>(JSON.parse(sessionStorage.getItem("template")));
    } else {
      this.template = new BehaviorSubject<ECommTemplate>(undefined);
    }
    if(sessionStorage.getItem("customer")){
      this.customer = new BehaviorSubject<Customer>(JSON.parse(sessionStorage.getItem("customer")));
    } else {
      this.customer = new BehaviorSubject<Customer>(undefined);
    }
    if(sessionStorage.getItem("access")){
      this.admin = new BehaviorSubject<Admin>(JSON.parse(sessionStorage.getItem("access")));
    } else {
      this.admin = new BehaviorSubject<Admin>({
        uid: undefined,
        Ecomm: false,
        ECommManager: false
      });
    }
    this.afAuth.authState.subscribe(user => {
      if(user){
        user.getIdToken().then(authId => {
          sessionStorage.setItem("access_token", authId);
        })
        if(user && user.uid){
          combineLatest([
            this.afs.doc<User>(`users/${user.uid}`).valueChanges(), 
            this.afs.doc<Admin>(`admin/${user.uid}`).valueChanges()
          ]).subscribe((output) => {
            if(output[0]) {
              sessionStorage.setItem("user", JSON.stringify(output[0]));
              this.user.next(output[0]);
            }
            if(output[1]) {
              sessionStorage.setItem("access", JSON.stringify(output[1]));
              this.admin.next(output[1]);
            }
            
            if(output[0] && output[1] && output[1].Ecomm && output[0].eCommTemplate){
              this.afs.doc<ECommTemplate>(`ecomm_template/${output[0].eCommTemplate}`).valueChanges().pipe(switchMap(template => {
                return this.afs.doc<TemplateOption>(`template_options/${template.theme}`).valueChanges().pipe(map(templateOption => {
                  template['template'] = templateOption;
                  template['id'] = output[0].eCommTemplate;
                  return template;
                }));
              })).subscribe(template => {
                let existing = undefined;
                if (sessionStorage.getItem("template")){
                  existing = JSON.parse(sessionStorage.getItem("template"));
                } 
                if(!existing || !_.isEqual(existing, template)) {
                  sessionStorage.setItem("template", JSON.stringify(template));
                  this.template.next(template);
                  if(!this.customer.getValue() || this.customer.getValue().CustomerKey !== output[0].customerId){
                    this.getCustomerDetails(output[0]).subscribe(customer => {
                      sessionStorage.setItem("customer", JSON.stringify(customer));
                      this.customer.next(customer);
                    })
                  }
                }
  
              });
            }
          });
        }
        
      }
     
    });

    this.user$ = this.user.asObservable();
    this.template$ = this.template.asObservable();
    this.access$ = this.admin.asObservable();
    this.customer$ = this.customer.asObservable();

  }

  async signIn(email: string, password: string){
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }
  public getIVDetails(): Observable<any> {
    if(this.template && this.template.getValue() && this.template.getValue().ivTenantID){
      const token = sessionStorage.getItem(`iv_${this.template.getValue().ivTenantID}`);
      if(token) {
        const json = JSON.parse(token);
        const millis = Date.now();
        if(json.expiration > millis){
          return of(json);
        }
      }
      return this.afAuth.authState.pipe(switchMap(user => {
        return this.aff.httpsCallable('iv/getIVToken')({}).pipe(map(x => {
        let response = JSON.parse(x);
        response["expiration"] = (response.expires_in * 1000) + Date.now();
        sessionStorage.setItem(`iv_${this.template.getValue().ivTenantID}`, JSON.stringify(response));
        return response; 
      }));
    }));
    } else {
      return of(undefined);
    }
  }
  public getPromisingDetails(): Observable<any> {
    if(this.template && this.template.getValue() && this.template.getValue().ivTenantID){
      const token = sessionStorage.getItem(`promising_${this.template.getValue().ivTenantID}`);
      if(token) {
        const json = JSON.parse(token);
        const millis = Date.now();
        if(json.expiration > millis){
          return of(json);
        }
      }
      return this.afAuth.authState.pipe(switchMap(user => {
        return this.aff.httpsCallable('iv/getPromisingToken')({}).pipe(map(x => {
        let response = JSON.parse(x);
        if(!response.expires_in){
          response["expires_in"] = 7200;
        }
        response["expiration"] = (response.expires_in * 1000) + Date.now();
        sessionStorage.setItem(`promising_${this.template.getValue().ivTenantID}`, JSON.stringify(response));
        return response; 
      }));
    }));
    } else {
      return of(undefined);
    }
  }

  public getCustomerDetails(user: User, reload: boolean = false): Observable<Customer> {
    if(reload){
      this.requestCache.clearKey(`customer_${user.customerId}`);
    }
    return this.omsService.callApi("getCustomerDetails", {"Customer": {"CustomerKey": user.customerId}}, "getCustomerDetails", true, `customer_${user.customerId}`)
      .pipe(
        catchError(error => {
          return this.findCustomer(user.email).pipe(switchMap(x => {
            if(x) {
              this.updateUserData(user, x.CustomerKey);
              return x;
            } else {
              return this.createCustomer(user.displayName, user.email).pipe(map(x => {
                if(x) {
                  this.updateUserData(user, x.CustomerKey);
                  return x;
                }
              }));
            }

          }))
        }),
        map((customer) => {
          if(customer){
            sessionStorage.setItem("customer", JSON.stringify(new Customer(customer)));
            this.customer.next(new Customer(customer));
            return new Customer(customer);
          }
        return undefined;
      }));
  }

  
  public findCustomer(email: string): Observable<any> {
    return this.omsService.callApi("getCustomerList", {"Customer": {"CustomerType": "02", "CallingOrganizationCode": this.appSettings.getCurrentSettings().baseOrg, "CustomerContactList":{"CustomerContact":{"EmailID": email}}}}, "getCustomerDetails")
      .pipe(map(customer => {
        if(customer && customer.Customer && customer.Customer.length > 0){
          console.log("found customer", customer);
          return customer.Customer[0];
        }
        return undefined;
      }));
  }

  public createCustomer(displayName: string, email: string): Observable<any> {
    console.log("createCustomer", email);
    let customerMaster = "Bambu-Corp";
    if(this.appSettings.getCurrentSettings().baseOrg.indexOf("Auro") > -1) {
      customerMaster = "Aurora-Corp";
    }
    return this.omsService.callApi("createCustomer", {"Customer": {"CustomerType": "02", "OrganizationCode":customerMaster, "CustomerContactList":{"CustomerContact":{"EmailID": email, "FirstName": displayName.split(" ")[0], "LastName": displayName.split(" ")[1]}}}})
      .pipe(map(customer => {
        if(customer ){
          return customer;
        }
        return undefined;
      }));
  }
  
  public async updateCustomer(user: User) {
    let customer = await this.findCustomer(user.email).toPromise();
    if(!customer){
      customer = await this.createCustomer(user.displayName, user.email).toPromise();
    }
    return this.updateUserData(user, customer.CustomerKey)
  }


  public updateCustomerAddress(address: Address, type: string, customerAdditionalAddressId?: string): Observable<any> {
    if(address && this.customer.getValue() && this.customer.getValue().CustomerKey) {
      let input = {
        "Customer": {
          "CustomerKey": this.customer.getValue().CustomerKey,
          "CustomerAdditionalAddressList": {
            "CustomerAdditionalAddress": {
              "IsShipTo": "Y",
              "IsBillTo": "Y",
              "PersonInfo": address
            }
          }
        }
      };
      if(customerAdditionalAddressId) {
        input.Customer.CustomerAdditionalAddressList.CustomerAdditionalAddress["CustomerAdditionalAddressID"] = customerAdditionalAddressId;
      }
      if(type === "billing") {
        input.Customer.CustomerAdditionalAddressList.CustomerAdditionalAddress["IsDefaultBillTo"] = "Y";
      } else if(type === "shipping") {
        input.Customer.CustomerAdditionalAddressList.CustomerAdditionalAddress["IsDefaultShipTo"] = "Y";
      }
      return this.omsService.callApi("manageCustomer", input).pipe(switchMap(
        (customer) => {
          return this.getCustomerDetails(this.user.getValue(), true);
        }
      ))
    }
    return of(undefined);
  }
  
  async register(email: string, password: string, displayName: string) {
    let credential = await this.afAuth.createUserWithEmailAndPassword(email, password);
    let { uid } = credential.user;
    let user = {
        uid,
        email,
        displayName
    };
    return await this.updateCustomer(user);
  }


  public getOrderHistory(): Observable<Order[]> {
    return this.omsService.callApi("getOrderList", {
      "Order": {
        "EnterpriseCode": this.appSettings.getCurrentSettings().baseOrg,
        "DocumentType": "0001",
        "DraftOrderFlag": "N",
        "ComplexQuery": {
          "Or": {
            "Exp": [
              {
                "Name": "BillToID",
                "Value": this.customer.getValue().CustomerID
              },
              {
                "Name": "CustomerEMailID",
                "Value": this.user.getValue().email
              }
            ]
          }
        }
      }
    }, "getOrderList", false, "", true).pipe(map(x => {
      console.log(x);
      if(x.Order) {
        return x.Order.map(o => new Order(o));
      } else {
        return [];
      }

    }))
  }



  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    const { user: { email, displayName }} = credential;
    return await this.updateCustomer(credential.user);
  }

  async signOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.user.next(undefined);
    this.admin.next({
      uid: undefined,
      Ecomm: false,
      ECommManager: false
    });
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }


  public updateUserCart(user, cartKey) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data = {
      currentCart: cartKey
    };

    userRef.update(data);
    return;
  }

  public setCurrentStore(storeId: string) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.user.getValue().uid}`);
    const data = {
      shipNode: storeId,
      uid: this.user.getValue().uid,
      email: this.user.getValue().email
    };

    return userRef.set(data, { merge: true });
  }

  private updateUserData(user, customerId) {

    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      customerId: customerId
    };
    if(user.photoURL){
      data["photoURL"] = user.photoURL;
    }

    return userRef.set(data, { merge: true });
  }

  public getCurrentCustomer(): Customer {
    if(this.customer.getValue()){
      return this.customer.getValue();
    }
    return undefined;
  }
}
