import { Component, OnInit } from '@angular/core';
import { ProductBoxComponent } from '../product-box.component';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent extends ProductBoxComponent implements OnInit {


  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 200); // Skeleton Loader
      this.ImageSrc = this.product.images[0].small;
    }
  }


}
