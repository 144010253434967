import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      'email': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    });
  }

  public onRegisterFormSubmit(values:Object):void {
    console.log("register");
    if (this.registerForm.valid) {
      this.authService.register(this.registerForm.get('email').value, this.registerForm.get('password').value, this.registerForm.get('name').value)
        .then(() => {
          this.router.navigate(['/']);
        });
    }
  }
}
