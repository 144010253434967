export interface Template {
  ApiName: string;
  TemplateId: string;
  TemplateData: any;
  TemplateType: string;
}

export const CONTEXT = {
  EnterpriseCode: "Outdoors",
  CatalogOrganizationCode: "Bambu-Corp",
  CustomerMasterOrganizationCode: "Bambu-Corp",
  InventoryOrganizationCode: "Bambu-Corp",
  IV: {
    TENANT_ID: "24b3166f",
    CLIENT_ID: "3f57bb9bbc184c6ea582f7d57f940729",
    SECRET_ID: "1f16b62ad1134ba79dbe7b2f495b78b3",
    DISTRIBUTION_GROUP_ID: "Outdoors_Shipping",
  },
};

const PrimaryInformation = {
  ImageLocation: "",
  ImageID: "",
  ExtendedDisplayDescription: "",
  ExtendedDescription: "",
  ShortDescription: "",
  IsValid: "",
  IsModelItem: "",
  IsConfigurable: "",
  IsShippingAllowed: "",
  IsPickupAllowed: "",
  IsDeliveryAllowed: "",
  KitCode: "",
  ConfigurationModelName: "",
  ConfiguredModelKey: "",
  IsPreConfigured: "",
  AssumeInfiniteInventory: "",
  ConfigurationKey: "",
};

const Order = {

};


const Customer = {
  CustomerKey: "",
  CustomerID: "",
  OrganizationCode: "",
  CustomerType: "",
  CustomerLevel: "",
  RelationshipType: "",
  CustomerRewardsNo: "",
  Vertical: "",
  Status: ""
}

const Address = {
  City: "",
  State: "",
  ZipCode: "",
  Country: "",
  AddressLine1: "",
  AddressLines2: "",
  FirstName: "",
  LastName: "",
  DayPhone: "",
  MobilePhone: "",
  EMailID: "",
};

export const TEMPLATES: Template[] = [
  {
    ApiName: "createOrder",
    TemplateType: "00",
    TemplateId: "createDraftOrder",
    TemplateData: {
      Order: {
        OrderHeaderKey: "",
        DraftOrderFlag: "",
        OrderNo: "",
        EnterpriseCode: "",
        PriceInfo: {
          Currency: "",
          TotalAmount: "",
        },
        OrderLines: {
          OrderLine: {
            OrderLineKey: "",
            PrimeLineNo: "",
            OrderedQty: "",
            LineType: "",
            ShipNode: "",
            DeliveryMethod: "",
            Item: {
              ItemID: "",
              UnitOfMeasure: "",
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCompleteOrderDetails",
    TemplateType: "00",
    TemplateId: "getOrderHistoryDetails",
    TemplateData: {
      Order: {
        OrderHeaderKey: "",
        OrderNo: "",
        EnterpriseCode: "",
        DocumentType: "",
        Status: "",
        PriceInfo: {
          Currency: "",
          TotalAmount: "",
        },
        Shipments: {
          Shipment: {
            ShipmentKey: "",
            ShipmentNo: "",
            ActualShipmentDate: "",
            ExpectedDeliveryDate: "",
            DeliveryMethod: "",
            CarrierServiceCode: "",
            SCAC: "",
            TrackingNo: "",
            ShipNode: "",
            Notes: {
              Note: {
                NoteText: "",
                Priority: "",
                ReasonCode: "",
                ContactTime: "",
                ContactReference: "",
                ContactType: "",
                ContactUser: "",
                SequenceNo: "",
              },
            },
            ToAddress: Address,
            Status: {
              Description: "",
              Status: "",
            },
          },
        },
        OrderLines: {
          OrderLine: {
            OrderLineKey: "",
            OrderedQty: "",
            DeliveryMethod: "",
            HoldFlag: "",
            IsBundleParent: "",
            IsConfigured: "",
            ItemGroupCode: "",
            KeyCode: "",
            PrimeLineNo: "",
            SubLineNo: "",
            LineType: "",
            CustomAttributes: {
              RentalDays: "",
            },
            ShipNode: "",
            ShipnodeDescription: "",
            SCAC: "",
            CarrierServiceCode: "",
            ConfigurationKey: "",
            ReservationID: "",
            Status: "",
            OrderLineReservations: {
              OrderLineReservation: {
                ReservationID: "",
                Quantity: "",
              },
            },
            BundleParentLine: {
              OrderLineKey: "",
            },
            Item: {
              ItemID: "",
              UnitOfMeasure: "",
              ProductClass: "",
            },
            ItemDetails: {
              ItemKey: "",
              ItemID: "",
              UnitOfMeasure: "",
              PrimaryInformation: PrimaryInformation,
            },
            LinePriceInfo: {
              UnitPrice: "",
              LineTotal: "",
              ExtendedPrice: "",
              BundleTotal: "",
            },
          },
        },
      },
    },
  },
  {
    ApiName: "createOrder",
    TemplateType: "00",
    TemplateId: "createOrder",
    TemplateData: {
      Order: {
        OrderHeaderKey: "",
        DraftOrderFlag: "",
        OrderNo: "",
        EnterpriseCode: "",
        PriceInfo: {
          Currency: "",
          TotalAmount: "",
        },
        OrderLines: {
          OrderLine: {
            OrderLineKey: "",
            PrimeLineNo: "",
            OrderedQty: "",
            ShipNode: "",
            DeliveryMethod: "",
            Item: {
              ItemID: "",
              UnitOfMeasure: "",
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCommonCodeList",
    TemplateType: "00",
    TemplateId: "getCountryList",
    TemplateData: {
      CommonCodeList: {
        CommonCode: {
          CodeLongDescription: "",
          CodeShortDescription: "",
          CodeValue: "",
        },
      },
    },
  },
  {
    ApiName: "getOrderList",
    TemplateType: "00",
    TemplateId: "getOrderHistoryList",
    TemplateData: {
      OrderList: {
        Order: {
          OrderHeaderKey: "",
          OrderNo: "",
          EntryType: "",
          DocumentType: "",
          DraftOrderFlag: "",
          SellerOrganizationCode: "",
          CarrierServiceCode: "",
          EnterpriseCode: "",
          BillToID: "",
          OrderName: "",
          Status: "",
          OrderDate: "",
          PriceInfo: {
            Currency: "",
            TotalAmount: "",
          },
          OverallTotals: {
            GrandTotal: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getOrderLineDetails",
    TemplateType: "00",
    TemplateId: "getConfigurationDetails",
    TemplateData: {
      OrderLine: {
        ConfigurationKey: "",
        BOMXML: "",
      },
    },
  },
  {
    ApiName: "getCustomerList",
    TemplateType: "00",
    TemplateId: "getCustomerDetails",
    TemplateData: {
      CustomerList: {
        Customer: {
          ...Customer,
          CustomerAdditionalAddressList: {
            CustomerAdditionalAddress: {
              IsDefaultShipTo: "",
              IsDefaultBillTo: "",
              IsShipTo: "",
              IsBillTo: "",
              PersonInfo: Address,
            },
          },
          CustomerCurrencyList: {
            CustomerCurrency: {
              Currency: "",
              CustomerCurrencyKey: "",
              IsDefaultCurrency: "",
            },
          },
          CustomerContactList: {
            CustomerContact: {
              CustomerContactID: "",
              FirstName: "",
              LastName: "",
              EmailID: "",
              DayPhone: "",
              MobilePhone: "",
              DateOfBirth: "",
              Title: "",
              CustomerAdditionalAddressList: {
                CustomerAdditionalAddress: {
                  IsDefaultShipTo: "",
                  IsDefaultBillTo: "",
                  IsShipTo: "",
                  IsBillTo: "",
                  PersonInfo: {
                    ...Address
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCustomerDetails",
    TemplateType: "00",
    TemplateId: "getCustomerDetails",
    TemplateData: {
      Customer: {
        ...Customer,
        CustomerAdditionalAddressList: {
          CustomerAdditionalAddress: {
            IsDefaultShipTo: "",
            IsDefaultBillTo: "",
            IsShipTo: "",
            IsBillTo: "",
            CustomerAdditionalAddressID: "",
            PersonInfo: {
             ...Address
            },
          },
        },
        CustomerCurrencyList: {
          CustomerCurrency: {
            Currency: "",
            CustomerCurrencyKey: "",
            IsDefaultCurrency: "",
          },
        },
        CustomerContactList: {
          CustomerContact: {
            CustomerContactID: "",
            FirstName: "",
            LastName: "",
            EmailID: "",
            DayPhone: "",
            MobilePhone: "",
            DateOfBirth: "",
            Title: "",
            CustomerAdditionalAddressList: {
              CustomerAdditionalAddress: {
                IsDefaultShipTo: "",
                IsDefaultBillTo: "",
                CustomerAdditionalAddressID: "",
                IsShipTo: "",
                IsBillTo: "",
                PersonInfo: {
                  ...Address
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "createCustomer",
    TemplateType: "00",
    TemplateId: "getCustomerDetails",
    TemplateData: {
      Customer: {
        ...Customer,
        CustomerContactList: {
          CustomerContact: {
            CustomerContactID: "",
            FirstName: "",
            LastName: "",
            EmailID: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getOrderList",
    TemplateType: "00",
    TemplateId: "getOrderList",
    TemplateData: {
      OrderList: {
        Order: {
          OrderNo: "",
          EnterpriseCode: "",
          DocumentType: "",
          OrderHeaderKey: "",
          DraftOrderFlag: "",
          OrderDate: "",
          Status: "",
          SellerOrganizationCode: "",
          OrderName: "",
          PriceInfo: {
            TotalAmount: "",
            Currency: "",
          },
          PersonInfoBillTo: {
            ...Address
          },
          PersonInfoShipTo: {
            ...Address
          },
          OverallTotals: {
            GrandTotal: "",
            GrandShippingTotal: "",
            GrandTax: "",
            LineSubTotal: "",
            GrandCharges: "",
            GrandDiscount: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getShipNodeList",
    TemplateType: "00",
    TemplateId: "getNodeDetailList",
    TemplateData: {
      ShipNodeList: {
        ShipNode: {
          Description: "",
          NodeType: "",
          OwnerKey: "",
          ShipNode: "",
          ShipnodeKey: "",
          ShipNodePersonInfo: {
            ...Address
          },
        },
      },
    },
  },
  {
    ApiName: "getSurroundingNodeList",
    TemplateType: "00",
    TemplateId: "getSurroundingNodeList",
    TemplateData: {
      GetSurroundingNodeList: {
        NodeList: {
          Node: {
            Description: "",
            DistanceFromShipToAddress: "",
            DistanceUOM: "",
            NodeType: "",
            OwnerKey: "",
            ShipNode: "",
            ShipnodeKey: "",
            ShipNodePersonInfo: {
              ...Address
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCompleteOrderDetails",
    TemplateType: "00",
    TemplateId: "getOrderDetails",
    TemplateData: {
      Order: {
        OrderHeaderKey: "",
        OrderNo: "",
        EntryType: "",
        DocumentType: "",
        DraftOrderFlag: "",
        SellerOrganizationCode: "",
        CarrierServiceCode: "",
        EnterpriseCode: "",
        BillToID: "",
        OrderName: "",
        Status: "",
        OrderDate: "",
        PriceInfo: {
          Currency: "",
          TotalAmount: "",
        },
        ChargeTransactionDetails: {
          RemainingAmountToAuth: "",
          AdditionalExpectedAuthorizations: "",
          TotalOpenAuthorizations: "",
        },
        PersonInfoBillTo: {
          ...Address
        },
        PersonInfoShipTo: {
          ...Address
        },
        OverallTotals: {
          GrandTotal: "",
          GrandShippingTotal: "",
          GrandTax: "",
          LineSubTotal: "",
          GrandCharges: "",
          GrandDiscount: "",
        },
        Promotions: {
          Promotion: {
            PromotionId: "",
            PromotionKey: "",
            PromotionType: "",
            PromotionApplied: "",
            Description: "",
            DenialReason: "",
            PricingRule: {
              Description: "",
            },
          },
        },
        Awards: {
          Award: {
            AwardAmount: "",
            AwardApplied: "",
            PromotionId: "",
            PromotionKey: "",
          },
        },
        PaymentMethods: {
          PaymentMethod: {
            PaymentType: "",
            MaxChargeLimit: "",
            UnlimitedCharges: "",
            FundsAvailable: "",
            GetFundsAvailableUserExitInvoked: "",
            ChargeSequence: "",
            PaymentKey: "",
            DisplaySvcNo: "",
            DisplayCreditCardNo: "",
            CreditCardExpDate: "",
            CreditCardName: "",
            CreditCardType: "",
          },
        },
        OrderLines: {
          OrderLine: {
            OrderLineKey: "",
            OrderedQty: "",
            DeliveryMethod: "",
            HoldFlag: "",
            IsBundleParent: "",
            IsConfigured: "",
            ItemGroupCode: "",
            KeyCode: "",
            PrimeLineNo: "",
            SubLineNo: "",
            LineType: "",
            CustomAttributes: {
              RentalDays: "",
            },
            ShipNode: "",
            ShipnodeDescription: "",
            SCAC: "",
            CarrierServiceCode: "",
            ConfigurationKey: "",
            ReservationID: "",
            Status: "",
            OrderLineReservations: {
              OrderLineReservation: {
                ReservationID: "",
                Quantity: "",
              },
            },
            BundleParentLine: {
              OrderLineKey: "",
            },
            Item: {
              ItemID: "",
              UnitOfMeasure: "",
              ProductClass: "",
            },
            ItemDetails: {
              ItemKey: "",
              ItemID: "",
              UnitOfMeasure: "",
              PrimaryInformation: PrimaryInformation,
              "ClassificationCodes": {
                "PickingType": ""
              },
            },
            LinePriceInfo: {
              UnitPrice: "",
              LineTotal: "",
              ExtendedPrice: "",
              BundleTotal: "",
            },
            WorkOrders: {
              WorkOrder: {
                WorkOrderKey: "",
                WorkOrderNo: "",
                Status: "",
                StatusDescription: "",
                WorkOrderAppointments: {
                  WorkOrderAppointment: {
                    WorkOrderApptKey: "",
                    RequestedQuantity: "",
                    ExpectedApptStartDate: "",
                    ExpectedApptEndDate: "",
                    ExecutionStartTime: "",
                    ExecutionEndTime: "",
                    PromisedApptEndDate: "",
                    PromisedApptStartDate: "",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "changeOrder",
    TemplateType: "00",
    TemplateId: "getOrderDetails",
    TemplateData: {
      Order: {
        OrderHeaderKey: "",
        OrderNo: "",
        EntryType: "",
        DocumentType: "",
        DraftOrderFlag: "",
        SellerOrganizationCode: "",
        CarrierServiceCode: "",
        EnterpriseCode: "",
        BillToID: "",
        OrderName: "",
        PriceInfo: {
          Currency: "",
          TotalAmount: "",
        },
        Promotions: {
          Promotion: {
            PromotionId: "",
            PromotionKey: "",
            PromotionType: "",
            PromotionApplied: "",
            Description: "",
            DenialReason: "",
            PricingRule: {
              Description: "",
            },
          },
        },
        Awards: {
          Award: {
            AwardAmount: "",
            AwardApplied: "",
            PromotionId: "",
            PromotionKey: "",
          },
        },
        PaymentMethods: {
          PaymentMethod: {
            PaymentType: "",
            MaxChargeLimit: "",
            UnlimitedCharges: "",
            FundsAvailable: "",
            GetFundsAvailableUserExitInvoked: "",
            ChargeSequence: "",
            PaymentKey: "",
            DisplaySvcNo: "",
            DisplayCreditCardNo: "",
            CreditCardExpDate: "",
            CreditCardName: "",
            CreditCardType: "",
          },
        },
        ChargeTransactionDetails: {
          RemainingAmountToAuth: "",
          AdditionalExpectedAuthorizations: "",
          TotalOpenAuthorizations: "",
        },
        PersonInfoBillTo: {
          ...Address
        },
        PersonInfoShipTo: {
          ...Address
        },
        OverallTotals: {
          GrandTotal: "",
          GrandShippingTotal: "",
          GrandTax: "",
          LineSubTotal: "",
          GrandCharges: "",
          GrandDiscount: "",
        },
        OrderLines: {
          OrderLine: {
            OrderLineKey: "",
            OrderedQty: "",
            DeliveryMethod: "",
            HoldFlag: "",
            IsBundleParent: "",
            IsConfigured: "",
            ReservationID: "",
            ItemGroupCode: "",
            KeyCode: "",
            PrimeLineNo: "",
            SubLineNo: "",
            ShipNode: "",
            ShipnodeDescription: "",
            SCAC: "",
            LineType: "",
            CustomAttributes: {
              RentalDays: "",
            },
            CarrierServiceCode: "",
            ConfigurationKey: "",
            Status: "",
            Item: {
              ItemID: "",
              UnitOfMeasure: "",
              ProductClass: "",
            },
            BundleParentLine: {
              OrderLineKey: "",
            },
            OrderLineReservations: {
              OrderLineReservation: {
                ReservationID: "",
                Quantity: "",
              },
            },
            ItemDetails: {
              ItemKey: "",
              ItemID: "",
              UnitOfMeasure: "",
              PrimaryInformation: PrimaryInformation,
              "ClassificationCodes": {
                "PickingType": ""
              },
            },
            LinePriceInfo: {
              UnitPrice: "",
              LineTotal: "",
              ExtendedPrice: "",
              BundleTotal: "",
            },
          },
        },
      },
    },
  },
  {
    ApiName: "searchCatalogIndex",
    TemplateType: "00",
    TemplateId: "searchCatalogIndexCategories",
    TemplateData: {
      CatalogSearch: {
        CategoryList: {
          Category: {
            CategoryID: "",
            CategoryPath: "",
            CategoryKey: "",
            Description: "",
            Status: "",
            SequenceNo: "",
            ShortDescription: "",
            ChildCategoryList: {
              Category: {
                CategoryID: "",
                CategoryPath: "",
                CategoryKey: "",
                Description: "",
                Status: "",
                SequenceNo: "",
                ShortDescription: "",
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCategoryDetails",
    TemplateType: "00",
    TemplateId: "getCategoryDetails",
    TemplateData: {
      Category: {
        CategoryID: "",
        CategoryPath: "",
        CategoryKey: "",
        Description: "",
        Status: "",
        SequenceNo: "",
        ShortDescription: "",
        ChildCategoryList: {
          Category: {
            CategoryID: "",
            CategoryPath: "",
            CategoryKey: "",
            Description: "",
            Status: "",
            SequenceNo: "",
            ShortDescription: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getCompleteItemList",
    TemplateType: "00",
    TemplateId: "ItemDetails",
    TemplateData: {
      ItemList: {
        Currency: "",
        PricingDate: "",
        Item: {
          ItemID: "",
          UnitOfMeasure: "",
          OrganizationCode: "",
          ItemGroupCode: "",
          ProductClass: "",
          ItemKey: "",
          PrimaryInformation: PrimaryInformation,
          "ClassificationCodes": {
            "PickingType": ""
          },
          "AlternateUOMList": {
            "AlternateUOM": {
              "UnitOfMeasure": "",
              "IsOrderingUOM": ""
            }
          },
          ComputedPrice: {
            UnitPrice: "",
            RetailPrice: "",
            ListPrice: "",
            BundleTotal: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getItemListForOrdering",
    TemplateType: "00",
    TemplateId: "getItemVariationList",
    TemplateData: {
      ItemList: {
        Item: {
          ItemID: "",
          UnitOfMeasure: "",
          ItemKey: "",
          OrganizationCode: "",
          ChildItemList: {
            ChildItem: {
              ItemID: "",
              UnitOfMeasure: "",
              ItemKey: "",
              OrganizationCode: "",
              PrimaryInformation: PrimaryInformation,
              ComputedPrice: {
                UnitPrice: "",
                RetailPrice: "",
                ListPrice: "",
                BundleTotal: "",
              },
              "AlternateUOMList": {
                "AlternateUOM": {
                  "UnitOfMeasure": "",
                  "IsOrderingUOM": ""
                }
              },
              ItemAttributeGroupTypeList: {
                ItemAttributeGroupType: {
                  ClassificationPurposeCode: "",
                  ItemAttributeGroupList: {
                    ItemAttributeGroup: {
                      ItemAttributeGroupDescription: "",
                      ItemAttributeList: {
                        ItemAttribute: {
                          ItemAttributeDescription: "",
                          Value: "",
                          Attribute: {
                            ShortDescription: "",
                            AttributeID: "",
                            AttributeGroupID: "",
                            DataType: "",
                            IsAllowedValueDefined: "",
                            SequenceNo: "",
                          },
                          AssignedValueList: {
                            AssignedValue: {
                              Value: "",
                              ShortDescription: "",
                              SequenceNo: "",
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          PrimaryInformation: PrimaryInformation,
          "ClassificationCodes": {
            "PickingType": ""
          },
          ComputedPrice: {
            UnitPrice: "",
            RetailPrice: "",
            ListPrice: "",
            BundleTotal: "",
          },
          "AlternateUOMList": {
            "AlternateUOM": {
              "UnitOfMeasure": "",
              "IsOrderingUOM": ""
            }
          },
          ItemAttributeGroupTypeList: {
            ItemAttributeGroupType: {
              ClassificationPurposeCode: "",
              ItemAttributeGroupList: {
                ItemAttributeGroup: {
                  ItemAttributeGroupDescription: "",
                  ItemAttributeList: {
                    ItemAttribute: {
                      ItemAttributeDescription: "",
                      Value: "",
                      Attribute: {
                        ShortDescription: "",
                        AttributeID: "",
                        AttributeGroupID: "",
                        DataType: "",
                        IsAllowedValueDefined: "",
                        SequenceNo: "",
                      },
                      AssignedValueList: {
                        AssignedValue: {
                          Value: "",
                          ShortDescription: "",
                          SequenceNo: "",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCarrierServiceOptionsForOrdering",
    TemplateType: "00",
    TemplateId: "getCarrierServiceOptions",
    TemplateData: {
      Order: {
        OrderHeaderKey: "",
        OrderLines: {
          OrderLine: {
            OrderLineKey: "",
            CarrierServiceList: {
              CarrierService: {
                CarrierServiceCode: "",
                CarrierServiceDesc: "",
                Price: "",
                Currency: "",
                DeliveryStartDate: "",
                DeliveryEndDate: "",
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getShipNodeList",
    TemplateType: "00",
    TemplateId: "nodeList",
    TemplateData: {
      ShipNodeList: {
        ShipNode: {
          ShipnodeKey: "",
          ShipnodeType: "",
          ShipNodePersonInfo: {
            Latitude: "",
            Longitude: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getDistributionRuleList",
    TemplateType: "00",
    TemplateId: "getDistributionRuleList",
    TemplateData: {
      DistributionRuleList: {
        DistributionRule: {
          DistributionRuleId: "",
          ItemGroupCode: "",
          Purpose: "",
          ItemShipNodes: {
            ItemShipNode: {
              ShipnodeKey: "",
              ShipNode: {
                ShipnodeKey: "",
                Latitude: "",
                Longitude: "",
                NodeType: "",
                Localecode: "",
                ShipNodePersonInfo: {
                  Country: "",
                  ZipCode: "",
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getFulfillmentSummaryDetails",
    TemplateType: "00",
    TemplateId: "getShipmentBreakdownCarrierOptions",
    TemplateData: {
      Order: {
        PriceInfo: {
          Currency: "",
        },
        UnavailableLines: {
          ShippingLines: {
            OrderLine: {
              OrderLineKey: "",
              AvailableQty: "",
              DeliveryMethod: "",
              EarliestShipDate: "",
              ItemDetails: {
                ItemID: "",
                UnitOfMeasure: "",
                PrimaryInformation: {
                  ImageLocation: "",
                  ImageID: "",
                  ShortDescription: "",
                },
              },
              LinePriceInfo: {
                UnitPrice: "",
                LineTotal: "",
                ExtendedPrice: "",
                BundleTotal: "",
              },
            },
          },
          PickupLines: {
            OrderLine: {
              OrderLineKey: "",
              AvailableQty: "",
              DeliveryMethod: "",
              EarliestShipDate: "",
              ItemDetails: {
                ItemID: "",
                UnitOfMeasure: "",
                PrimaryInformation: {
                  ImageLocation: "",
                  ImageID: "",
                  ShortDescription: "",
                },
              },
              LinePriceInfo: {
                UnitPrice: "",
                LineTotal: "",
                ExtendedPrice: "",
                BundleTotal: "",
              },
            },
          },
        },
        PickupGroups: {
          PickupGroup: {
            Shipnode: {
              ShipNode: "",
              Description: "",
            },
            OrderLines: {
              OrderLine: {
                OrderLineKey: "",
                AvailableQty: "",
                DeliveryMethod: "",
                EarliestShipDate: "",
                ItemDetails: {
                  ItemID: "",
                  UnitOfMeasure: "",
                  PrimaryInformation: {
                    ImageLocation: "",
                    ImageID: "",
                    ShortDescription: "",
                  },
                },
                LinePriceInfo: {
                  UnitPrice: "",
                  LineTotal: "",
                  ExtendedPrice: "",
                  BundleTotal: "",
                },
              },
            },
          },
        },
        ShippingGroups: {
          ShippingGroup: {
            DeliveryDate: "",
            EarliestDate: "",
            LastDate: "",
            ShipDate: "",
            CarrierServiceCode: "",
            CarrierServiceList: {
              CarrierService: {
                CarrierServiceCode: "",
                CarrierServiceDesc: "",
                DeliveryEndDate: "",
                DeliveryStartDate: "",
                Price: "",
                Currency: "",
              },
            },
            OrderLines: {
              OrderLine: {
                OrderLineKey: "",
                AvailableQty: "",
                DeliveryMethod: "",
                EarliestShipDate: "",
                ItemDetails: {
                  ItemID: "",
                  UnitOfMeasure: "",
                  PrimaryInformation: {
                    ImageLocation: "",
                    ImageID: "",
                    ShortDescription: "",
                  },
                },
                LinePriceInfo: {
                  UnitPrice: "",
                  LineTotal: "",
                  ExtendedPrice: "",
                  BundleTotal: "",
                },
                CarrierServiceList: {
                  CarrierService: {
                    CarrierServiceCode: "",
                    CarrierServiceDesc: "",
                    DeliveryEndDate: "",
                    DeliveryStartDate: "",
                    Price: "",
                    Currency: "",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "searchCatalogIndex",
    TemplateType: "00",
    TemplateId: "searchCatalogIndexResults",
    TemplateData: {
      CatalogSearch: {
        ItemList: {
          Currency: "",
          Item: {
            ItemID: "",
            UnitOfMeasure: "",
            OrganizationCode: "",
            ProductClass: "",
            ItemKey: "",
            "AlternateUOMList": {
              "AlternateUOM": {
                "UnitOfMeasure": "",
                "IsOrderingUOM": ""
              }
            },
            PrimaryInformation: PrimaryInformation,
            "ClassificationCodes": {
              "PickingType": ""
            },
            Availability: {
              CurrentAvailableQty: "",
              OnhandAvailableQuantity: "",
            },
            ComputedPrice: {
              UnitPrice: "",
              RetailPrice: "",
              ListPrice: "",
              BundleTotal: "",
            },
            CategoryList: {
              Category: {
                CategoryPath: "",
              },
            },
          },
        },
        FacetList: {
          ItemAttribute: {
            Value: "",
            SequenceNo: "",
            ItemAttributeDescription: "",
            ItemAttributeName: "",
            ItemAttributeGroupKey: "",
            ItemAttributeGroupType: "",
            ItemAttributeKey: "",
          },
        },
        CurrentCategory: {
          CategoryPath: "",
          Count: "",
          ShortDescription: "",
          Description: "",
        },
        CategoryList: {
          Category: {
            CategoryID: "",
            CategoryPath: "",
            CategoryKey: "",
            Description: "",
            Count: "",
            Status: "",
            SequenceNo: "",
            ShortDescription: "",
            ChildCategoryList: {
              Category: {
                CategoryID: "",
                CategoryPath: "",
                CategoryKey: "",
                Description: "",
                Status: "",
                SequenceNo: "",
                ShortDescription: "",
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "getCategoryDetails",
    TemplateType: "00",
    TemplateId: "getCategoryDetails",
    TemplateData: {
      Category: {
        CategoryID: "",
        CategoryPath: "",
        ShortDescription: "",
        Description: "",
        OrganizationCode: "",
        AssetList: {
          Asset: {
            AssetID: "",
            ContentID: "",
            ContentLocation: "",
            Description: "",
            Type: "",
            Label: "",
          },
        },
      },
    },
  },
  {
    ApiName: "getItemListForOrdering",
    TemplateType: "00",
    TemplateId: "getItemList",
    TemplateData: {
      ItemList: {
        Currency: "",
        Item: {
          ItemID: "",
          UnitOfMeasure: "",
          OrganizationCode: "",
          ProductClass: "",
          ItemKey: "",
          PrimaryInformation: PrimaryInformation,
          "AlternateUOMList": {
            "AlternateUOM": {
              "UnitOfMeasure": "",
              "IsOrderingUOM": ""
            }
          },
          ComputedPrice: {
            UnitPrice: "",
            RetailPrice: "",
            ListPrice: "",
            BundleTotal: "",
          },
          "ClassificationCodes": {
            "PickingType": ""
          },
          ChildItemList: {
            ChildItem: {
              ItemID: "",
              UnitOfMeasure: "",
              ItemKey: "",
              OrganizationCode: "",
              PrimaryInformation: PrimaryInformation,
              InventoryTagAttributes: {
                BatchNo: "",
                LotNumber: "",
                ManufacturingDate: "",
                RevisionNo: "",
              },
              ComputedPrice: {
                UnitPrice: "",
                RetailPrice: "",
                ListPrice: "",
                BundleTotal: "",
              },
              ItemAttributeGroupTypeList: {
                ItemAttributeGroupType: {
                  ClassificationPurposeCode: "",
                  ItemAttributeGroupList: {
                    ItemAttributeGroup: {
                      ItemAttributeGroupDescription: "",
                      ItemAttributeList: {
                        ItemAttribute: {
                          ItemAttributeDescription: "",
                          Value: "",
                          Attribute: {
                            ShortDescription: "",
                            AttributeID: "",
                            AttributeGroupID: "",
                            DataType: "",
                            IsAllowedValueDefined: "",
                            SequenceNo: "",
                          },
                          AssignedValueList: {
                            AssignedValue: {
                              Value: "",
                              ShortDescription: "",
                              SequenceNo: "",
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    ApiName: "searchCatalogIndex",
    TemplateType: "00",
    TemplateId: "searchResultsNoInventory",
    TemplateData: {
      CatalogSearch: {
        ItemList: {
          Currency: "",
          Item: {
            ItemID: "",
            UnitOfMeasure: "",
            OrganizationCode: "",
            ProductClass: "",
            ItemKey: "",
            PrimaryInformation: PrimaryInformation,
            "ClassificationCodes": {
              "PickingType": ""
            },
            "AlternateUOMList": {
              "AlternateUOM": {
                "UnitOfMeasure": "",
                "IsOrderingUOM": ""
              }
            },
            ComputedPrice: {
              UnitPrice: "",
              RetailPrice: "",
              ListPrice: "",
              BundleTotal: "",
            },
            CategoryList: {
              Category: {
                CategoryPath: "",
              },
            },
            ChildItemList: {
              ChildItem: {
                ItemID: "",
                UnitOfMeasure: "",
                ItemKey: "",
                OrganizationCode: "",
                PrimaryInformation: PrimaryInformation,
                "ClassificationCodes": {
                  "PickingType": ""
                },
                "AlternateUOMList": {
                  "AlternateUOM": {
                    "UnitOfMeasure": "",
                    "IsOrderingUOM": ""
                  }
                },
                InventoryTagAttributes: {
                  BatchNo: "",
                  LotNumber: "",
                  ManufacturingDate: "",
                  RevisionNo: "",
                },
                ComputedPrice: {
                  UnitPrice: "",
                  RetailPrice: "",
                  ListPrice: "",
                  BundleTotal: "",
                },
                ItemAttributeGroupTypeList: {
                  ItemAttributeGroupType: {
                    ClassificationPurposeCode: "",
                    ItemAttributeGroupList: {
                      ItemAttributeGroup: {
                        ItemAttributeGroupDescription: "",
                        ItemAttributeList: {
                          ItemAttribute: {
                            ItemAttributeDescription: "",
                            Value: "",
                            Attribute: {
                              ShortDescription: "",
                              AttributeID: "",
                              AttributeGroupID: "",
                              DataType: "",
                              IsAllowedValueDefined: "",
                              SequenceNo: "",
                            },
                            AssignedValueList: {
                              AssignedValue: {
                                Value: "",
                                ShortDescription: "",
                                SequenceNo: "",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        FacetList: {
          ItemAttribute: {
            Value: "",
            SequenceNo: "",
            ItemAttributeDescription: "",
            ItemAttributeName: "",
            ItemAttributeGroupKey: "",
            ItemAttributeGroupType: "",
            ItemAttributeKey: "",
          },
        },
        CurrentCategory: {
          CategoryPath: "",
          Count: "",
          ShortDescription: "",
          Description: "",
        },
        CategoryList: {
          Category: {
            CategoryID: "",
            CategoryPath: "",
            CategoryKey: "",
            Description: "",
            Count: "",
            Status: "",
            SequenceNo: "",
            ShortDescription: "",
            ChildCategoryList: {
              Category: {
                CategoryID: "",
                CategoryPath: "",
                CategoryKey: "",
                Description: "",
                Status: "",
                SequenceNo: "",
                ShortDescription: "",
              },
            },
          },
        },
      },
    },
  },
];
