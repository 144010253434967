<!-- Add to credit card modal popup start-->
<ng-template #addressModal let-modal>
    <div class="address-form">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 header">
                   {{ "address-capture-form" | translate }}
                </div>
                <div class="col-12 body">
                    <form [formGroup]="formGroup" (ngSubmit)="handleResponse()">
                        <div class="row check-out">
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label" translate>first-name</div>
                                <input type="text" name="FirstName" [formControl]="formGroup.controls['FirstName']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="formGroup.controls.FirstName.touched && formGroup.controls.FirstName.errors?.required" class="text text-danger">
                                First Name is required.
                                </div>
                                <div *ngIf="formGroup.controls.FirstName.touched && formGroup.controls.FirstName.errors?.pattern" class="text text-danger">
                                First Name must be an alphabates.
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label" translate>last-name</div>
                                <input type="text" name="LastName" [formControl]="formGroup.controls['LastName']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="formGroup.controls.LastName.touched && formGroup.controls.LastName.errors?.required" class="text text-danger">
                                Last Name is required.
                                </div>
                                <div *ngIf="formGroup.controls.LastName.touched && formGroup.controls.LastName.errors?.pattern" class="text text-danger">
                                Last Name must be an alphabates.
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label" translate>Phone</div>
                                <input type="text" name="DayPhone" [formControl]="formGroup.controls['DayPhone']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="formGroup.controls.DayPhone.touched && formGroup.controls.DayPhone.errors?.required" class="text text-danger">
                                 DayPhone No is required.
                                </div>
                                <div *ngIf="formGroup.controls.DayPhone.touched && formGroup.controls.DayPhone.errors?.pattern" class="text text-danger">
                                  DayPhone No is must be number.
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label" translate>EMail</div>
                                <input type="text" name="EMailID" [formControl]="formGroup.controls['EMailID']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="formGroup.controls.EMailID.touched && formGroup.controls.EMailID.errors?.required" class="text text-danger">
                                 EMail is required
                                </div>
                                <div *ngIf="formGroup.controls.EMailID.touched && formGroup.controls.EMailID.errors?.EMailID" class="text text-danger">
                                 Invalid EMail
                                </div>
                            </div>
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <div class="field-label" translate>Country</div>
                                <select name="Country" [formControl]="formGroup.controls['Country']">
                                    <option value="" selected>Select Country</option>
                                    <option value="CA">Canada</option>
                                    <option value="US">United States</option>
                                    <option value="Australia">Australia</option>
                                </select>
                                <div *ngIf="formGroup.controls.Country.touched && formGroup.controls.Country.errors?.required" class="text text-danger">
                                 Country is required
                                </div>
                            </div>
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <div class="field-label" translate>address</div>
                                <input type="text" name="AddressLine1" [formControl]="formGroup.controls['AddressLine1']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="formGroup.controls.AddressLine1.touched && formGroup.controls.AddressLine1.errors?.required" class="text text-danger">
                                 Address is required
                                </div>
                                <div *ngIf="formGroup.controls.AddressLine1.touched && formGroup.controls.AddressLine1.errors?.maxlength" class="text text-danger">
                                  Maximum 50 character
                                </div>
                            </div>
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <div class="field-label" translate>city</div>
                                <input type="text" name="City" [formControl]="formGroup.controls['City']" value="" placeholder="" autocomplete="off">
                                 <div *ngIf="formGroup.controls.City.touched && formGroup.controls.City.errors?.required" class="text text-danger">
                                 City is required
                                </div>
                            </div>
                            <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                <div class="field-label" translate>state</div>
                                <input type="text" name="State" [formControl]="formGroup.controls['State']" value="" placeholder="" autocomplete="off">
                                 <div *ngIf="formGroup.controls.State.touched && formGroup.controls.State.errors?.required" class="text text-danger">
                                 State is required
                                </div>
                            </div>
                            <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                <div class="field-label" translate>postal-code</div>
                                <input type="text" name="ZipCode" [formControl]="formGroup.controls['ZipCode']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="formGroup.controls.ZipCode.touched && formGroup.controls.ZipCode.errors?.required" class="text text-danger">
                                 ZipCode is required
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn-solid btn" [disabled]="!formGroup.valid" translate>
                            save-address
                         </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add to credit card modal popup end-->