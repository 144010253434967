import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
    Injectable, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
  import { isPlatformBrowser } from '@angular/common';
  import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
  import { CartService } from 'src/app/shared/services/cart.service';
  import { Address, CustomerAddress, Order } from 'src/app/shared/classes';
  import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
  
  @Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
  })
  export class AddressComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() existingAddress: CustomerAddress;
    @Output() addressChange: EventEmitter<any> = new EventEmitter<any>();
    
    @ViewChild("addressModal", { static: false }) AddressModal: TemplateRef<any>;
    
    public closeResult: string;
    public modalOpen: boolean = false;
  
    public formGroup: FormGroup;
  
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
      private modalService: NgbModal,
      private fb: FormBuilder
    ) {
    }
  
    ngOnInit(): void {
        this.formGroup = this.fb.group({
            FirstName: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            LastName: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            DayPhone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
            EMailID: ['', [Validators.required, Validators.email]],
            AddressLine1: ['', [Validators.required, Validators.maxLength(50)]],
            Country: ['', Validators.required],
            City: ['', Validators.required],
            State: ['', Validators.required],
            ZipCode: ['', Validators.required]
        });
        
    }
  
    ngAfterViewInit(): void {
        if(this.existingAddress) {
            this.formGroup.patchValue(this.existingAddress.Address);
        }
    }
  
    async openModal() {
      this.modalOpen = true;
      if (isPlatformBrowser(this.platformId)) { // For SSR 
        this.modalService.open(this.AddressModal, { 
          size: 'sm',
          ariaLabelledBy: 'Address-Modal',
          centered: true,
          windowClass: 'theme-modal address-modal AddressModal'
        }).result.then((result) => {
          return this.formGroup.value;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }
  
    handleResponse() {
      if(this.formGroup.valid){
        console.log('Handle Response', this.formGroup.value);
        this.closeResult = this.formGroup.value;
        this.addressChange.emit(this.formGroup.value);
        this.modalService.dismissAll(this.closeResult);
      }
  
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
  
    ngOnDestroy() {
      if(this.modalOpen){
        this.modalService.dismissAll();
      }
    }
  
  }
  