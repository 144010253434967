import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject, of, forkJoin, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { InventoryResponse, ItemNodeAvailability, Order } from '../classes';
import { Product } from '../classes/product';
import { AppSettings } from './app.settings';
import { AuthService } from './auth.service';
import { PromisingService } from './promising.service';
import { OmsRestService } from './oms.service';

@Injectable()
export class InventoryService {

  public ivCallStack = [];
  private showIV: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private httpClient: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService,
    private promisingService: PromisingService
  ) {
    
  }

  toggleIVDisplay(): void {
    console.log('toggleIV');
    this.showIV.next(!this.showIV.getValue());
  }

  showIVDisplay(): Observable<boolean> {
    return this.showIV.asObservable();
  }

  getAvailability(items: Product[]): Observable<InventoryResponse>{
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        if(items && items.length > 0){
          let input = {
            "lines": [],
            "distributionGroupId": this.appSettings.getCurrentSettings().distributionGroup
          };
    
          for(let i of items){
            let line = {
              "deliveryMethod": "SHP",
              "itemId": i.ItemID,
              "lineId": i.ItemID,
              "unitOfMeasure": i.UnitOfMeasure
            };
            input.lines.push(line);
          }
    
          return this.post("/v1/availability/network", input)
            .pipe(map(r => new InventoryResponse(r)));
        } else {
          return of(new InventoryResponse(items));
        }
      }
    }));   
  }

  getNodeAvailability(items: Product[], shipNodes: string[]): Observable<ItemNodeAvailability[]> {
    /*if(shipNodes.length == 1){
      shipNodes.push("Aurora_WH1");
    }*/
    console.log("getNodeAvailability", items, shipNodes);
    var input = {
      "considerSafetyStock": "true",
      "lines": items.map(p => {
        return {
          "deliveryMethod": "PICK",
          "itemId": p.ItemID,
          "lineId": p.ItemID,
          "shipNodes": shipNodes,
          "unitOfMeasure": p.UnitOfMeasure
        }
      })
    }
    return this.post("/v1/availability/node", input).pipe(map(x => {

      return x.lines.map(line => new ItemNodeAvailability(line));
    }));
  }
  reserveInventory(order: Order) {
    let input = {
      "lines": [],
      "reference": order.OrderHeaderKey,
      "timeToExpire": 60
    };

    for(let orderItem of order.OrderLines){
      let line = {
        "deliveryMethod": "SHP",
        "itemId": orderItem.Item.ItemID,
        "lineId": orderItem.OrderLineKey,
        "quantity": orderItem.OrderedQty,
        "unitOfMeasure": "EACH",
        "distributionGroup": this.appSettings.getCurrentSettings().distributionGroup
      };
      input.lines.push(line);
    }

    return this.post("/v1/reservations", input);
  }

  get(url: string, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.get(`https://api.watsoncommerce.ibm.com/inventory/${template.ivTenantID}${url}`, options);
      }
    }));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.delete(`https://api.watsoncommerce.ibm.com/inventory/${template.ivTenantID}${url}`, options);
      }
    }));
  }

  post(url: string, input: any, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.post(`https://api.watsoncommerce.ibm.com/inventory/${template.ivTenantID}${url}`, input, options);
      }
    }));
  }

  patch(url: string, input: any, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.patch(`https://api.watsoncommerce.ibm.com/inventory/${template.ivTenantID}${url}`, input, options);
      }
    }));
  }

  createReservation({
    product,
    quantity,
    shipNode,
    distributionGroup,
    deliveryMethod,
    reference
  }): Observable<any> {
    let input = {
      lines: [{
        deliveryMethod,
        distributionGroup,
        quantity,
        shipNode,
        lineId: '1',
        itemId: product.ItemID,
        unitOfMeasure: product.UnitOfMeasure
      }],
      timeToExpire: "15",
      reference
    }

    return this.post(`/v1/reservations`, input);
  }

  updateReservation(reservationId: string, quantity: number): Observable<any> {
    return this.patch(`/v1/reservations/${reservationId}`, {quantity});
  }

  deleteReservation(reservationId: string): Observable<any> {
    return this.delete(`/v1/reservations?id=${reservationId}`);
  }
  getReservations(id: string): Observable<any> {
    return this.get(`/v1/reservations?id=${id}`);
  }
  getDemandTypes(): Observable<any> {
    return this.get('/v1/configuration/demand_types');
  }

  getSupplyTypes(): Observable<any> {
    return this.get('/v1/configuration/supply_types');
  }

  createSupplyType(supplyType: string, derivedFromType: string): Observable<any> {
    return this.put(`/v1/configuration/supply_types/${supplyType}`, {
      derivedFromType
    });
  }

  deleteSupplyType(supplyType: string): Observable<any> {
    return this.delete(`/v1/configuration/supply_types/${supplyType}`);
  }

  createNode(node: string, latitude?: number, longitude?: number): Observable<any> {
    let input = {};
    if(latitude && longitude){
      input["latitude"] = latitude;
      input["longitude"] = longitude;
    }
    return this.put(`/v1/configuration/shipNodes/${node}`, input);
  }
  createDG(dgid: string, nodes: string[]): Observable<any> {

    let input = {
      shipNodes: nodes.map(x => { return {"shipNode": x}}),
      syncDgAvailability: "N"
    };
    return this.put(`/v1/configuration/distributionGroups/${dgid}`, input);
  }

  getThresholds(): Observable<any> {
    return this.get(`/v1/configuration/thresholds`)
  }

  updateThresholds(low: number, medium: number, high: number): Observable<any> {
    return this.put(`/v1/configuration/thresholds`, {
      low,
      medium,
      high
    });
  }

  syncInventoryStock(product: Product, nodeQuantity: any[], type: string = "ONHAND", eta: Date = new Date("1900-01-01T00:00:00Z"), segmentType: string = "", segment: string = "", omsRestService?: OmsRestService) {
    let date = new Date();
    for(let node of nodeQuantity) {
      if(node.quantity > 0){
        this.promisingService.loadItemNodeAvailability(product, node.shipNode, parseInt(node.quantity), eta)
        .subscribe();
      }
    }

    if(omsRestService){
      omsRestService.getRule("IV_1").pipe(switchMap(version => {
        if(version && version.indexOf("2.") > -1){
          console.log("Adjusted GIV Inventory");
          return omsRestService.callApi("adjustInventory", {
            "Items": {
              "Item": nodeQuantity.map(x => {
                return {
                  "AdjustmentType": "ABSOLUTE",
                  "Availability": "TRACK",
                  "ItemID": product.ItemID,
                  "UnitOfMeasure": product.UnitOfMeasure,
                  "SupplyType": type,
                  "ShipNode": x.shipNode,
                  "Quantity": x.quantity,
                  "ETA": eta.toISOString(),
                  "SegmentType": segmentType,
                  "Segment": segment
                }
              })
            }
          })
        }
      })).subscribe(r => {
        
      });
      
    }

    return this.put(`/v1/supplies`, {
      "supplies": nodeQuantity.map(x => { return {
        "itemId": product.ItemID,
        "unitOfMeasure": product.UnitOfMeasure,
        "type": type,
        "sourceTs": date.toISOString(),
        "quantity": x.quantity,
        "shipNode": x.shipNode,
        "segmentType": segmentType,
        "segment": segment,
        "eta": eta.toISOString()
      }})
    });
  }


  put(url: string, input: any, options?: any): Observable<any> {
    return this.authService.template$.pipe(switchMap(template => {
      if(template.ivTenantID){
        return this.httpClient.put(`https://api.watsoncommerce.ibm.com/inventory/${template.ivTenantID}${url}`, input, options);
      }
    }));
  }

  getIsoDate(date: Date): string {
    return date.toISOString().substring(0, 10) + "T00:00:00Z";
  }

  getSupply(product: Product, shipNode: string, segmentType?: string, segment?: string): Observable<any> {
    return this.get(`/v1/supplies?itemId=${product.ItemID}&unitOfMeasure=${product.UnitOfMeasure}&shipNode=${shipNode}`).pipe(map(x => {
      console.log("getSupply", x);
      return x;
    }));
  }
  getSupplies(product: Product, shipNodes: string[], segmentType?: string, segment?: string): Observable<any[]> {
    const requests: Observable<any>[] = shipNodes.map(node => {
      return this.getSupply(product, node, segmentType, segment);
    });

    return combineLatest(requests).pipe(map(x => {
      return x.reduce((previous, current) => {
        if(current && current.length > 0){
          previous[current[0].shipNode] = current.reduce((previousTypes, currentType) => {
            if(!previousTypes[currentType.type]) {
              previousTypes[currentType.type] = {};
            } 
            previousTypes[currentType.type][currentType.eta] = currentType;
            return previousTypes;
          }, {});
        }
        return previous;
      }, {});
    }));
  }
  clearDemoSupplies(date: Date, product: Product, storeId: string, whId: string){
    const currentDate = new Date();
    const twoweeks = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 14);
    const fourdays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 4);
    const twodays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 2);
    const oneweek = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 7);
    let input = {
      "supplies": [
        {
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${storeId}_1`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${storeId}_2`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${storeId}_3`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${whId}1`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${whId}2`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": "1900-01-01T00:00:00Z",
         "itemId": product.ItemID,
         "quantity": 0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "HELD",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(twodays),
         "itemId": product.ItemID,
          "quantity": 0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "INTRANSIT",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(twoweeks),
         "itemId": product.ItemID,
         "quantity": 0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "PO_PLACED",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(oneweek),
         "itemId": product.ItemID,
         "quantity": 0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "PO_SCHEDULED",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(fourdays),
         "itemId": product.ItemID,
         "quantity": 0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "PO_RELEASED",
         "unitOfMeasure": product.UnitOfMeasure
        }
      ]
    };
    return this.put(`/v1/supplies`, input);
  }

  clearDemoDemands(date: Date, product: Product, whId: string) {
    const currentDate = new Date();
    const fivedays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 5);
    const twodays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 2);
    const oneday = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 1);
    const sevendays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 7);
    let input = {
      "demands": [{
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(twodays),
        "sourceTs": currentDate.toISOString(),
        "type": "SCHEDULED",
        "unitOfMeasure": product.UnitOfMeasure
      },{
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(oneday),
        "sourceTs": currentDate.toISOString(),
        "type": "BACKORDER",
        "unitOfMeasure": product.UnitOfMeasure
      },{
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(fivedays),
        "sourceTs": currentDate.toISOString(),
        "type": "ALLOCATED",
        "unitOfMeasure": product.UnitOfMeasure
      }, {
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(sevendays),
        "sourceTs": currentDate.toISOString(),
        "type": "OPEN_ORDER",
        "unitOfMeasure": product.UnitOfMeasure
      }]
    };
    return this.put(`/v1/demands`, input);
  }


  createDemoDemands(date: Date, product: Product, whId: string) {
    const currentDate = new Date();
    const fivedays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 5);
    const twodays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 2);
    const oneday = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 1);
    const sevendays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 7);
    let input = {
      "demands": [{
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 13.0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(twodays),
        "sourceTs": currentDate.toISOString(),
        "type": "SCHEDULED",
        "unitOfMeasure": product.UnitOfMeasure
      },{
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 5.0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(oneday),
        "sourceTs": currentDate.toISOString(),
        "type": "BACKORDER",
        "unitOfMeasure": product.UnitOfMeasure
      },{
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 20.0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(fivedays),
        "sourceTs": currentDate.toISOString(),
        "type": "ALLOCATED",
        "unitOfMeasure": product.UnitOfMeasure
      }, {
        "adjustmentReason": "PROCESS",
        "itemId": product.ItemID,
        "cancelDate": "2500-01-01T00:00:00Z",
        "quantity": 80.0,
        "shipNode": `${whId}1`,
        "shipDate": this.getIsoDate(sevendays),
        "sourceTs": currentDate.toISOString(),
        "type": "OPEN_ORDER",
        "unitOfMeasure": product.UnitOfMeasure
      }]
    };
    return this.put(`/v1/demands`, input);
  }

  createDemoSupplies(date: Date, product: Product, storeId: string, whId: string) {
    const currentDate = new Date();
    const twoweeks = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 14);
    const fourdays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 4);
    const twodays = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 2);
    const oneweek = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 7);
    let input = {
      "supplies": [
        {
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 45.0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${storeId}_1`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 100.0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${storeId}_2`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 28.0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${storeId}_3`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 273.0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${whId}1`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
          "eta": "1900-01-01T00:00:00Z",
          "itemId": product.ItemID,
          "quantity": 160.0,
          "shipByDate": "2500-01-01T00:00:00Z",
          "sourceTs": currentDate.toISOString(),
          "shipNode": `${whId}2`,
          "type": "ONHAND",
          "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": "1900-01-01T00:00:00Z",
         "itemId": product.ItemID,
         "quantity": 10.0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "HELD",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(twodays),
         "itemId": product.ItemID,
          "quantity": 30.0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "INTRANSIT",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(twoweeks),
         "itemId": product.ItemID,
         "quantity": 65.0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "PO_PLACED",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(oneweek),
         "itemId": product.ItemID,
         "quantity": 157.0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "PO_SCHEDULED",
         "unitOfMeasure": product.UnitOfMeasure
        },{
         "eta": this.getIsoDate(fourdays),
         "itemId": product.ItemID,
         "quantity": 90.0,
         "shipByDate": "2500-01-01T00:00:00Z",
         "sourceTs": currentDate.toISOString(),
         "shipNode": `${whId}1`,
         "type": "PO_RELEASED",
         "unitOfMeasure": product.UnitOfMeasure
        }
      ]
    };
    return this.put(`/v1/supplies`, input);
  }
  /*
  ivApi(method: string, path: string, body?: any, queryParams?: any[], cache?: boolean, cacheKey?: string): Observable<any> {
    let input = {
      "InventoryVisibilityAPI": {
        "Content-Type": "application/json",
        "HTTPMethod": method,
        "RetrySafe": "true",
        "URL": `https://api.watsoncommerce.ibm.com/inventory/12345${path}`
      }
    };

    if(body) {
      input.InventoryVisibilityAPI["Input"] = body;
    }
    if(queryParams) {
      let temp = [];
      for(let param in queryParams){
        temp.push({
          "Name": param,
          "Value": queryParams[param]
        });
      }
      input.InventoryVisibilityAPI["RequestParameters"] = {
        "RequestParameter": temp
      };
    }
    return this.callService("BDACallIVService", input, undefined, cache, cacheKey);
  }*/
}
