import { Component, OnInit, Inject, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { OrderLine } from '../../classes';
import { Product } from '../../classes/product';
import { AuthService } from '../../services/auth.service';
import { CartService } from '../../services/cart.service';
import { InventoryService } from '../../services/inventory.service';
import { OmsRestService } from '../../services/oms.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-pickup',
  templateUrl: './store-pickup.component.html',
  styleUrls: ['./store-pickup.component.scss']
})
export class StorePickupComponent implements OnInit {

  @ViewChild("storePickupModal", { static: false }) StorePickupModal: TemplateRef<any>;

  public store: any;
  public title: string;

  public product:Product;
  public orderLine: OrderLine;
  public storeResults: any;
  public countries: any[];
  public address: any;
  public currentStore: string;

  public modalOpen: boolean = false;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private inventoryService: InventoryService,
    private cartService: CartService,
    private authService: AuthService,
    private omsService: OmsRestService
  ) { 
    this.initCountries();
  }

  async openModal(data) {
    console.log('store-pickup-modal', data);
    this.product = data.product;
    this.orderLine = data.orderLine;
    this.title = data.title;
    this.authService.user$.subscribe(r => {
      this.currentStore = r.shipNode;
    })

    if(sessionStorage.getItem("searchAddress")){
      this.address = JSON.parse(sessionStorage.getItem("searchAddress"));
      this.search();
    } else {
      this.address = {
        "Country": "US",
        "ZipCode": undefined
      };
    }

    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.StorePickupModal, { 
        size: 'lg',
        ariaLabelledBy: 'Store-Pickup-Modal',
        centered: true,
        windowClass: 'theme-modal store-pickup-modal'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
       // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  search() {
    if(this.address.Country && this.address.ZipCode){
      sessionStorage.setItem("searchAddress", JSON.stringify(this.address));
      this.omsService.getNodeAvailability(this.address, this.product, this.inventoryService).subscribe(c => {
        this.storeResults = c;
      })
    }
  }
  initCountries() {
      this.countries = this.omsService.getCountries();
  }

  selectNode(node: any) {
    if(this.orderLine){
      this.cartService.changeDeliveryMethod(this.orderLine.OrderLineKey, "PICK", node.ShipNode);
    } else {
      this.cartService.addProductToCart(this.product, 1, this.product.UnitOfMeasure, "PICK", node.ShipNode, this.inventoryService);
    }
    this.modalService.dismissAll();
  }

  ngOnInit(): void {
  }
  onDismiss(): void {
    this.modalService.dismissAll();
  }
  onConfirm(): void {

  }

  setMyStore(node: any): void {
    this.authService.setCurrentStore(node.ShipNode);
  }


}
