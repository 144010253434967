<div class="img-wrapper">
	<div class="lable-block">
		<span class="lable3" *ngIf='product.new'>new</span>
		<span class="lable4" *ngIf='product.sale'>on sale</span>
        <span class="label3" *ngIf="product.IsPreOrder">pre-order</span>
	</div>
	<div class="front">
        <a [routerLink]="['//shop/product/', product.id, product.UnitOfMeasure]">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
                 [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].small" 
                 class="img-fluid lazy-loading" alt="">
        </a>
        <div *ngIf="product.availability && !product.availability.isAvailable()" class="overlay unavailable" translate>unavailable</div>
          <div *ngIf="product.availability && product.availability.isAvailable()" class="overlay available">
            <div class="overlay-container">
                <div *ngIf="product.availability.isCurrentlyAvailable()"><span class="label">{{"Onhand" | translate }}:</span> {{ product.availability.getAvailableQuantity() }}</div>
                <div *ngIf="product.nodeAvailability && product.nodeAvailability.getAvailableQuantity() > 0"><span class="label">{{"in-my-store" | translate }}:</span> {{ product.nodeAvailability.getAvailableQuantity() }}</div>
                <div *ngIf="product.availability.getFutureQuantity() > 0"><span class="label">{{"future-quantity" | translate }}:</span> {{ product.availability.getFutureQuantity() }}</div>
                <div class="date"><span class="label">{{"ead"}}:</span> {{ product.availability.getEAD() | date }}</div>
                <div class="date" *ngIf="product.nodeAvailability && product.nodeAvailability.getEarliestRecord() && product.nodeAvailability.getEarliestRecord().earliestDisplay === 'Today'"><span class="label" translate>order-by-8</span> {{"Today" | translate}}</div>
                <div class="date" *ngIf="product.nodeAvailability && product.nodeAvailability.getEarliestRecord().totalAvailableQuantity > 0 && product.nodeAvailability.getEarliestRecord() && product.nodeAvailability.getEarliestRecord().earliestDisplay !== 'Today'"><span class="label" translate>available-for-pickup</span> {{ product.nodeAvailability.getEarliestRecord().earliestDisplay }}</div>
              </div>
          
          </div>
	</div>
	<div class="cart-box">
		<button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
        <a href="javascript:void(0)" [title]="'Pickup' | translate" (click)="pickup(product)">
            <i class="ti-bag"></i>
          </a>
		<a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
            <i class="ti-heart" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
            <i class="ti-reload" aria-hidden="true"></i>
        </a>
	</div>
</div>
<div class="product-detail text-center ">
	<div class="rating">
		<bar-rating [rate]="5" [readOnly]="true" class="text-center pro-rating"></bar-rating>
	</div>
    <a [routerLink]="['//shop/product/', product.id, product.UnitOfMeasure]">
        <h6>{{ product.title | titlecase }}</h6>
    </a>
    <h4>
        {{ product?.newPrice | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price | currency:currency?.currency:'symbol' }}</span></del>
    </h4>
    <ul class="color-variant" *ngIf="Color(product?.variants).length">
      <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
        (click)="ChangeVariants(color, product)">
      </li>
    </ul>
</div>


<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<app-store-pickup #storePickupModal></app-store-pickup>

