<div class="img-wrapper">
    <div class="ribbon" *ngIf="product.new"><span>new</span></div>
    <div class="front">
        <a [routerLink]="['//shop/product/', product.id, product.UnitOfMeasure]">
            <img  [defaultImage]="'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="product.images[0].small" class="img-fluid"
                  alt="{{ product.images[0].alt }}">
        </a>
        <div *ngIf="product.availability && !product.availability.isAvailable()" class="overlay unavailable" translate>unavailable</div>
          <div *ngIf="product.availability && product.availability.isAvailable()" class="overlay available">
            <div class="overlay-container">
                <div *ngIf="product.availability.isCurrentlyAvailable()"><span class="label">{{"Onhand" | translate }}:</span> {{ product.availability.getAvailableQuantity() }}</div>
                <div *ngIf="product.nodeAvailability && product.nodeAvailability.getAvailableQuantity() > 0"><span class="label">{{"in-my-store" | translate }}:</span> {{ product.nodeAvailability.getAvailableQuantity() }}</div>
                <div *ngIf="product.availability.getFutureQuantity() > 0"><span class="label">{{"future-quantity" | translate }}:</span> {{ product.availability.getFutureQuantity() }}</div>
                <div class="date"><span class="label">{{"ead"}}:</span> {{ product.availability.getEAD() | date }}</div>
                <div class="date" *ngIf="product.nodeAvailability && product.nodeAvailability.getEarliestRecord() && product.nodeAvailability.getEarliestRecord().earliestDisplay === 'Today'"><span class="label" translate>order-by-8</span> {{"Today" | translate}}</div>
                <div class="date" *ngIf="product.nodeAvailability && product.nodeAvailability.getEarliestRecord().totalAvailableQuantity > 0 && product.nodeAvailability.getEarliestRecord() && product.nodeAvailability.getEarliestRecord().earliestDisplay !== 'Today'"><span class="label" translate>available-for-pickup</span> {{ product.nodeAvailability.getEarliestRecord().earliestDisplay }}</div>
            </div>
          
          </div>
    </div>
    <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="fa fa-heart" aria-hidden="true"></i>
        </a>
        <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> Add to cart
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> Add to cart
        </button>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
        	<i class="fa fa-refresh" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
    <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div class="product-info">
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a [routerLink]="['//shop/product/', product?.title.replace(' ', '-')]">
    	<h6>{{ product?.title | titlecase }}</h6>
    </a>
    <h4>        {{ product?.newPrice | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price | currency:currency?.currency:'symbol' }}</span></del></h4>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>