import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from './product.service';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
	queryParams?: any;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor(
		private productService: ProductService
	) { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'home', type: 'link', active: false, path:'/home'
		},
		{
			title: 'Shop', type: 'link', active: false, path: '/shop/collection'
		},
		{ path: '/pages/dashboard', title: 'dashboard', type: 'link' },
		{ path: '/pages/aboutus', title: 'about-us', type: 'link' },
		{
			title: 'blogs', type: 'sub', active: false, children: [
				{ path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
				{ path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
				{ path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
				{ path: '/pages/blog/details', title: 'blog-details', type: 'link' }
			]
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	//leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
	public get leftMenuItems(): Observable<Menu[]> {
		return this.productService.getCategories();
	}
}
