<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4 translate>footer.title</h4>
								<p> {{ "footer.content" | translate : {orgName: orgName} }}</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" [placeholder]="'footer.enter-email' | translate">
							</div>
							<button type="submit" class="btn btn-solid" translate>footer.subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4 translate>footer.about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" style="max-width: 300px" alt="logo">
						</div>

						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 translate>footer.categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul *ngIf="categories">
                                <li *ngFor="let category of categories">
									<a [routerLink]="category.path" [queryParams]="category.queryParams">{{ category.name }}</a>
								</li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 translate>footer.choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)" translate>footer.shipping-returns</a></li>
                                <li><a href="javascript:void(0)" translate>footer.secure-shopping</a></li>
                                <li><a href="javascript:void(0)" translate>footer.gallary</a></li>
                                <li><a href="javascript:void(0)" translate>footer.affiliates</a></li>
                                <li><a href="javascript:void(0)" translate>footer.contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4 translate>footer.store-info</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>100 Mural St. Suite 100, Richmond Hill ON, L4B 1J3</li>
						        <li><i class="fa fa-phone"></i>Call Us: (855) 909-3999</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>info@fahmpartners.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} powered by FAHM Technology Partners</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                   
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->