import { Component } from '@angular/core';
import { ProductBoxComponent } from '../product-box.component';

@Component({
  selector: 'app-product-box-three',
  templateUrl: './product-box-three.component.html',
  styleUrls: ['./product-box-three.component.scss']
})
export class ProductBoxThreeComponent extends ProductBoxComponent {


}
