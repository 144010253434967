import { Component, PLATFORM_ID, Inject, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { InventoryService } from './shared/services/inventory.service';
import { AppSettings, Settings } from './shared/services/app.settings';
import { AuthService } from './shared/services/auth.service';
import { RequestCache } from './shared/services/request-cache.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public activeIndex: number = -1;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  public showIVData: boolean = false;
  public settings: any;
  public theme: string;
  private currentState: number = 0;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, 
    public ivService: InventoryService,
    private appSettings: AppSettings,
    private authService: AuthService,
    private router: Router,
    private cache: RequestCache,
    private translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr', 'es', 'pt']);
    }
    this.ivService.showIVDisplay().subscribe(r => {
      this.showIVData = r;
    });
    this.appSettings.getSettings().subscribe(r => {
      if(r) {
        this.settings = r;
      } else {
        this.settings =  {
          name: 'default',
          theme: 'outdoors',
          logo: '',
          'baseOrg': '',
          'distributionGroup': '',
          'domain': '',
          'banner': '',
          'slides': '',
          'rtl': false,
          adminSidenavIsOpened: false,
          adminSidenavIsPinned: false,
          adminSidenavUserBlock: true,
          template: {
            value: 'outdoors',
            name: '',
            banners: [],
            slides: [],
            sequence: 0,
            id: ''
          }
        };

      }
      if(this.settings && this.settings.template && this.settings.template.value){
        this.theme = this.settings.template.value;
        console.log('theme', this.theme);
        document.body.classList.forEach(classes => {
          document.body.classList.remove(classes);
        })
        if(this.settings.locale.startsWith('es')) {
          console.log("setting language to spanish");
          this.translate.use('es');
        } else if (this.settings.locale === 'pt_BR') {
          this.translate.use('pt');
        } else {
          this.translate.use('en');
        }
        document.title = this.settings.name;

        var link: any = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `/assets/images/${this.settings.template.value}/favicon/favicon.png`;

        document.body.classList.add(this.theme);
        if(this.settings.themeColor) {
          document.documentElement.style.setProperty('--theme-default', this.settings.themeColor);
          document.documentElement.style.setProperty('--theme-gradient1', this.settings.themeColor);
          document.documentElement.style.setProperty('--theme-gradient2', this.settings.themeColor);
        } else {
          switch (this.theme) {
            default:
              document.documentElement.style.setProperty('--theme-default', '#f0b54d');
          }
        }
        if(this.settings.darkTheme) {
          if(!document.body.classList.contains('dark')){
            document.body.classList.add('dark');
          }
        } else {
          if(document.body.classList.contains('dark')) {
            document.body.classList.remove('dark');
          }
        }

      }

    });
    //document.body.classList.toggle('dark')
  }

  ngOnInit() {
    this.authService.access$.subscribe(r => {
      if(this.currentState == 0) {
        this.currentState = r.Ecomm ? 1 : 2;
      } else {
        if(this.currentState == 1 && !r.Ecomm){
          this.router.navigate(['/sign-in']);
          this.currentState = r.Ecomm ? 1 : 2;
        } else if(this.currentState == 2 && r.Ecomm) {
          this.router.navigate(['/']);
          this.currentState = r.Ecomm ? 1 : 2;
        }
      }
    })
    this.authService.template$.subscribe(r => {
      if(r){
        if(this.cache.setCacheServer(r.domain)){

          this.router.navigate(['/']);
        }
        this.appSettings.updateSettings(new Settings(r));
      } 
    })
   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh    
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0,0);
      }
    })  
  }

  setActive(index: number) {
    if(this.activeIndex === index) {
      this.activeIndex = -1;
    } else {
      this.activeIndex = index;
    }
  }

}
