import { Category, Product, ItemAttribute } from '../classes/product';
import { InventoryService } from '../services/inventory.service';

export class SearchResults {
  public CallingOrganizationCode: string;
  public Categories: Category[];
  public CurrentCategory: any;
  public Items: Product[];
  public TotalHits: number;
  public TotalPages: number;
  public PageNumber: number;
  public PageSize: number;
  public columns: number = 3;
  public productCount: number[] = [];
  public currency: string;
  public Facets: ItemAttribute[] = [];

  private inventoryLoaded: boolean = false;
  private inventoryLoading: boolean = false;
  private currentShipNode: string;

  constructor (obj: any) {
    if(obj != null){
      Object.assign(this, obj);
      this.TotalHits = parseInt(obj.TotalHits);
      this.TotalPages = parseInt(obj.TotalPages);
      this.PageNumber = parseInt(obj.PageNumber);
      this.PageSize = parseInt(obj.PageSize);
      if(obj.ItemList && obj.ItemList.Item){
        this.currency = obj.ItemList.Currency ? obj.ItemList.Currency : 'USD';
        this.Items = obj.ItemList.Item.map(x => new Product(x, this.currency));
        let group: number = Math.floor(this.Items.length / this.columns) + 1;

        this.productCount = Array(group).fill(0).map((x,i)=>i);
      }
      if(obj.Item) {
        this.currency = obj.Currency ? obj.Currency : 'USD';
        this.Items = obj.Item.map(x => new Product(x));
        let group: number = Math.floor(this.Items.length / this.columns) + 1;

        this.productCount = Array(group).fill(0).map((x,i)=>i);
      }
      if(obj.CategoryList && obj.CategoryList.Category){
        this.Categories = obj.CategoryList.Category.map(x => new Category(x));
      }
      if(obj.FacetList && obj.FacetList.ItemAttribute) {
        this.Facets = obj.FacetList.ItemAttribute.map(x => new ItemAttribute(x, 'facet'));
      }
    }
  }

  loadInventory(inventoryService: InventoryService, shipNode?: string): void {
    if(shipNode && this.currentShipNode !== shipNode) {
      this.currentShipNode = shipNode;
      this.inventoryLoaded = false;
    }
    if(this.Items && this.Items.length > 0){
      let skus = this.Items.reduce((products, currentProduct) => {
        if(currentProduct.PrimaryInformation.IsModelItem && currentProduct.variants){
          currentProduct.variants.itemList.forEach(variation => products.push(variation));
        } else {
          products.push(currentProduct);
        }
        return products;
      }, []);
      if(!this.inventoryLoaded && !this.inventoryLoading){
        this.inventoryLoading = true;
        inventoryService.getAvailability(skus).subscribe(r => {
          r.lines.map(inventoryResponse => {
            skus.find(i => (i.ItemID === inventoryResponse.lineId)).recordInventoryResponse(inventoryResponse);
          });
          if(shipNode){
            inventoryService.getNodeAvailability(skus, [shipNode]).subscribe(r => {
              r.map(itemNodeAvailability => {
                skus.find(i => (i.ItemID === itemNodeAvailability.lineId)).recordNodeInventoryResponse(itemNodeAvailability);
              });
              this.inventoryLoaded = true;
              this.inventoryLoading = false;
            })
          } else {
            this.inventoryLoaded = true;
            this.inventoryLoading = false;
          }
        });
      }
    }
   
  }

  
  merge(obj: SearchResults) {
    this.PageNumber = obj.PageNumber;
    for(let item of obj.Items){
      this.Items.push(item);
      let group: number = Math.floor(this.Items.length / this.columns) + 1;
      this.productCount = Array(group).fill(0).map((x,i)=>i);
    }
  }

  resultStart(): number {
      return 1;
  }

  resultEnd(): number {
    if(this.TotalHits < this.PageSize * this.PageNumber){
      return this.TotalHits;
    } else {
      return this.PageSize * this.PageNumber;
    }
  }
}
