import { Component } from '@angular/core';
import { FooterComponent } from '../footer.component';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.scss']
})
export class FooterTwoComponent extends FooterComponent {


}
