import { ItemAvailability, ItemNodeAvailability } from '.';
import { InventoryService } from '../services/inventory.service';
import { Menu } from '../services/nav.service';
import { OmsRestService } from '../services/oms.service';
import { ProductService } from '../services/product.service';
import { PromisingService } from '../services/promising.service';

export class Category implements Menu {
  public identifier: string;
  public name: string;
  public categoryPath: string;
  public resourceId: string;
  public shortDescription: string;
  public storeID: string;
  public thumbnail: string;
  public uniqueID: string;
  public route: string;
  public hideFromNavigation: boolean = false;
  public url: string;
  public id: string;
  public classification: string = "category";
  public description: string;
  public routeParam: any;
  public childCategories: Category[] = [];
  public children: Category[];
  public isMenuOpen: boolean = false;
  public path: string;
  public title: string;
  public subtitle: string;
  public details: string;
  public type: string;
  public megaMenu: boolean;
  public image: string;
  public active: boolean;
  public badge: boolean;
  public badgeText: string;
  public queryParams: any;

  constructor (obj: any) {
    if(obj != null){
      this.identifier = obj.CategoryID;
      this.id = obj.CategoryID;
      if(obj.CategoryPath){
        this.route = `/products/${obj.CategoryPath.replaceAll('/','|')}`;
        this.routeParam = {
          "category_path": obj.CategoryPath
        };
        this.url = this.route;
      }

      this.name = obj.ShortDescription;
      this.title = obj.ShortDescription;
      this.shortDescription = obj.ShortDescription;
      if(obj.Description !== obj.ShortDescription) {
        this.subtitle = obj.Description;
      }

      if(obj.AssetList?.Asset) {
        for(let asset of obj.AssetList.Asset){
          if(asset.Type && asset.Type === 'Image'){
            this.image = `${asset.ContentLocation}/${asset.ContentID}`;
          }
        }
      }
      this.categoryPath = obj.CategoryPath;
      this.path = `/shop/collection`;
      this.queryParams = {
        'category': obj.CategoryPath
      };
      this.active = false;
      this.megaMenu = false;
      this.type = 'link';
      this.uniqueID = obj.CategoryKey;

      if(obj.ChildCategoryList && obj.ChildCategoryList.Category){
        if(Array.isArray(obj.ChildCategoryList.Category)){
          this.childCategories = obj.ChildCategoryList.Category.map(x => new Category(x));
        } else {
          this.childCategories = [new Category(obj.ChildCategoryList.Category)];
        }
        this.children = this.childCategories;
      }
    }
  }

  hasSubCategory(): boolean {
    return this.children && this.children.length > 0;
  }
  hasParentCategory(): boolean {
    let parts: String[] = this.categoryPath.split('/');
    return parts.length > 3;
  }
  getParentCategory(): string {
    let parts: String[] = this.categoryPath.split('/');
    let response = " ";
    if(parts.length > 1){
      response = "";
      for(let i = 1; i < parts.length - 1; i++){
        response += `/${parts[i]}`;
      }
    }
    return response;
  }

  getChildrenCall(): string {
    return `categoryview/byParentCategory/${this.uniqueID}`;
  }

}



export class Product {
  public ItemKey: string;
  public UnitOfMeasure: string;
  public ItemID: string;
  public OrganizationCode: string;
  public PrimaryInformation: ProductPrimaryInformation;
  public showListPrice: boolean = false;
  public ItemAttributeGroups;
  public sKUs: Product[];
  public selectedValues: any = {};
  public selectedSku: Product;
  public distinctAttributes: DistinctAttributes;
  public variants: VariationItem;
  public currency: string;
  public availability: ItemAvailability;
  public nodeAvailability: ItemNodeAvailability;
  public nodeCount: number;
  public ComputedPrice: any;
  public shipNode: string;
  public deliveryMethod: string = "SHP";
  public alternativeItems: Product[];
  public id: string;
  public name: string;
  public images: Array<any>;
  public oldPrice: number;
  public newPrice: number;
  public discount: number;
  public ratingsCount: number = 394;
  public ratingsValue: number = this.ratingsCount * 90;
  public description: string;
  public stock: number;
  public cartCount: number;
  public color: Array<string>;
  public size: Array<string>;
  public weight: number;
  public categoryId: string;
  public title: string;
  public type: string;
  public brand: string;
  public collection: string[];
  public price: number;
  public sale: boolean;
  public tags: string[] = [];
  public quantity: number = 1;
  public new: boolean = false;
  public IsPreOrder: boolean = false;
  public IsCrowdSourced: boolean = false;
  public estimatedDeliveryOptions: EstimatedDeliveryOption[] = [];
  public hasAlternateUOMs: boolean = false;
  private currentShipNode: string;
  private currentZipCode: string;
  private variantshipNode: string;
  public UOMs: any[] = [];

  public variantsLoaded: boolean = false;
  private variantsLoading: boolean = false;
  public inventoryLoaded: boolean = false;
  private inventoryLoading: boolean = false;
  public promisingLoaded: boolean = false;
  private promisingLoading: boolean = false;

  private variationInventoryLoaded: boolean = false;
  private variationInventoryLoading: boolean = false;

  
  constructor (obj: any, currency: string = "USD")  {
    if(obj != null){
      Object.assign(this, obj);
      if(obj.PrimaryInformation) {
        this.PrimaryInformation = new ProductPrimaryInformation(obj.PrimaryInformation);
      }
      if(obj.ComputedPrice) {
        this.showListPrice = (obj.ComputedPrice && obj.ComputedPrice.ListPrice !== obj.ComputedPrice.UnitPrice);
      }

      if(obj.ItemAttributeGroupTypeList){
        this.distinctAttributes = new DistinctAttributes(obj);
      }
      if(obj.AlternateUOMList && obj.AlternateUOMList.AlternateUOM && obj.AlternateUOMList.AlternateUOM.length > 0){
        this.UOMs.push({
          "UnitOfMeasure": this.UnitOfMeasure
        });
        for(let uom of obj.AlternateUOMList.AlternateUOM) {
          if(uom.IsOrderingUOM === "Y"){
            this.hasAlternateUOMs = true;
            this.UOMs.push({
              ...uom
            });
          }
        }
      }
      if(obj.ClassificationCodes && obj.ClassificationCodes.PickingType){
        if(obj.ClassificationCodes.PickingType.toLowerCase() === 'preorder') {
          this.IsPreOrder = true;
        } else if(obj.ClassificationCodes.PickingType.toLowerCase() === 'crowdsourced') {
          this.IsCrowdSourced = true;
        }
      }
      if(obj.AssociationTypeList && obj.AssociationTypeList.AssociationType){
        for(let at of obj.AssociationTypeList.AssociationType){
          if(at.Type === 'Alternative.Y') {
            if(at.AssociationList && at.AssociationList.Association) {
              this.alternativeItems = at.AssociationList.Association.map(a => new Product(a.Item));
            } else {
              this.alternativeItems = [];
            }
         
          }
        }
      }
      if(obj.ChildItemList && obj.ChildItemList.ChildItem && obj.ChildItemList.ChildItem.length > 0){
        this.variants = new VariationItem(obj.ChildItemList);
      }
      this.id = this.ItemID;
      if(this.PrimaryInformation) {
        this.name = this.PrimaryInformation.ShortDescription;
        this.description = this.PrimaryInformation.ExtendedDescription;
        this.images = [
          {
            "small": this.PrimaryInformation.Image,
            "medium": this.PrimaryInformation.Image,
            "large": this.PrimaryInformation.Image,
          }
        ];
        this.title = this.PrimaryInformation.ShortDescription;
        this.type = this.PrimaryInformation.IsModelItem ? 'variation' : '';
        this.brand = obj.PrimaryInformation.ManufacturerName;
      } else {
        this.name = this.ItemID;
        this.description = this.ItemID;
        this.title = this.ItemID;
        this.type = '';
        this.brand = '';
        this.images = [{
          "small": "",
          "medium": "",
          "large": ""
        }]
      }

      this.collection = [];
      if(obj && obj.ComputedPrice && obj.ComputedPrice.ListPrice) {
        this.price = obj.ComputedPrice.ListPrice;
      } else {
        this.price = 0;
      }

      this.sale = false;



      if(this.ComputedPrice){
        if(parseFloat(this.ComputedPrice.UnitPrice) < parseFloat(this.ComputedPrice.ListPrice)){
          this.discount = (parseFloat(this.ComputedPrice.ListPrice) - parseFloat(this.ComputedPrice.UnitPrice));
        }
        this.newPrice = this.ComputedPrice.UnitPrice;
      }

      this.stock = 0;
    }
    this.currency = currency;
  }

  loadvariants(searchService: ProductService, inventoryService?: InventoryService, shipNode?: string) {
    if(shipNode && this.variantshipNode !== shipNode) {
      this.variantshipNode = shipNode;
      this.variationInventoryLoaded = false;
    }
    if(!this.variantsLoaded && !this.variantsLoading){
      this.variantsLoading = true;
     
      if(this.PrimaryInformation.IsModelItem){
        searchService.getVariationItems(this)
          .subscribe(r => {
            this.variantsLoaded = true;
            this.variantsLoading = false;
            this.variants = r;
            if(inventoryService && !this.variationInventoryLoaded && !this.variationInventoryLoading){
              this.variationInventoryLoading = true;
              inventoryService.getAvailability(this.variants.itemList).subscribe(response => {
                for(let inventory of response.lines) {
                  this.variants.itemList.find((prod) => prod.ItemID === inventory.lineId).recordInventoryResponse(inventory);
                }
                if(shipNode) {
                  inventoryService.getNodeAvailability(this.variants.itemList, [shipNode]).subscribe(itemNodeAvail => {
                    for(let item of itemNodeAvail){
                      this.variants.itemList.find((prod => prod.ItemID === item.lineId)).recordNodeInventoryResponse(item);
                    }
                    this.variationInventoryLoading = false;
                    this.variationInventoryLoaded = true;
                  })
                } else {
                  this.variationInventoryLoading = false;
                  this.variationInventoryLoaded = true;
                }
              });
            }
          });
        }
    } else if(inventoryService && !this.variationInventoryLoaded && !this.variationInventoryLoading) {
      this.variationInventoryLoading = true;
      inventoryService.getAvailability(this.variants.itemList).subscribe(response => {
        for(let inventory of response.lines) {
          this.variants.itemList.find((prod) => prod.ItemID === inventory.lineId).recordInventoryResponse(inventory);
        }
        if(shipNode) {
          inventoryService.getNodeAvailability(this.variants.itemList, [shipNode]).subscribe(itemNodeAvail => {
            for(let item of itemNodeAvail){
              this.variants.itemList.find((prod => prod.ItemID === item.lineId)).recordNodeInventoryResponse(item);
            }
            this.variationInventoryLoading = false;
            this.variationInventoryLoaded = true;
          })
        } else {
          this.variationInventoryLoading = false;
          this.variationInventoryLoaded = true;
        }
      });
    }
   
  }

  loadPromising(promisingService: PromisingService, omsRestService: OmsRestService, zipCode: string): void {
    if(zipCode && this.currentZipCode !== zipCode) {
      this.currentZipCode = zipCode;
      this.promisingLoaded = false;
    }
    if(!this.promisingLoaded && !this.promisingLoading){
      this.promisingLoading = true;
      promisingService.getEDD(this, zipCode).subscribe(r => {
          this.promisingLoading = false;
          let nodes = r.reduce((nodes, option) => {
          if(option.resultFound && nodes.findIndex(value => value === option.result.fulfillingNode) == -1){
            nodes.push(option.result.fulfillingNode);
          }
          return nodes;
        }, []);
        omsRestService.getNodeDetails(nodes).subscribe(nodeMap => {

          this.estimatedDeliveryOptions = r.map(option => {
            if(option.resultFound){
              option["shipNode"] = nodeMap[option.result.fulfillingNode];
            }
            this.promisingLoaded = true;
            return option;
          })
        });
        
      })
    }
  }

  loadAvailablity(inventoryService: InventoryService, shipNode?: string): void {
    if(this.PrimaryInformation.AssumeInfiniteInventory){
      this.inventoryLoaded = true;
      this.availability = undefined;
      this.stock = 1000000000;
    } else {
      if(shipNode && this.currentShipNode !== shipNode) {
        this.currentShipNode = shipNode;
        this.inventoryLoaded = false;
      }
      if(!this.inventoryLoaded && !this.inventoryLoading){
        this.inventoryLoading = true;
        inventoryService.getAvailability([this]).subscribe(r => {
          this.recordInventoryResponse(r.lines[0]);
          if(shipNode){
            inventoryService.getNodeAvailability([this], [shipNode]).subscribe(r => {
              this.recordNodeInventoryResponse(r[0]);
              this.inventoryLoading = false;
              this.inventoryLoaded = true;
            });
          } else {
            this.inventoryLoading = false;
            this.inventoryLoaded = true;
          }
        });
      }
    }
   
  }

  recordInventoryResponse(inventoryResponse: ItemAvailability): void {
    this.availability = inventoryResponse;
    this.stock = this.availability.getTotalAvailableQuantity();
  }
  recordNodeInventoryResponse(inventoryNodeResponse: ItemNodeAvailability): void {
    this.nodeAvailability = inventoryNodeResponse;
    this.nodeCount = this.nodeAvailability.getTotalAvailableQuantity();
  }
  isValidSelection(): boolean {
    return !this.getSelectedItem().PrimaryInformation.IsModelItem;
  }
  getSelectedItem(): Product {
    if(this.variants && this.variants.selectedItem){
      return this.variants.selectedItem;
    }
    return this;
  }

}

export class ItemAttribute {
public AttributeID: string;
public AttributeGroupID: string;
public DataType: string;
public SequenceNo: number;
public ShortDescription: string;
public AssignedValues: AssignedValue[];
public Value: String;

constructor (obj: any, type?: string) {
  if(obj != null) {
    Object.assign(this, obj.Attribute);
    if(type && type === 'facet'){
      this.AttributeID = obj.ItemAttributeName;
      this.AttributeGroupID = obj.AttributeGroupType;
      this.ShortDescription = obj.ItemAttributeDescription;
    }
    this.SequenceNo = parseInt(obj.SequenceNo);
    if(obj.Value){
      this.Value = obj.Value;
    }
    if(obj.AssignedValueList && obj.AssignedValueList.AssignedValue && obj.AssignedValueList.AssignedValue.length > 0){
      this.AssignedValues = obj.AssignedValueList.AssignedValue.map(x => new AssignedValue(x));
    }
  }
}

}

export class AssignedValue {
public Value: string;
public ShortDescription: string;
public SequenceNo: number;
constructor (obj: any) {
  if(obj != null) {
    Object.assign(this, obj);
    this.SequenceNo = parseInt(obj.SequenceNo);
  }
}
}
export class DistinctAttributes {
public itemAttributes: ItemAttribute[];

constructor (obj: any) {
  this.itemAttributes = [];
  if(obj.ItemAttributeGroupTypeList && obj.ItemAttributeGroupTypeList.ItemAttributeGroupType){
    var iagt = obj.ItemAttributeGroupTypeList.ItemAttributeGroupType;
    for(var t = 0; t < iagt.length; t++){
      if(iagt[t].ClassificationPurposeCode === "DISTINCT_ATTRIBUTES"){
        iagt = iagt[t];
        break;
      }
    }
    if(iagt.ItemAttributeGroupList && iagt.ItemAttributeGroupList.ItemAttributeGroup){
      var iagl = iagt.ItemAttributeGroupList.ItemAttributeGroup;
      for(var i = 0; i < iagl.length; i++){
        var iag = iagl[i];
        if(iag.ItemAttributeList && iag.ItemAttributeList.ItemAttribute){
          this.itemAttributes = iag.ItemAttributeList.ItemAttribute.map(x => new ItemAttribute(x));
        }
      }
    }
  }
}

getAttribute(sAttributeID: string, ItemAttributeGroupList: any[]): ItemAttribute{
  for(var i = 0; i < ItemAttributeGroupList.length; i++){
    var iag = ItemAttributeGroupList[i];
    if(iag.ItemAttributeList && iag.ItemAttributeList.ItemAttribute){
      var ial = iag.ItemAttributeList.ItemAttribute;
      for(var r = 0; r < ial.length; r++){
        if(ial[r].Attribute.AttributeID === sAttributeID){
          return ial[r];
        }
      }
    }
  }
}
}

export class ProductPrimaryInformation {
Description: string;
ExtendedDescription: string;
ExtendedDisplayDescription: string;
ConfigurationModelName: string;
ShortDescription: string;
ImageLocation: string;
ImageID: string;
IsValid: boolean;
IsModelItem: boolean;
Image: string;
IsConfigurable: boolean;
ConfigurationKey: string
IsPickupAllowed: boolean;
IsShippingAllowed: boolean;
IsDeliveryAllowed: boolean;
AssumeInfiniteInventory: boolean;

constructor (obj: any) {
  if(obj != null){
    Object.assign(this, obj);
    this.AssumeInfiniteInventory = (obj.AssumeInfiniteInventory === "Y");
    this.IsValid = (obj.IsValid === "Y");
    this.IsModelItem = (obj.IsModelItem === "Y");
    this.IsConfigurable = (obj.IsConfigurable === 'Y');
    this.IsPickupAllowed = (obj.IsPickupAllowed !== 'N');
    this.IsShippingAllowed= (obj.IsShippingAllowed !== 'N');
    this.IsDeliveryAllowed = (obj.IsDeliveryAllowed !== 'N');
    if(obj.ImageLocation && obj.ImageID) {
      this.Image = `${obj.ImageLocation}/${obj.ImageID}`;
    }
  }
}
}

export class VariationItem {
itemList: Product[];
sequence: ItemAttribute[];
distinctAttributes: KeyData;
itemMap: KeyData;
validCombinations: KeyData;

public selected: any = {};
public selectedItem: any;

constructor (obj: any) {
  if(obj != null){
    this.sequence = [];
    this.distinctAttributes = {};
    this.itemMap = {};
    this.validCombinations = {};

    Object.assign(this, obj);
    if(obj.ChildItem){
      this.itemList = obj.ChildItem.map((x) => new Product(x, obj.Currency));
    } else {
      this.itemList = obj.Item.map((x) => new Product(x, obj.Currency));
    }
    this.selectedItem = this.itemList[0];

    for(let i = 0; i < this.itemList.length; i++){
      let item: Product = this.itemList[i];
      let key: string = "";
      for(let attribute of item.distinctAttributes.itemAttributes) {
        key += "_" + attribute.Value;
        if(i == 0){
          this.sequence.push(attribute);
        }
        let existing = this.distinctAttributes[attribute.AttributeID];
        if(!existing){
          this.distinctAttributes[attribute.AttributeID] = [];
          existing = this.distinctAttributes[attribute.AttributeID];
        }
        let found: boolean = false;
        for(let k = 0; k < existing.length; k++){
          let val = existing[k];
          if(val.Value === attribute.AssignedValues[0].Value){
            found = true;
          }
        }
        if(!found){
          if(attribute.AssignedValues.length > 0){
            existing.push(attribute.AssignedValues[0]);
          }
        }
      }
      this.itemMap[key] = item;
    }
    for(var i = 0; i < this.sequence.length; i++){
      this.distinctAttributes[this.sequence[i].AttributeID].sort(this.compareSequence);
    }
  }
}

setSelected(Attribute: any, Value: any){
  if(!this.unavailable(Attribute, Value)){
    let key: string = "";
    let all: boolean = true;
    this.selected[Attribute.AttributeID] = Value.Value;
    for(let i = 0; i < this.sequence.length; i++){
      var id = this.sequence[i].AttributeID;
      if(this.selected[id]){
        key += "_" + this.selected[id];
      } else {
        all = false;
      }
    }
    if(all){
      this.selectedItem = this.itemMap[key];
    }
  } else {
    this.selected = {};
    this.selected[Attribute.AttributeID] = Value.Value;
    let key: string = "";
    let all: boolean = true;
    for(let i = 0; i < this.sequence.length; i++){
      var id = this.sequence[i].AttributeID;
      if(this.selected[id]){
        key += "_" + this.selected[id];
      } else {
        all = false;
      }
    }
    if(all){
      this.selectedItem = this.itemMap[key];
    }
  }

}

public getButtonStyle(Attribute: any, AttributeValue: any){
  if(this.selected && this.selected[Attribute.AttributeID] && this.selected[Attribute.AttributeID] === AttributeValue.Value){
    return 'selected';
  } else if(this.unavailable(Attribute, AttributeValue)){
    return 'unavailable';
  }
  return 'available';
}

unavailable(Attribute: any, Value: any): boolean {
  let key: string = "";
  for(let i = 0; i < this.sequence.length; i++){
    let attribute = this.sequence[i];
    if(Attribute.AttributeID === attribute.AttributeID){
      key += "_" + Value.Value;
    } else if(this.selected[attribute.AttributeID]) {
      key += "_" + this.selected[attribute.AttributeID];
    } else {
      return false;
    }
  }
  if(this.itemMap[key]){
    return this.itemMap[key].stock == 0;
  }
  return true;
}

compareSequence(a: any, b: any): number{
  if(a.SequenceNo < b.SequenceNo){
    return -1;
  }
  if(a.SequenceNo > b.SequenceNo){
    return 1;
  }
  return 0;
}
}

export interface KeyData {
[key: string]: any;
}


export interface EstimatedDeliveryOption {
  carrierService: string;
  resultFound: boolean;
  shipNode: any;
  result: {
    deliveryTime: Date;
    placeOrderBy: Date;
    fulfillmentNode: string;
    expectedShipTime: Date;
    leadTimeUserd: string;
    processingTimeUsed: string;
    processingStartTime: Date;
    transitTimeUsed: number;
  }
}

export interface Variants {
    variant_id?: number;
    id?: number;
    sku?: string;
    size?: string;
    color?: string;
    image_id?: number;
}

export interface Images {
    image_id?: number;
    id?: number;
    alt?: string;
    src?: string;
    variant_id?: any[];
}